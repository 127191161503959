function Footer() {
  return (
    <div className="footer">
      <a href="/privacy.pdf" className="select">
        プライバシーポリシー
      </a>
      <a href="/security.pdf" className="select">
        個人情報取扱
      </a>
    </div>
  );
}

export default Footer;
