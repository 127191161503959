import React, { forwardRef } from 'react';
import { Field } from 'formik';
import { Form, Row } from 'react-bootstrap';
import './style.scss';
import { ExclamationCircle } from 'react-bootstrap-icons';

export function Label({ label, isRequired = false, children }) {
  return (
    <Form.Label>
      <Row style={{ height: '38px' }} className="align-items-center">
        <div>
          <span className="required-star">{isRequired && '*'}</span>
          {label || children}
        </div>
      </Row>
    </Form.Label>
  );
}

export const InputLabel = forwardRef(
  (
    {
      suffix = false,
      onChange,
      label,
      hint,
      error,
      component,
      inputRef,
      index,
      name,
      col,
      ...props
    },
    ref
  ) => (
    <div className={`input-wrap pos-rel ${error && 'is-invalid'}`}>
      <Form.Group as={Row} controlId="id-input" noGutters className="wrap-input__common">
        {component != null ? (
          component
        ) : (
          <Field
            render={({ field }) =>
              onChange ? (
                <input
                  {...field}
                  {...props}
                  onChange={onChange}
                  value={field.value[name]}
                  name={name}
                  ref={(el) => {
                    if (inputRef && el && (ref || true)) {
                      inputRef.current[index] = el;
                    }
                  }}
                />
              ) : (
                <input
                  {...field}
                  {...props}
                  value={field.value[name]}
                  name={name}
                  ref={(el) => {
                    if (inputRef && el && (ref || true)) {
                      inputRef.current[index] = el;
                    }
                  }}
                />
              )
            }
          />
        )}
        {<div className="text-hint">{hint}</div>}
        {error && (
          <>
            <div className="error-icon">
              <ExclamationCircle color="red" />
            </div>
            <div className="error-msg">{error}</div>
          </>
        )}
        {suffix && !error && (
          <>
            <div className="prefix-unit pos-abs d-block">{suffix}</div>
          </>
        )}
      </Form.Group>
    </div>
  )
);
