/* eslint-disable prettier/prettier */
import React from 'react';
import ModalDelete from './common/modal/ModalDelete';

function HeaderForm({ haveBtnDelete = false, deleteShowing, setDeleteShowing, deleteThisCheckup, isInfo, hideLabel = false }) {
  return (
    <div className="header-form">
      <h4>{!hideLabel && "帳票情報"}</h4>
      {haveBtnDelete && (
        <ModalDelete
          isInfo={isInfo}
          deleteShowing={deleteShowing}
          setDeleteShowing={setDeleteShowing}
          deleteThisCheckup={deleteThisCheckup}
        />
      )}
    </div>
  );
}

export default HeaderForm;
