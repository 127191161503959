import { toIso8601str } from './date';

const defaultDateFormat = 'YYYY年MM月DD日';
export function computeDate(input, otherFormat) {
  return input ? toIso8601str(input, otherFormat || defaultDateFormat) : '-';
}

export function objectByString(obj, nestedKey) {
  nestedKey = nestedKey?.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  nestedKey = nestedKey?.replace(/^\./, ''); // strip nestedKeyArr leading dot
  const nestedKeyArr = nestedKey?.split('.');
  for (let i = 0, n = nestedKeyArr?.length; i < n; i += 1) {
    const k = nestedKeyArr[i];
    if (obj) {
      if (k in obj) {
        obj = obj[k];
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
  return obj;
}
export const generateDateString = (year, month, day) => {
  const twoDigitSettings = { minimumIntegerDigits: 2, useGrouping: false };
  if (year && month && day)
    return `${year}-${Number(month).toLocaleString('en-US', twoDigitSettings)}-${Number(
      day
    ).toLocaleString('en-US', twoDigitSettings)}`;
  return null;
};
export const convertFullToHalf = (string) => {
  const result = string
    .replace(/[\uff01-\uff5e]/g, (ch) => String.fromCharCode(ch.charCodeAt(0) - 0xfee0))
    .replaceAll('。', '.')
    .replaceAll('ー', '-');
  return result;
};
