import DynamicUserBreadcrumb from './components/Breadcrumb/BreadcrumbDynamicEmployee';
import EmployeeCreate from './views/employee/Create';
import EmployeeEdit from './views/employee/Edit';
import EmployeeDetailInfo from './views/employee/Detail';
import EquipmentLoanConfirm from './views/employee/equipmentLoanConfirm/Edit';
import UniformLoanConfirm from './views/employee/uniformLoanConfirm/Edit';
import Employment from './views/employee/employment/Edit';
import EmergencyContact from './views/employee/emergencyContact/Edit';
import HousingInformation from './views/employee/housing/Information';
import HousingEdit from './views/employee/housing/Edit';
import PensionBookInformation from './views/employee/pensionBook/Information';
import PensionBookEdit from './views/employee/pensionBook/Edit';
import VehicleInspectionInfo from './views/employee/vehicleInspection/Information';
import VehicleInspectionEdit from './views/employee/vehicleInspection/Edit';
import VehicleInsuranceInfo from './views/employee/vehicleInsurance/Information';
import VehicleInsuranceEdit from './views/employee/vehicleInsurance/Edit';
import WorkConditionInfo from './views/employee/workCondition/Information';
import WorkConditionEdit from './views/employee/workCondition/Edit';
import RepaymentPlanInfo from './views/employee/repaymentPlan/Information';
import RepaymentPlanEdit from './views/employee/repaymentPlan/Edit';
import SkillInfo from './views/employee/skill/Information';
import SkillEdit from './views/employee/skill/Edit';
import TransferBankAccountInfo from './views/employee/transferBankAccount/Information';
import TransferBankAccountEdit from './views/employee/transferBankAccount/Edit';
import AptitudeInfo from './views/employee/aptitudeTest/Information';
import AptitudeEdit from './views/employee/aptitudeTest/Edit';
import HealthCertInfo from './views/employee/healthCertificate/Information';
import HealthCertEdit from './views/employee/healthCertificate/Edit';
import AccidentReportInfo from './views/employee/accidentReport/Information';
import AccidentReportEdit from './views/employee/accidentReport/Edit';
import FamilyInfo from './views/employee/family/Info';
import FamilyEdit from './views/employee/family/Edit';
import DriverLicenseInfo from './views/employee/driverLicense/Information';
import DriverLicenseEdit from './views/employee/driverLicense/Edit';
import OperationRecordInfo from './views/employee/operationRecordCert/Information';
import OperationRecordEdit from './views/employee/operationRecordCert/Edit';
import CommuteCostApplicationInfo from './views/employee/commuteCostApplication/Information';
import CommuteCostApplicationEdit from './views/employee/commuteCostApplication/Edit';
import EmployeeRelatedDocInfo from './views/employee/relatedDocs/Information';
import EmployeeList from './views/employees';
import EmploymentInsuranceCertEdit from './views/employee/employmentInsuranceCert/Edit';
import EmploymentInsuranceCertInformation from './views/employee/employmentInsuranceCert/Information';
import EmployeeResidenceCardIndex from './views/employee/residenceCard/Index';
import EmployeeResidenceCardEdit from './views/employee/residenceCard/Edit';
import EmployeeDisabilityCertIndex from './views/employee/disabilityCert/Index';
import EmployeeDisabilityCertEdit from './views/employee/disabilityCert/Edit';
import EmployeeHealthInsuranceCardIndex from './views/employee/healthInsuranceCard/Index';
import EmployeeHealthInsuranceCardEdit from './views/employee/healthInsuranceCard/Edit';
import RelatedDocsImagesUpload from './views/employee/relatedDocsImages/Upload';
import RelatedDocsImagesEdit from './views/employee/relatedDocsImages/Edit';

const route = {
  getLink: ({ params, query }) => {
    let link = this.path;

    if (params) {
      const paramNames = Object.keys(params);
      paramNames.forEach((paramName) => {
        link = link.replace(`:${paramName}`, params[paramName]);
      });
    }

    if (query) {
      const queryFields = Object.keys(query);
      link += '?';
      if (queryFields.length > 1) {
        queryFields.forEach((field) => {
          link = `${link}&${field}=${query[field]}`;
        });
      } else {
        queryFields.forEach((field) => {
          link = `${link}${field}=${query[field]}`;
        });
      }
    }

    return link;
  },
};

const routes = [
  // { ...route, path: '/', exact: true, component: DisabilityCertificate, name: 'Home' },
  // { ...route, path: '/login', exact: true, component: Login, name: 'Login' },
  // { ...route, path: '/detail', exact: true, component: DisabilityDetail, name: 'Detail' },
  // { ...route, path: '/image', exact: true, component: DisabilityImageUpload, name: 'Image upload' },
  {
    ...route,
    path: '/',
    exact: true,
    component: EmployeeList,
    name: 'Employee List',
    breadcrumb: '従業員',
  },
  {
    ...route,
    path: '/employee',
    exact: true,
    component: EmployeeList,
    name: 'Employee List',
    breadcrumb: '従業員',
  },
  {
    ...route,
    path: '/employee/create',
    exact: true,
    component: EmployeeCreate,
    name: 'Employee Create',
    breadcrumb: '新規作成',
  },
  {
    ...route,
    path: '/employee/:id',
    exact: true,
    component: EmployeeDetailInfo,
    name: 'Employee detail info',
    breadcrumb: DynamicUserBreadcrumb,
  },
  {
    ...route,
    path: '/employee/:id/edit',
    exact: true,
    component: EmployeeEdit,
    name: 'Employee Edit',
    breadcrumb: '基本情報編集',
  },
  {
    ...route,
    path: '/employee/:id/equipmentLoanConfirm',
    exact: true,
    component: EquipmentLoanConfirm,
    name: 'New Equipment Loan Confirm',
    breadcrumb: '備品',
  },
  {
    ...route,
    path: '/employee/:id/equipmentLoanConfirm/:lendId',
    exact: true,
    component: EquipmentLoanConfirm,
    name: 'Equipment Loan Confirm',
    breadcrumb: '情報の登録',
  },
  {
    ...route,
    path: '/employee/:id/uniformLoanConfirm',
    exact: true,
    component: UniformLoanConfirm,
    name: 'New Uniform Loan Confirm',
    breadcrumb: '作業着/制服',
  },
  {
    ...route,
    path: '/employee/:id/uniformLoanConfirm/:lendId',
    exact: true,
    component: UniformLoanConfirm,
    name: 'Uniform Loan Confirm',
    breadcrumb: '情報の登録',
  },
  {
    ...route,
    path: '/employee/:id/employment',
    exact: true,
    component: Employment,
    name: 'Employment',
    breadcrumb: '雇用情報の編集',
  },
  {
    ...route,
    path: '/employee/:id/workCondition',
    exact: true,
    component: WorkConditionInfo,
    name: 'Work Condition Information',
    breadcrumb: '契約情報',
  },
  {
    ...route,
    path: '/employee/:id/workCondition/edit',
    exact: true,
    component: WorkConditionEdit,
    name: 'Edit Work Condition',
    breadcrumb: '情報の登録',
  },
  {
    ...route,
    path: '/employee/:id/driverLicense',
    exact: true,
    component: DriverLicenseInfo,
    name: 'Driver License',
    breadcrumb: '運転免許証',
  },
  {
    ...route,
    path: '/employee/:id/driverLicense/new',
    exact: true,
    component: DriverLicenseEdit,
    name: 'New Driver License',
    breadcrumb: '情報の登録',
  },
  {
    ...route,
    path: '/employee/:id/driverLicense/:issuanceDateStr/edit',
    exact: true,
    component: DriverLicenseEdit,
    name: 'Edit Driver License',
    breadcrumb: '情報の登録',
  },
  {
    ...route,
    path: '/employee/:id/healthCert',
    exact: true,
    component: HealthCertInfo,
    name: 'Health certificates',
    breadcrumb: '健康診断書',
  },
  {
    ...route,
    path: '/employee/:id/healthCert/:healthCheckupId/edit',
    exact: true,
    component: HealthCertEdit,
    name: 'Edit health certificate',
    breadcrumb: '情報の編集',
  },
  {
    ...route,
    path: '/employee/:id/healthCert/new',
    exact: true,
    component: HealthCertEdit,
    name: 'New health certificate',
    breadcrumb: '情報の編集',
  },
  {
    ...route,
    path: '/employee/:id/emergencyContact',
    exact: true,
    component: EmergencyContact,
    name: 'EmergencyContact',
    breadcrumb: '緊急連絡先',
  },
  {
    ...route,
    path: '/employee/:id/housing',
    exact: true,
    component: HousingInformation,
    name: 'Housing information',
    breadcrumb: '住居情報',
  },
  {
    ...route,
    path: '/employee/:id/housing/edit',
    exact: true,
    component: HousingEdit,
    name: 'edit Housing information',
    breadcrumb: '情報の編集',
  },
  {
    ...route,
    path: '/employee/:id/pensionBook',
    exact: true,
    component: PensionBookInformation,
    name: 'Pension Book information',
    breadcrumb: '年金手帳',
  },
  {
    ...route,
    path: '/employee/:id/pensionBook/edit',
    exact: true,
    component: PensionBookEdit,
    name: 'edit Pension Book information',
    breadcrumb: '情報の編集',
  },
  {
    ...route,
    path: '/employee/:id/repaymentPlan',
    exact: true,
    component: RepaymentPlanInfo,
    name: 'Repayment plan infomation',
    breadcrumb: '返済情報',
  },
  {
    ...route,
    path: '/employee/:id/repaymentPlan/:planId/edit',
    exact: true,
    component: RepaymentPlanEdit,
    name: 'Edit Repayment plan',
    breadcrumb: '情報の編集',
  },
  {
    ...route,
    path: '/employee/:id/repaymentPlan/new',
    exact: true,
    component: RepaymentPlanEdit,
    name: 'New Repayment plan',
    breadcrumb: '情報の編集',
  },
  {
    ...route,
    path: '/employee/:id/skill',
    exact: true,
    component: SkillInfo,
    name: 'Skills infomation',
    breadcrumb: '資格者証',
  },
  {
    ...route,
    path: '/employee/:id/skill/new',
    exact: true,
    component: SkillEdit,
    name: 'Add skill infomation',
    breadcrumb: '情報の編集',
  },
  {
    ...route,
    path: '/employee/:id/skill/:skill_id/edit',
    exact: true,
    component: SkillEdit,
    name: 'Add skill infomation',
    breadcrumb: '情報の編集',
  },
  {
    ...route,
    path: '/employee/:id/transferBankAccount',
    exact: true,
    component: TransferBankAccountInfo,
    name: 'Transfer bank account infomation',
    breadcrumb: '銀行口座',
  },
  {
    ...route,
    path: '/employee/:id/transferBankAccount/:accountId/edit',
    exact: true,
    component: TransferBankAccountEdit,
    name: 'Transfer bank account Edit',
    breadcrumb: '情報の編集',
  },
  {
    ...route,
    path: '/employee/:id/transferBankAccount/new',
    exact: true,
    component: TransferBankAccountEdit,
    name: 'Transfer bank account New',
    breadcrumb: '情報の編集',
  },
  {
    ...route,
    path: '/employee/:id/aptitudeTest',
    exact: true,
    component: AptitudeInfo,
    name: 'Aptitude Test Infomation',
    breadcrumb: '適正診断結果',
  },
  {
    ...route,
    path: '/employee/:id/aptitudeTest/:aptTestId/edit',
    exact: true,
    component: AptitudeEdit,
    name: 'Edit Aptitude Test',
    breadcrumb: '情報の編集',
  },
  {
    ...route,
    path: '/employee/:id/aptitudeTest/new',
    exact: true,
    component: AptitudeEdit,
    name: 'New Aptitude Test',
    breadcrumb: '情報の編集',
  },
  {
    ...route,
    path: '/employee/:id/vehicleInspection',
    exact: true,
    component: VehicleInspectionInfo,
    name: 'Commute Vehicle Infomation',
    breadcrumb: '通勤車両車検証',
  },
  {
    ...route,
    path: '/employee/:id/vehicleInspection/:inspectionId/edit',
    exact: true,
    component: VehicleInspectionEdit,
    name: 'Edit Commute Vehicle',
    breadcrumb: '情報の編集',
  },
  {
    ...route,
    path: '/employee/:id/vehicleInspection/new',
    exact: true,
    component: VehicleInspectionEdit,
    name: 'New Commute Vehicle',
    breadcrumb: '情報の編集',
  },
  {
    ...route,
    path: '/employee/:id/vehicleInsurance',
    exact: true,
    component: VehicleInsuranceInfo,
    name: 'Vehicle Insurance Infomation',
    breadcrumb: '通勤車両保険',
  },
  {
    ...route,
    path: '/employee/:id/vehicleInsurance/:insuranceId/edit',
    exact: true,
    component: VehicleInsuranceEdit,
    name: 'Edit Vehicle Insurance',
    breadcrumb: '情報の編集',
  },
  {
    ...route,
    path: '/employee/:id/vehicleInsurance/new',
    exact: true,
    component: VehicleInsuranceEdit,
    name: 'New Vehicle Insurance',
    breadcrumb: '情報の編集',
  },
  {
    ...route,
    path: '/employee/:id/accidentReport',
    exact: true,
    component: AccidentReportInfo,
    name: 'Accident report info',
    breadcrumb: '事故情報',
  },
  {
    ...route,
    path: '/employee/:id/accidentReport/:accReportId/edit',
    exact: true,
    component: AccidentReportEdit,
    name: 'Accident report edit',
    breadcrumb: '情報の編集',
  },
  {
    ...route,
    path: '/employee/:id/accidentReport/new',
    exact: true,
    component: AccidentReportEdit,
    name: 'Accident report new',
    breadcrumb: '情報の編集',
  },
  {
    ...route,
    path: '/employee/:id/family',
    exact: true,
    component: FamilyInfo,
    name: 'Family info',
    breadcrumb: '家族情報',
  },
  {
    ...route,
    path: '/employee/:id/family/edit',
    exact: true,
    component: FamilyEdit,
    name: 'Family edit',
    breadcrumb: '情報の編集',
  },
  {
    ...route,
    path: '/employee/:id/operationRecordCert',
    exact: true,
    component: OperationRecordInfo,
    name: 'Operation Record Certificate info',
    breadcrumb: '運転記録証明書',
  },
  {
    ...route,
    path: '/employee/:id/operationRecordCert/:opReportId/edit',
    exact: true,
    component: OperationRecordEdit,
    name: 'Operation Record Certificate edit',
    breadcrumb: '情報の編集',
  },
  {
    ...route,
    path: '/employee/:id/operationRecordCert/new',
    exact: true,
    component: OperationRecordEdit,
    name: 'Operation Record Certificate new',
    breadcrumb: '情報の編集',
  },
  {
    ...route,
    path: '/employee/:id/commuteCostApplication',
    exact: true,
    component: CommuteCostApplicationInfo,
    name: 'Commute Cost Application Info',
    breadcrumb: '通勤手当',
  },
  {
    ...route,
    path: '/employee/:id/commuteCostApplication/new',
    exact: true,
    component: CommuteCostApplicationEdit,
    name: 'Commute Cost New',
    breadcrumb: '情報の編集',
  },
  {
    ...route,
    path: '/employee/:id/commuteCostApplication/:commuteId/edit',
    exact: true,
    component: CommuteCostApplicationEdit,
    name: 'Commute Cost Edit',
    breadcrumb: '情報の編集',
  },
  {
    ...route,
    path: '/employee/:id/employmentInsuranceCert',
    exact: true,
    component: EmploymentInsuranceCertInformation,
    name: 'Employment Insurance Cert Information',
    breadcrumb: '雇用保険証',
  },
  {
    ...route,
    path: '/employee/:id/employmentInsuranceCert/edit',
    exact: true,
    component: EmploymentInsuranceCertEdit,
    name: 'Edit Employment Insurance Cert',
    breadcrumb: '情報の編集',
  },
  {
    ...route,
    path: '/employee/:id/residenceCard',
    exact: true,
    component: EmployeeResidenceCardIndex,
    name: 'Employee Residence Card Information',
    breadcrumb: '在留カード',
  },
  {
    ...route,
    path: '/employee/:id/residenceCard/edit',
    exact: true,
    component: EmployeeResidenceCardEdit,
    name: 'Edit Employee Residence Card',
    breadcrumb: '情報の編集',
  },
  {
    ...route,
    path: '/employee/:id/disabilityCert',
    exact: true,
    component: EmployeeDisabilityCertIndex,
    name: 'Employee Disability Cert Information',
    breadcrumb: '障害者手帳',
  },
  {
    ...route,
    path: '/employee/:id/disabilityCert/edit',
    exact: true,
    component: EmployeeDisabilityCertEdit,
    name: 'Edit Employee Disability Cert',
    breadcrumb: '情報の編集',
  },
  {
    ...route,
    path: '/employee/:id/healthInsuranceCard',
    exact: true,
    component: EmployeeHealthInsuranceCardIndex,
    name: 'Employee Health Insurance Card Information',
    breadcrumb: '健康保険証',
  },
  {
    ...route,
    path: '/employee/:id/healthInsuranceCard/edit',
    exact: true,
    component: EmployeeHealthInsuranceCardEdit,
    name: 'Edit Employee Health Insurance Card',
    breadcrumb: '情報の編集',
  },
  {
    ...route,
    path: '/employee/:id/:relatedDocsName/uploadRelatedDocs',
    exact: false,
    component: RelatedDocsImagesUpload,
    name: 'Upload Related Docs',
    breadcrumb: '画像の登録',
  },
  {
    ...route,
    path: '/employee/:id/:relatedDocsName/editUploadedRelatedDocs/:relatedDocsId',
    exact: false,
    component: RelatedDocsImagesEdit,
    name: 'Edit Related Docs',
    breadcrumb: '画像の編集',
  },
  {
    ...route,
    path: '/employee/:id/:relatedDocsName',
    exact: false,
    component: EmployeeRelatedDocInfo,
    name: 'Employee Related Doc Information',
    breadcrumb: '帳票情報',
  },
];

export default routes;
