import { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import InputDropdown from '../../../components/common/form/InputDropdown';
import InputText from '../../../components/common/form/InputText';
import HeaderForm from '../../../components/HeaderForm';
import axiosInstance, { fetchBanks, fetchBranches } from '../../../api/base';
import {
  DOCS_DIV_VALUE_WITHHOLDING_SLIP,
  DOCS_DIV_VALUE_DISABILITY_CERT,
  AUTH_DELETE,
  AUTH_CREATEUPDATE,
} from '../../../api/constants';
import { setSuccessToast } from '../../../redux/slices/toast';
import { IsAuthorize } from '../../../redux/slices/auth';

const Schema = yup.object().shape({
  account_usage_div: yup.number().required(' '),
  bank_cd: yup.string().required(' '),
  bank_cd_dropdown: yup.string().required(' '),
  branch_cd: yup.string().required(' '),
  branch_cd_dropdown: yup.string().required(' '),
  account_type_div: yup.number().required(' '),
  account_no: yup
    .string()
    .required(' ')
    .trim()
    .matches(/^[0-9]+$/, '7桁の半角数字で入力してください')
    .length(7, '7桁の半角数字で入力してください'),
  account_nm_kn: yup
    .string()
    .required(' ')
    .trim()
    .matches(/^([ァ-ン]|ー)+$/, 'カタカナで入力してください'),
});

export default function TransferBankAccountEdit(props) {
  const [bankAccount, setBankAccount] = useState({
    account_usage_div: '',
    bank_cd_dropdown: '',
    bank_cd: '',
    branch_cd_dropdown: '',
    branch_cd: '',
    account_type_div: '',
    account_no: '',
    account_nm_kn: '',
  });
  const [slipTypes, setSlipTypes] = useState([]);
  const [accountTypes, setAccountTypes] = useState([]);
  const [banks, setBanks] = useState([]);
  const [branches, setBranches] = useState([]);
  const [deleteShowing, setDeleteShowing] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    fetchBanks().then((response) => setBanks(response.data));

    axiosInstance.get(`/divs/${DOCS_DIV_VALUE_WITHHOLDING_SLIP}`).then((response) => {
      setSlipTypes(response.data.sort((cur, next) => cur.display_order - next.display_order));
    });

    axiosInstance.get(`/divs/${DOCS_DIV_VALUE_DISABILITY_CERT}`).then((response) => {
      setAccountTypes(response.data.sort((cur, next) => cur.display_order - next.display_order));
    });
    if (props.location.state) setBankAccount(props.location.state);
  }, []);

  const handleSubmitSend = (params) => {
    axiosInstance({
      method: Object.keys(props.location.state).length ? 'patch' : 'post',
      url: `/employees/${props.match.params.id}/employee_payee_banks`,
      data: {
        bank_cd: params.bank_cd,
        branch_cd: params.branch_cd,
        account_type_div: params.account_type_div,
        account_usage_div: params.account_usage_div,
        account_no: params.account_no,
        account_nm_kn: params.account_nm_kn,
        employee_cd: params.employee_cd,
        payee_bank_id: Object.keys(props.location.state).length ? params.payee_bank_id : null,
      },
    }).then((response) => {
      if (response.data) {
        dispatch(setSuccessToast(true));
        props.history.push(`/employee/${props.match.params.id}/transferBankAccount`);
      }
    });
  };

  const deleteThisCheckup = () => {
    axiosInstance
      .delete(
        `/employees/${props.match.params.id}/employee_payee_banks/${props.match.params.accountId}`
      )
      .then((response) => {
        if (response.data) {
          dispatch(setSuccessToast(true));
          props.history.push(`/employee/${props.match.params.id}/transferBankAccount`);
        }
      });
  };

  return (
    <div className="content w-form">
      <HeaderForm
        deleteShowing={deleteShowing}
        setDeleteShowing={setDeleteShowing}
        deleteThisCheckup={deleteThisCheckup}
        // eslint-disable-next-line react/destructuring-assignment
        haveBtnDelete={props.match.params.accountId && IsAuthorize(AUTH_DELETE)}
      />
      <Formik
        validationSchema={Schema}
        onSubmit={handleSubmitSend}
        enableReinitialize
        initialValues={bankAccount}
      >
        {({ handleSubmit, handleChange, setFieldValue, values, errors, touched }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <div className="mt-6 bg-gray2 pa-16 px-10 pos-rel">
              <div className="w-90">
                <InputDropdown
                  title="口座用途"
                  values={values}
                  errors={errors}
                  touched={touched}
                  fieldKey="account_usage_div"
                  handleChange={handleChange}
                  options={slipTypes}
                  optionKey="div_value"
                  optionName="div_value_nm"
                />
                <InputDropdown
                  title="銀行名"
                  values={values}
                  errors={errors}
                  touched={touched}
                  fieldKey="bank_cd_dropdown"
                  handleChange={(e) => {
                    setFieldValue('bank_cd', e.target.value);
                    setFieldValue('bank_cd_dropdown', e.target.value);
                    fetchBranches(e.target.value).then((res) => {
                      setBranches(res.data);
                      setFieldValue('branch_cd_dropdown', '');
                      setFieldValue('branch_cd', '');
                    });
                  }}
                  options={banks}
                  optionKey="code"
                  optionName="name"
                />
                <InputText
                  title="銀行コード"
                  fieldKey="bank_cd"
                  disabled
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  touched={touched}
                  helpText="銀行名を選択すると自動入力されます"
                />
                <InputDropdown
                  title="銀行名"
                  values={values}
                  errors={errors}
                  touched={touched}
                  defaultValue="支店名を選択してください"
                  disabled={!values.bank_cd}
                  fieldKey="branch_cd_dropdown"
                  handleChange={(e) => {
                    setFieldValue('branch_cd_dropdown', e.target.value);
                    setFieldValue('branch_cd', e.target.value);
                  }}
                  options={branches}
                  optionKey="code"
                  optionName="name"
                  helpText="銀行コードが入力されると選択可能になります"
                />
                <InputText
                  title="支店コード"
                  fieldKey="branch_cd"
                  disabled
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  touched={touched}
                  helpText="支店名を選択すると自動入力されます"
                />
                <InputDropdown
                  title="口座種別"
                  values={values}
                  errors={errors}
                  touched={touched}
                  fieldKey="account_type_div"
                  handleChange={handleChange}
                  options={accountTypes}
                  optionKey="div_value"
                  optionName="div_value_nm"
                />
                <InputText
                  title="口座番号"
                  fieldKey="account_no"
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  touched={touched}
                  helpText="半角数字で入力"
                />
                <InputText
                  title="口座名義"
                  fieldKey="account_nm_kn"
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  touched={touched}
                  helpText="カタカナで入力"
                />
              </div>
            </div>
            <div className="dis-flex flex-center submit-wrapper">
              <button className="save-btn" type="submit" disabled={!IsAuthorize(AUTH_CREATEUPDATE)}>
                登録
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
