import { Col, Form, Row } from 'react-bootstrap';

export default function InputTextArea(props) {
  const {
    values,
    handleChange,
    title = '備考',
    name = 'freememo',
    rows = 3,
    disabled = false,
  } = props;

  return (
    <Form.Group as={Row} className="mb-8">
      <Form.Label className="pl-10 col-sm-3">{title}</Form.Label>
      <Col sm="9">
        <Form.Control
          as="textarea"
          rows={rows}
          name={name}
          value={values[name]}
          onChange={handleChange}
          disabled={disabled}
        />
      </Col>
    </Form.Group>
  );
}
