export default function HistoryList(props) {
  const { list, children, showAllList } = props;
  return (
    <>
      {list.length > (showAllList ? 0 : 1) && (
        <div className="mt-6 bg-gray pa-16">
          <span className="text-lght">過去</span>
          <div className="mt-3 divider-btm" />
          {children}
        </div>
      )}
      {!list.length && <div className="no-data-txt-sm sz-16 w-100">該当データなし</div>}
    </>
  );
}
