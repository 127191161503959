import { useDispatch, useSelector } from 'react-redux';
import IcDownload from '../../../assets/images/ic_download.svg';
import IcRemove from '../../../assets/images/ic_remove.svg';
import { computeDate } from '../../../utils/convert';
import { deleteImage, addSequence } from '../../../redux/slices/relatedDocs';
import axiosInstance from '../../../api/base';

export default function RelatedDocsImagesList({ listType, deleteFile, lastUploadedDate }) {
  const uploadedImages = useSelector((state) => state.relatedDocs.uploadedImages);
  const dispatch = useDispatch();

  const onDownload = async (fileName, url, empCd, id, seq) => {
    if (empCd && id && seq) {
      axiosInstance
        .get(`/employees/${empCd}/employee_related_docs/test/image/${id}/${seq}`, {
          responseType: 'blob',
          dataType: 'binary',
        })
        .then((response) => {
          const imgUrl = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = imgUrl;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => console.log(error));
    } else {
      const response = await fetch(url, { mode: 'no-cors' });
      const blob = await response.blob();
      const file = new File([blob], fileName, { type: 'image/png' });
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(file);
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
  };

  const onDelete = (deleteIndex, sequence) => {
    deleteFile(deleteIndex);
    dispatch(deleteImage(deleteIndex));

    if (sequence) {
      dispatch(addSequence(sequence));
    }
  };

  return (
    <>
      {listType === 'edit' && uploadedImages.length > 0 && (
        <div>
          <span className="last-updated-date__title">登録日：</span>
          <span className="last-updated-date__date">{computeDate(lastUploadedDate)}</span>
        </div>
      )}

      <div className="related-docs-images__list__title">画像一覧</div>

      <div className="related-docs-images__list__wrapper">
        {!!uploadedImages &&
          uploadedImages.map(
            ({ name, sequence, url, employeeCd, relatedDocsId, relatedDocsSeq }, index) => (
              <div key={index}>
                <div className="related-docs-images__list__image">
                  <img src={url} alt={name} />
                </div>

                <div className="text-prim">{name}</div>

                <div className="related-docs-images__list__action">
                  <div onClick={() => onDelete(index, sequence)} aria-hidden="true">
                    <img alt="remove" src={IcRemove} />
                    <span>削除</span>
                  </div>

                  <div
                    onClick={() => onDownload(name, url, employeeCd, relatedDocsId, relatedDocsSeq)}
                    aria-hidden="true"
                  >
                    <img alt="download" src={IcDownload} />
                    <span>ダウンロード</span>
                  </div>
                </div>
              </div>
            )
          )}
      </div>
    </>
  );
}
