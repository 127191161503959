import { useState, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import InputDateForm from '../../../components/common/form/InputDateForm';
import InputDropdown from '../../../components/common/form/InputDropdown';
import InputTextArea from '../../../components/common/form/InputTextArea';
import HeaderForm from '../../../components/HeaderForm';
import axiosInstance from '../../../api/base';
import { generateDateString } from '../../../utils/convert';
import { handleBlur, handleChangePhone } from '../../../utils/handleInput';
import { setSuccessToast } from '../../../redux/slices/toast';
import { getUser } from '../../../redux/slices/user';
import { IsAuthorize } from '../../../redux/slices/auth';
import { AUTH_CREATEUPDATE, AUTH_DELETE } from '../../../api/constants';

const Schema = yup.object().shape({
  lend_item_cd: yup.string().required(' '),
  lend_ymd_year: yup.number().required(' '),
  lend_ymd_month: yup.number().required(' '),
  lend_ymd_day: yup.number().required(' '),
  lend_fixture_memo: yup.string().notRequired(),
});

export default function EquipmentLoanConfirm(props) {
  const [loan, setLoan] = useState({});
  const [companyId, setCompanyId] = useState('');
  const [lendFixtures, setLendFixtures] = useState([]);
  const [deleteShowing, setDeleteShowing] = useState(false);
  const { currentUser } = useSelector(getUser);

  const dispatch = useDispatch();

  const deleteThisCheckup = () => {
    axiosInstance
      .delete(`/employees/${props.match.params.id}/fixture_lends/${props.match.params.lendId}`)
      .then((response) => {
        if (response.data) {
          dispatch(setSuccessToast(true));
          props.history.push(`/employee/${props.match.params.id}`);
        }
      });
  };

  useEffect(() => {
    if (props.match.params.lendId) {
      const { data } = props.location.state;
      const lendDate = data.lend_ymd?.split('-') || [];
      const returnDate = data.return_ymd?.split('-') || [];
      const tel = data.tel?.split('-') || [];

      setLoan({
        lend_ymd_year: parseInt(lendDate[0], 10) || '',
        lend_ymd_month: parseInt(lendDate[1], 10) || '',
        lend_ymd_day: parseInt(lendDate[2], 10) || '',
        return_ymd_year: parseInt(returnDate[0], 10) || '',
        return_ymd_month: parseInt(returnDate[1], 10) || '',
        return_ymd_day: parseInt(returnDate[2], 10) || '',
        tel_areacd: tel[0] || '',
        tel_localcd: tel[1] || '',
        tel_number: tel[2] || '',
        lend_fixture_memo: data.lend_fixture_memo,
        lend_item_cd: data.lend_item_cd,
      });
    } else {
      setLoan({
        lend_ymd_year: '',
        lend_ymd_month: '',
        lend_ymd_day: '',
        return_ymd_year: '',
        return_ymd_month: '',
        return_ymd_day: '',
        lend_item_cd: '',
      });
    }
    setCompanyId(currentUser?.company_cd);
  }, [currentUser]);

  useEffect(() => {
    if (companyId) {
      axiosInstance
        .get(`/companies/${companyId}/fixture_lends`)
        .then((response) => setLendFixtures(response.data));
    }
  }, [companyId]);

  const handleSubmitSend = (params) => {
    axiosInstance({
      method: props.match.params.lendId ? 'patch' : 'post',
      url: `/employees/${props.match.params.id}/fixture_lends`,
      data: {
        ...params,
        lend_seq: props.match.params.lendId || null,
        lend_ymd: generateDateString(
          params.lend_ymd_year,
          params.lend_ymd_month,
          params.lend_ymd_day
        ),
        return_ymd: generateDateString(
          params.return_ymd_year,
          params.return_ymd_month,
          params.return_ymd_day
        ),
        tel: generateDateString(params.tel_areacd, params.tel_localcd, params.tel_number),
      },
    }).then((response) => {
      if (response?.data) {
        dispatch(setSuccessToast(true));
        props.history.push(`/employee/${props.match.params.id}`);
      }
    });
  };
  return (
    <div className="content w-form">
      <HeaderForm
        deleteShowing={deleteShowing}
        setDeleteShowing={setDeleteShowing}
        deleteThisCheckup={deleteThisCheckup}
        // eslint-disable-next-line react/destructuring-assignment
        haveBtnDelete={props.match.params.lendId && IsAuthorize(AUTH_DELETE)}
      />
      <Formik
        validationSchema={Schema}
        onSubmit={handleSubmitSend}
        enableReinitialize
        initialValues={loan}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          setFieldTouched,
          setFieldValue,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <div className="mt-6 bg-gray2 pa-16 px-10 pos-rel">
              <div className="w-90">
                <InputDropdown
                  title="備品区分"
                  values={values}
                  errors={errors}
                  touched={touched}
                  fieldKey="lend_item_cd"
                  handleChange={handleChange}
                  options={lendFixtures}
                  optionKey="lend_fixture_cd"
                  optionName="lend_fixture_nm"
                />

                <InputDateForm
                  title="貸与日"
                  titleRequired
                  handleChange={handleChange}
                  valuePrefix="lend_ymd"
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                />
                <InputDateForm
                  title="返却日"
                  titleRequired={false}
                  handleChange={handleChange}
                  valuePrefix="return_ymd"
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                />
                <Form.Group
                  as={Row}
                  className={`mb-6 ${
                    (errors.tel_areacd || errors.tel_localcd || errors.tel_number) && 'mb-11'
                  }`}
                >
                  <Form.Label column sm="3" className="pl-10">
                    電話番号
                  </Form.Label>
                  <Col as={Row} className="item-center">
                    <Col className="pos-rel">
                      <Form.Control
                        name="tel_areacd"
                        value={values.tel_areacd}
                        required
                        onBlur={(e) => handleBlur(e, setFieldValue, setFieldTouched)}
                        onChange={(e) => handleChangePhone(e, setFieldValue)}
                        isInvalid={errors.tel_areacd}
                      />
                      <Form.Control.Feedback type="invalid" as="small" className="pos-abs">
                        {errors.tel_areacd}
                      </Form.Control.Feedback>
                    </Col>
                    <Col sm="1" className="text-center">
                      -
                    </Col>
                    <Col className="pos-rel">
                      <Form.Control
                        name="tel_localcd"
                        value={values.tel_localcd}
                        required
                        onBlur={(e) => handleBlur(e, setFieldValue, setFieldTouched)}
                        onChange={(e) => handleChangePhone(e, setFieldValue)}
                        isInvalid={errors.tel_localcd}
                      />
                      <Form.Control.Feedback type="invalid" as="small" className="pos-abs">
                        {errors.tel_localcd}
                      </Form.Control.Feedback>
                    </Col>
                    <Col sm="1" className="text-center">
                      -
                    </Col>
                    <Col className="pos-rel">
                      <Form.Control
                        name="tel_number"
                        value={values.tel_number}
                        required
                        onBlur={(e) => handleBlur(e, setFieldValue, setFieldTouched)}
                        onChange={(e) => handleChangePhone(e, setFieldValue)}
                        isInvalid={errors.tel_number}
                      />
                      <Form.Control.Feedback type="invalid" as="small" className="pos-abs">
                        {errors.tel_number}
                      </Form.Control.Feedback>
                    </Col>
                  </Col>
                  <Col md={{ span: 9, offset: 3 }}>
                    {!errors.tel_areacd && !errors.tel_localcd && !errors.tel_number && (
                      <Form.Text className="text-muted">半角数字で入力してください</Form.Text>
                    )}
                  </Col>
                </Form.Group>
                <InputTextArea
                  name="lend_fixture_memo"
                  values={values}
                  handleChange={handleChange}
                />
              </div>
            </div>
            <div className="dis-flex flex-center submit-wrapper">
              <button className="save-btn" type="submit" disabled={!IsAuthorize(AUTH_CREATEUPDATE)}>
                登録
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
