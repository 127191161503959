import { configureStore } from '@reduxjs/toolkit';
import toast from './slices/toast';
import auth from './slices/auth';
import user from './slices/user';
import relatedDocs from './slices/relatedDocs';

export default configureStore({
  reducer: {
    auth,
    toast,
    user,
    relatedDocs,
  },
});
