import axios from 'axios';
import fetchJsonp from 'fetch-jsonp';
import {
  BASE_URL,
  LOGIN_API,
  POSTAL_CODE_SEARCH_ENDPOINT,
  BANK_SEARCH_ENDPOINT,
} from './constants';
import cache from '../redux/cache';

const axiosInstance = axios.create({});

// eslint-disable-next-line dot-notation
axiosInstance.defaults.baseURL = BASE_URL;
axiosInstance.defaults.headers['Content-Type'] = 'application/json';

// Request interceptor
axiosInstance.interceptors.request.use((config) => {
  const token = cache.get('token');

  if (!token && config.url !== LOGIN_API) {
    window.location = LOGIN_API;
    return {
      ...config,
      cancelToken: new axios.CancelToken((cancel) => cancel('No user token')),
    };
  }
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

// Response interceptor
// axiosInstance.interceptors.response.use(
//   (res) => res,
//   (error) => {
//     if (error.response.status === 401) {
//       localStorage.removeItem('token');
//       window.location = LOGIN_API;
//       return;
//     }
//     if (error.response.status === 403) {
//       localStorage.removeItem('token');
//       window.location = LOGIN_API;
//     }
//   }
// );

export default axiosInstance;

export const fetchPostalCode = (zipcode, limit) => {
  const query = new URLSearchParams();
  query.append('zipcode', zipcode);
  if (limit) {
    query.append('limit', `${limit}`);
  }

  return fetchJsonp(`${POSTAL_CODE_SEARCH_ENDPOINT}?${query.toString()}`).then((res) => res.json());
};

export const fetchBanks = () => axios.get(`${BANK_SEARCH_ENDPOINT}/banks.json`);
export const fetchBranches = (bankCode) =>
  axios.get(`${BANK_SEARCH_ENDPOINT}/banks/${bankCode}/branches.json`);
