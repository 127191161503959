import { useState, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import InputDateForm from '../../../components/common/form/InputDateForm';
import InputDropdown from '../../../components/common/form/InputDropdown';
import axiosInstance from '../../../api/base';
import { AUTH_CREATEUPDATE, AUTH_DELETE, DIV_ID_APTITUDE_TEST } from '../../../api/constants';
import { generateDateString } from '../../../utils/convert';
import CheckBox from '../../../components/Input/CheckBox';
import { setSuccessToast } from '../../../redux/slices/toast';
import InputTextArea from '../../../components/common/form/InputTextArea';
import HeaderForm from '../../../components/HeaderForm';
import { IsAuthorize } from '../../../redux/slices/auth';

const Schema = yup.object().shape({
  checkup_res_expired_ymd_year: yup.number().required(' '),
  checkup_res_expired_ymd_month: yup.number().required(' '),
  checkup_res_expired_ymd_day: yup.number().required(' '),
  aptitude_test_menu_div: yup.number().required(' '),
  checkup_res_freememo: yup.string().notRequired(),
});

export default function AptitudeTestEdit(props) {
  const dispatch = useDispatch();
  const [aptitudeTest, setAptitudeTest] = useState({});
  const [deleteShowing, setDeleteShowing] = useState(false);
  const [diagnosisResults, setResults] = useState([]);

  useEffect(() => {
    axiosInstance.get(`/divs/${DIV_ID_APTITUDE_TEST}`).then((response) => {
      setResults(response.data.sort((cur, next) => cur.display_order - next.display_order));
    });
    if (props.match.params.aptTestId && !props.location.state) {
      axiosInstance
        .get(
          `/employees/${props.match.params.id}/employee_aptitude_tests/${props.match.params.aptTestId}`
        )
        .then((response) => {
          const checkupPlanDate = response.data.checkup_plans_ymd?.split('-') || [];
          const checkupDate = response.data.checkup_ymd?.split('-') || [];
          const checkupResExpiredDate = response.data.checkup_res_expired_ymd?.split('-') || [];

          setAptitudeTest({
            ...response.data,
            checkup_plans_ymd_year: parseInt(checkupPlanDate[0], 10) || '',
            checkup_plans_ymd_month: parseInt(checkupPlanDate[1], 10) || '',
            checkup_plans_ymd_day: parseInt(checkupPlanDate[2], 10) || '',
            checkup_ymd_year: parseInt(checkupDate[0], 10) || '',
            checkup_ymd_month: parseInt(checkupDate[1], 10) || '',
            checkup_ymd_day: parseInt(checkupDate[2], 10) || '',
            checkup_res_expired_ymd_year: parseInt(checkupResExpiredDate[0], 10) || '',
            checkup_res_expired_ymd_month: parseInt(checkupResExpiredDate[1], 10) || '',
            checkup_res_expired_ymd_day: parseInt(checkupResExpiredDate[2], 10) || '',
          });
        });
    } else if (props.match.params.aptTestId && props.location.state) {
      const checkupPlanDate = props.location.state.checkup_plans_ymd?.split('-') || [];
      const checkupDate = props.location.state.checkup_ymd?.split('-') || [];
      const checkupResExpiredDate = props.location.state.checkup_res_expired_ymd?.split('-') || [];

      setAptitudeTest({
        ...props.location.state,
        checkup_plans_ymd_year: parseInt(checkupPlanDate[0], 10) || '',
        checkup_plans_ymd_month: parseInt(checkupPlanDate[1], 10) || '',
        checkup_plans_ymd_day: parseInt(checkupPlanDate[2], 10) || '',
        checkup_ymd_year: parseInt(checkupDate[0], 10) || '',
        checkup_ymd_month: parseInt(checkupDate[1], 10) || '',
        checkup_ymd_day: parseInt(checkupDate[2], 10) || '',
        checkup_res_expired_ymd_year: parseInt(checkupResExpiredDate[0], 10) || '',
        checkup_res_expired_ymd_month: parseInt(checkupResExpiredDate[1], 10) || '',
        checkup_res_expired_ymd_day: parseInt(checkupResExpiredDate[2], 10) || '',
      });
    } else {
      setAptitudeTest({
        checkup_plans_ymd_year: '',
        checkup_plans_ymd_month: '',
        checkup_plans_ymd_day: '',
        undecided_flag: false,
        checkup_ymd_year: '',
        checkup_ymd_month: '',
        checkup_ymd_day: '',
        checkup_res_expired_ymd_year: '',
        checkup_res_expired_ymd_month: '',
        checkup_res_expired_ymd_day: '',
        aptitude_test_menu_div: '',
        checkup_res_freememo: '',
      });
    }
  }, []);

  const handleSubmitSend = (params, { setErrors }) => {
    const checkupPlansDate = generateDateString(
      params.checkup_plans_ymd_year,
      params.checkup_plans_ymd_month,
      params.checkup_plans_ymd_day
    );
    if (!checkupPlansDate && !params.undecided_flag) {
      setErrors({
        checkup_plans_ymd_year: ' ',
        checkup_plans_ymd_month: ' ',
        checkup_plans_ymd_day: ' ',
      });
    } else
      axiosInstance({
        method: props.match.params.aptTestId ? 'patch' : 'post',
        url: `/employees/${props.match.params.id}/employee_aptitude_tests`,
        data: {
          ...params,
          checkup_plans_ymd: checkupPlansDate,
          checkup_ymd: params.checkup_ymd_day
            ? generateDateString(
                params.checkup_ymd_year,
                params.checkup_ymd_month,
                params.checkup_ymd_day
              )
            : null,
          checkup_res_expired_ymd: generateDateString(
            params.checkup_res_expired_ymd_year,
            params.checkup_res_expired_ymd_month,
            params.checkup_res_expired_ymd_day
          ),
        },
      }).then((response) => {
        if (response.data) {
          dispatch(setSuccessToast(true));
          props.history.push(`/employee/${props.match.params.id}/aptitudeTest`);
        }
      });
  };
  const deleteThisCheckup = () => {
    axiosInstance
      .delete(
        `/employees/${props.match.params.id}/employee_aptitude_tests/${props.match.params.aptTestId}`
      )
      .then((response) => {
        if (response.data) props.history.push(`/employee/${props.match.params.id}/aptitudeTest`);
      });
  };
  return (
    <div className="content w-form">
      <HeaderForm
        deleteShowing={deleteShowing}
        setDeleteShowing={setDeleteShowing}
        deleteThisCheckup={deleteThisCheckup}
        // eslint-disable-next-line react/destructuring-assignment
        haveBtnDelete={props.match.params.aptTestId && IsAuthorize(AUTH_DELETE)}
      />
      <Formik
        validationSchema={Schema}
        onSubmit={handleSubmitSend}
        enableReinitialize
        initialValues={aptitudeTest}
      >
        {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <div className="mt-6 bg-gray2 pa-16 pos-rel">
              <div className="w-90">
                <InputDateForm
                  title="診断予定日"
                  titleRequired
                  handleChange={handleChange}
                  valuePrefix="checkup_plans_ymd"
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  disabled={values.undecided_flag}
                />
                <Row>
                  <Form.Group as={Col} md={{ span: 9, offset: 3 }} className="mb-3">
                    <CheckBox
                      name="undecided_flag"
                      label="診断予定日は未定"
                      checked={values.undecided_flag}
                      onChange={handleChange}
                      isInvalid={!values.undecided_flag && !values.checkup_plans_ymd_year}
                      disabled={values.checkup_plans_ymd_year}
                    />
                  </Form.Group>
                </Row>
                <InputDateForm
                  title="診断日"
                  handleChange={handleChange}
                  valuePrefix="checkup_ymd"
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                />
                <InputDropdown
                  title="診断メニュー"
                  values={values}
                  errors={errors}
                  touched={touched}
                  fieldKey="aptitude_test_menu_div"
                  handleChange={handleChange}
                  options={diagnosisResults}
                  optionKey="div_value"
                  optionName="div_value_nm"
                />
                <InputDateForm
                  title="診断結果失効日"
                  titleRequired
                  handleChange={handleChange}
                  valuePrefix="checkup_res_expired_ymd"
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                />
                <InputTextArea
                  name="checkup_res_freememo"
                  values={values}
                  handleChange={handleChange}
                />
              </div>
            </div>
            <div className="dis-flex flex-center submit-wrapper">
              <button className="save-btn" type="submit" disabled={!IsAuthorize(AUTH_CREATEUPDATE)}>
                登録
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
