import React from 'react';
import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { loginAction, selectAuth } from '../redux/slices/auth';

import logo from '../assets/images/logo.svg';

// https://react-bootstrap.github.io/components/forms/#forms-validation-libraries
const schema = Yup.object().shape({
  id: Yup.string().required(),
  password: Yup.string().required(),
});

const logoStyle = {
  width: '250px',
};
function Login() {
  const dispatch = useDispatch();
  const { loading, error } = useSelector(selectAuth);

  const submitForm = async (data) => {
    const params = {
      id: data.id,
      login_password: data.password,
    };
    dispatch(loginAction(params));
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={submitForm}
      initialValues={{ id: '', password: '' }}
    >
      {({ handleSubmit, handleChange, isValid, dirty, values, errors }) => (
        <div className="screen-center login">
          <div className="text-center">
            <h1 className="text-primary login__header">
              <img src={logo} style={logoStyle} alt="Pastel" />
            </h1>
          </div>
          <Form noValidate validated={isValid} onSubmit={handleSubmit} className="mt-10">
            <Form.Group as={Row} controlId="id-input" noGutters>
              <Form.Label column sm={3}>
                ログインID：
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  name="id"
                  type="text"
                  value={values.id}
                  placeholder="IDを入力してください"
                  onChange={handleChange}
                  isInvalid={!!errors.id}
                />
                <Form.Control.Feedback type="invalid">IDを入力してください</Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="password-input" className="mt-8" noGutters>
              <Form.Label column sm={3}>
                パスワード：
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  name="password"
                  type="password"
                  value={values.password}
                  placeholder="パスワードを入力してください"
                  onChange={handleChange}
                  isInvalid={!!errors.password}
                />
                <Form.Control.Feedback type="invalid">
                  パスワードを入力してください
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            {error && dirty && <div className="text-center text-danger">{error}</div>}
            <div className="text-center">
              <Button
                variant="secondary"
                className="login__btn--submit mt-8"
                type="submit"
                disabled={!isValid || !dirty || loading}
              >
                ログイン
              </Button>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
}

export default withRouter(Login);
