import { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import InputDateForm from '../../../components/common/form/InputDateForm';
import axiosInstance from '../../../api/base';
import { generateDateString } from '../../../utils/convert';
import { setSuccessToast } from '../../../redux/slices/toast';
import InputTextArea from '../../../components/common/form/InputTextArea';
import HeaderForm from '../../../components/HeaderForm';
import { AUTH_CREATEUPDATE, AUTH_DELETE } from '../../../api/constants';
import { IsAuthorize } from '../../../redux/slices/auth';

const Schema = yup.object().shape({
  repayment_start_ym_year: yup.number().required(' '),
  repayment_start_ym_month: yup.number().required(' '),
  repayment_end_ym_year: yup.number().required(' '),
  repayment_end_ym_month: yup.number().required(' '),
  freememo: yup.string().notRequired(),
});

export default function RepaymentPlanEdit(props) {
  const [repaymentPlan, setRepaymentPlan] = useState({});
  const [deleteShowing, setDeleteShowing] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.match.params.planId) {
      axiosInstance
        .get(`/employees/${props.match.params.id}/repayment_plans/${props.match.params.planId}`)
        .then((response) => {
          const consultPlanDate = response.data.repayment_start_ym?.split('-') || [];
          const consultDate = response.data.repayment_end_ym?.split('-') || [];

          setRepaymentPlan({
            ...response.data,
            repayment_start_ym_year: parseInt(consultPlanDate[0], 10) || '',
            repayment_start_ym_month: parseInt(consultPlanDate[1], 10) || '',
            repayment_end_ym_year: parseInt(consultDate[0], 10) || '',
            repayment_end_ym_month: parseInt(consultDate[1], 10) || '',
          });
        });
    } else {
      setRepaymentPlan({
        repayment_start_ym_year: '',
        repayment_start_ym_month: '',
        repayment_end_ym_year: '',
        repayment_end_ym_month: '',
        freememo: '',
      });
    }
  }, []);

  const handleSubmitSend = (params) => {
    axiosInstance({
      method: props.match.params.planId ? 'patch' : 'post',
      url: `/employees/${props.match.params.id}/repayment_plans`,
      data: {
        ...params,
        repayment_start_ym: generateDateString(
          params.repayment_start_ym_year,
          params.repayment_start_ym_month,
          1
        ),
        repayment_end_ym: generateDateString(
          params.repayment_end_ym_year,
          params.repayment_end_ym_month,
          1
        ),
      },
    }).then((response) => {
      if (response.data) {
        dispatch(setSuccessToast(true));
        props.history.push(`/employee/${props.match.params.id}/repaymentPlan`);
      }
    });
  };
  const deleteThisCheckup = () => {
    axiosInstance
      .delete(`/employees/${props.match.params.id}/repayment_plans/${props.match.params.planId}`)
      .then((response) => {
        if (response.data) props.history.push(`/employee/${props.match.params.id}/repaymentPlan`);
      });
  };
  return (
    <div className="content w-form">
      <HeaderForm
        deleteShowing={deleteShowing}
        setDeleteShowing={setDeleteShowing}
        deleteThisCheckup={deleteThisCheckup}
        // eslint-disable-next-line react/destructuring-assignment
        haveBtnDelete={props.match.params.planId && IsAuthorize(AUTH_DELETE)}
      />
      <Formik
        validationSchema={Schema}
        onSubmit={handleSubmitSend}
        enableReinitialize
        initialValues={repaymentPlan}
      >
        {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <div className="mt-6 bg-gray2 pa-16 pos-rel">
              <div className="w-90">
                <InputDateForm
                  title="返済開始月"
                  titleRequired
                  noDay
                  handleChange={handleChange}
                  valuePrefix="repayment_start_ym"
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                />
                <InputDateForm
                  title="返済終了予定月"
                  titleRequired
                  noDay
                  handleChange={handleChange}
                  valuePrefix="repayment_end_ym"
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                />
                <InputTextArea values={values} handleChange={handleChange} />
              </div>
            </div>
            <div className="dis-flex flex-center submit-wrapper">
              <button className="save-btn" type="submit" disabled={!IsAuthorize(AUTH_CREATEUPDATE)}>
                登録
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
