import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import axiosInstance from '../../../api/base';
import { AUTH_CREATEUPDATE, AUTH_DELETE } from '../../../api/constants';
import InputText from '../../../components/common/form/InputText';
import HeaderForm from '../../../components/HeaderForm';
import { IsAuthorize } from '../../../redux/slices/auth';
import { setSuccessToast } from '../../../redux/slices/toast';

export default function EmploymentInsuranceCertEdit({ history, match }) {
  const [employmentInsuranceCert, setEmploymentInsuranceCert] = useState({
    uiInsuredNo1Dig: '',
    uiInsuredNo4Dig: '',
    uiInsuredNo6Dig: '',
  });
  const [deleteShowing, setDeleteShowing] = useState(false);

  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    uiInsuredNo1Dig: Yup.string()
      .length(1, '1桁の文字列を入力してください')
      .required(' ')
      .nullable(),
    uiInsuredNo4Dig: Yup.string()
      .length(4, '4桁の文字列を入力してください')
      .required(' ')
      .nullable(),
    uiInsuredNo6Dig: Yup.string()
      .length(6, '6桁の文字列を入力してください')
      .required(' ')
      .nullable(),
  });

  const handleSubmitSend = (params) => {
    axiosInstance
      .post(`/employees/${match.params.id}/employee_employment_insurance_cert`, {
        ui_insured_no_1dig: params.uiInsuredNo1Dig,
        ui_insured_no_4dig: params.uiInsuredNo4Dig,
        ui_insured_no_6dig: params.uiInsuredNo6Dig,
      })
      .then(({ status }) => {
        if (status === 200) {
          dispatch(setSuccessToast(true));
          history.push(`/employee/${match.params.id}/employmentInsuranceCert`);
        }
      });
  };

  const deleteThisCheckup = () => {
    axiosInstance
      .delete(`/employees/${match.params.id}/employee_employment_insurance_cert`)
      .then((response) => {
        if (response.data) history.push(`/employee/${match.params.id}/employmentInsuranceCert`);
      });
  };

  useEffect(() => {
    axiosInstance
      .get(`/employees/${match.params.id}/employee_employment_insurance_cert`)
      .then(({ data, status }) => {
        if (status === 200) {
          setEmploymentInsuranceCert({
            uiInsuredNo1Dig: data ? data.ui_insured_no_1dig : '',
            uiInsuredNo4Dig: data ? data.ui_insured_no_4dig : '',
            uiInsuredNo6Dig: data ? data.ui_insured_no_6dig : '',
          });
        }
      });
  }, []);

  return (
    <div className="content w-form">
      <HeaderForm
        deleteShowing={deleteShowing}
        setDeleteShowing={setDeleteShowing}
        deleteThisCheckup={deleteThisCheckup}
        haveBtnDelete={IsAuthorize(AUTH_DELETE)}
      />
      <Formik
        initialValues={employmentInsuranceCert}
        validationSchema={validationSchema}
        onSubmit={handleSubmitSend}
        enableReinitialize
      >
        {({ handleSubmit, handleChange, values, errors, touched }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <div className="mt-6 bg-gray2 pa-16 px-10 pos-rel">
              <div className="w-90">
                <InputText
                  helpText="半角数字で入力"
                  title="被保険者番号4桁"
                  fieldKey="uiInsuredNo4Dig"
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className="w-90">
                <InputText
                  helpText="半角数字で入力"
                  title="被保険者番号6桁"
                  fieldKey="uiInsuredNo6Dig"
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className="w-90">
                <InputText
                  helpText="半角数字で入力"
                  title="被保険者番号1桁"
                  fieldKey="uiInsuredNo1Dig"
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
              </div>
            </div>
            <div className="dis-flex flex-center submit-wrapper">
              <button className="save-btn" type="submit" disabled={!IsAuthorize(AUTH_CREATEUPDATE)}>
                登録
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
