import { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import FileHandler from '../../../components/FileHandler';
import HistoryList from '../../../components/HistoryList';
import InfoPageHeader from '../../../components/InfoPageHeader';
import axiosInstance from '../../../api/base';
import { DOCS_DIV_VALUE_HEALTH_CERT } from '../../../api/constants';
import { RELATED_DOCS_DIV_VALUE_NAMES } from '../../../utils/const';
import { computeDate } from '../../../utils/convert';

export default function HealthCertInfo(props) {
  const [healthCheckups, setHealthCheckups] = useState([]);
  const [images, setImages] = useState([]);

  useEffect(() => {
    axiosInstance
      .get(
        `/employees/${props.match.params.id}/employee_related_docs/test/div/${DOCS_DIV_VALUE_HEALTH_CERT}`
      )
      .then((response) => {
        setImages(response.data);
      });

    axiosInstance
      .get(`employees/${props.match.params.id}/employee_health_checkups`)
      .then((response) => {
        setHealthCheckups(response.data);
      });
  }, []);

  const gotoPage = (subroute, state = {}) => {
    props.history.push({
      pathname: `/employee/${props.match.params.id}/healthCert/${subroute}`,
      state,
    });
  };

  return (
    <div className="content">
      <Row>
        <Col>
          <InfoPageHeader onCreate={() => gotoPage('new')} />
          {healthCheckups.length > 0 && (
            <FileHandler
              className="divider-btm height-120"
              onEditClicked={() =>
                gotoPage(`${healthCheckups[0].health_checkup_id}/edit`, healthCheckups[0])
              }
            >
              <span>帳票：</span>
              <span className="text-prim">
                {RELATED_DOCS_DIV_VALUE_NAMES[DOCS_DIV_VALUE_HEALTH_CERT]}
              </span>
              <br />
              <span className="text-lght">更新日：</span>
              <span>{computeDate(healthCheckups[0]?.update_datetime)}</span>
              <br />
              <span className="text-lght">受診予定日：</span>
              <span>{computeDate(healthCheckups[0]?.consult_plans_ymd)}</span>
            </FileHandler>
          )}
        </Col>
        <Col>
          <InfoPageHeader onCreate={() => gotoPage('uploadRelatedDocs')} imageHeader />
          {images[0] && (
            <FileHandler
              imageHandler
              className="divider-btm"
              onEditClicked={() => gotoPage(`editUploadedRelatedDocs/${images[0].related_docs_id}`)}
            >
              <span>更新日：</span>
              <span className="text-prim">{computeDate(images[0].related_docs_ymd)}</span>
            </FileHandler>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <HistoryList list={healthCheckups}>
            {healthCheckups.slice(1).map((checkup) => (
              <FileHandler
                grayIcon
                className="divider-btm"
                onEditClicked={() => gotoPage(`${checkup.health_checkup_id}/edit`, checkup)}
              >
                <span>帳票：</span>
                <span className="text-prim">
                  {RELATED_DOCS_DIV_VALUE_NAMES[DOCS_DIV_VALUE_HEALTH_CERT]}
                </span>
                <br />
                <span>更新日：</span>
                <span className="text-prim">{computeDate(checkup.update_datetime)}</span>
                <br />
                <span>受診予定日：</span>
                <span className="text-prim">{computeDate(checkup.consult_plans_ymd)}</span>
              </FileHandler>
            ))}
          </HistoryList>
        </Col>
        <Col>
          <HistoryList list={images}>
            {images.slice(1).map((image) => (
              <FileHandler
                centerEdit
                imageHandler
                grayIcon
                className="divider-btm"
                onEditClicked={() => gotoPage(`editUploadedRelatedDocs/${image.related_docs_id}`)}
              >
                <span>更新日：</span>
                <span className="text-prim">{computeDate(image.related_docs_ymd)}</span>
              </FileHandler>
            ))}
          </HistoryList>
        </Col>
      </Row>
    </div>
  );
}
