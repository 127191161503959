// BASE API URL
export const BASE_URL = process.env.REACT_APP_AXIOS_BASE_URL || '/api/v1';

export const POSTAL_CODE_SEARCH_ENDPOINT = 'https://zipcloud.ibsnet.co.jp/api/search';
export const BANK_SEARCH_ENDPOINT = 'https://bank.teraren.com/';

// API routing
export const LOGIN_API = '/login';
export const ME_API = '/me';
export const USER_API = '/users';

export const AUTH_REFERENCE = 1;
export const AUTH_CREATEUPDATE = 2;
export const AUTH_DELETE = 3;

export const DIV_ID_APTITUDE_TEST = 1;
export const DIV_ID_INSURANCE = 2;
export const DIV_ID_SKILL = 3;
export const DIV_ID_EMG_CONTACT_RELATIONSHIP = 6;
export const DIV_ID_ACCIDENT = 7;
export const DIV_ID_COMPENSATION = 8;
export const DIV_ID_DEPARTMENT = 9;
export const DIV_ID_DIAGNOSIS_RESULT = 11;
export const DIV_ID_EMPLOYMENT_TYPE = 15;
export const DIV_ID_EMPLOYMENT_TERM = 16;
export const DIV_ID_JOB = 17;
export const DIV_ID_RESIDENCE_CARD = 19;
export const DIV_ID_COMMUTE_WAY = 20;
export const DIV_ID_VEHICLE = 21;
export const DIV_ID_FAMILY_RELATIONSHIP = 23;
export const DIV_ID_FAMILY_LIVING_TOGETHER = 24;
export const DIV_ID_SPECIAL_CASES_FAMILY_DEPENDENTS = 25;
export const DIV_ID_BLOOD_TYPE = 29;
export const DIV_ID_NATIONALITY = 30;
export const DIV_ID_SIZE = 31;
export const DIV_ID_POSITION = 33;

export const DOCS_DIV_VALUE_WORKING_RESUME = 1;
export const DOCS_DIV_VALUE_WORKING_CURRICULUM_VITAE = 2;
export const DOCS_DIV_VALUE_PENSION_BOOK = 3;
export const DOCS_DIV_VALUE_DISABILITY_CERT = 4;
export const DOCS_DIV_VALUE_WITHHOLDING_SLIP = 5;
export const DOCS_DIV_VALUE_WORKING_CONDITIONS_NOTICE = 6;
export const DOCS_DIV_VALUE_EMPLOYMENT_AGREEMENT = 7;
export const DOCS_DIV_VALUE_IDENTITY_GUARANTEE = 8;
export const DOCS_DIV_VALUE_COMMUTING_DIAGRAM = 9;
export const DOCS_DIV_VALUE_POWER_OF_ATTORNEY = 10;
export const DOCS_DIV_VALUE_CITY_PREFECTURAL_PAYMENT_SLIP = 11;
export const DOCS_DIV_VALUE_CITIZEN_CARD = 12;
export const DOCS_DIV_VALUE_HEALTH_CERT = 13;
export const DOCS_DIV_VALUE_OPERATION_RECORD_CERT = 14;
export const DOCS_DIV_VALUE_BEGINNER_APPROPRIATE_DIAGNOSIS = 15;
export const DOCS_DIV_VALUE_DRIVER_LICENSE = 16;
export const DOCS_DIV_VALUE_VEHICLE_INSPECTION_OF_COMMUTE = 17;
export const DOCS_DIV_VALUE_COMMUTER_VEHICLE_INSURANCE = 18;
export const DOCS_DIV_VALUE_RESIDENCE_CARD = 19;
export const DOCS_DIV_VALUE_EMPLOYMENT_INSURANCE_CERT = 21;
export const DOCS_DIV_VALUE_VEHICLE_QUALIFICATION_INFO = 22;
export const DOCS_DIV_VALUE_HEALTH_INSURANCE_CARD = 23;
export const DOCS_DIV_VALUE_WRITTEN_OATH = 24;
export const DOCS_DIV_VALUE_TRANSFER_BANK_ACCOUNT = 25;
export const DOCS_DIV_VALUE_DEDUCTION_FOR_DEPENDS = 26;
export const DOCS_DIV_VALUE_HOUSING_INFORMATION = 27;
export const DOCS_DIV_VALUE_FAMILY = 28;
export const DOCS_DIV_VALUE_EMERGENCY_CONTACT = 29;
export const DOCS_DIV_VALUE_COMMUTING_COST_APPLICATION = 30;
export const DOCS_DIV_VALUE_UNIFORM_LOAN_APPLICATION_CONFIRM = 31;
export const DOCS_DIV_VALUE_EQUIPMENT_LOAN_CONFIRM = 32;
export const DOCS_DIV_VALUE_ACCIDENT_REPORT = 33;
export const DOCS_DIV_VALUE_REPAYMENT_PLAN = 34;
export const DOCS_DIV_VALUE_AVATAR = 35;
export const DOCS_DIV_VALUE_OTHER = 36;
export const DOCS_DIV_VALUE_DAILY_REPORT = 37;

export const FMLY_ADDR_TOGETHER_DIV_SAME = 0;
export const FMLY_ADDR_TOGETHER_DIV_DIFF = 1;

export const EMPLOYEE_DOCS_IMAGE_ONLY = [
  { divId: DOCS_DIV_VALUE_WORKING_RESUME, route: 'workingResume' },
  { divId: DOCS_DIV_VALUE_WORKING_CURRICULUM_VITAE, route: 'workingcurriculumVitae' },
  { divId: DOCS_DIV_VALUE_WITHHOLDING_SLIP, route: 'withholdingSlip' },
  { divId: DOCS_DIV_VALUE_IDENTITY_GUARANTEE, route: 'identityGuarantee' },
  { divId: DOCS_DIV_VALUE_COMMUTING_DIAGRAM, route: 'commutingDiagram' },
  { divId: DOCS_DIV_VALUE_POWER_OF_ATTORNEY, route: 'powerOfAttorney' },
  { divId: DOCS_DIV_VALUE_CITY_PREFECTURAL_PAYMENT_SLIP, route: 'cityPrefecturalPaymentSlip' },
  { divId: DOCS_DIV_VALUE_CITIZEN_CARD, route: 'citizenCard' },
  { divId: DOCS_DIV_VALUE_WRITTEN_OATH, route: 'writtenOath' },
  { divId: DOCS_DIV_VALUE_DEDUCTION_FOR_DEPENDS, route: 'deductionForDepends' },
  { divId: DOCS_DIV_VALUE_OTHER, route: 'other' },
  { divId: DOCS_DIV_VALUE_DAILY_REPORT, route: 'dailyReport' },
];

export const LIST_ID_DROPDOWN_PUBLIC_VEHICLES = [1, 2];

export const DOCS_DIV_ROUTER_MAPPER = [
  { divId: DOCS_DIV_VALUE_ACCIDENT_REPORT, route: 'accidentReport' },
  { divId: DOCS_DIV_VALUE_BEGINNER_APPROPRIATE_DIAGNOSIS, route: 'aptitudeTest' },
  { divId: DOCS_DIV_VALUE_COMMUTING_COST_APPLICATION, route: 'commuteCostApplication' },
  { divId: DOCS_DIV_VALUE_DISABILITY_CERT, route: 'disabilityCert' },
  { divId: DOCS_DIV_VALUE_DRIVER_LICENSE, route: 'driverLicense' },
  { divId: DOCS_DIV_VALUE_EMPLOYMENT_INSURANCE_CERT, route: 'employmentInsuranceCert' },
  { divId: DOCS_DIV_VALUE_EQUIPMENT_LOAN_CONFIRM, route: 'equipmentLoanConfirm' },
  { divId: DOCS_DIV_VALUE_FAMILY, route: 'family' },
  { divId: DOCS_DIV_VALUE_HEALTH_CERT, route: 'healthCert' },
  { divId: DOCS_DIV_VALUE_HEALTH_INSURANCE_CARD, route: 'healthInsuranceCard' },
  { divId: DOCS_DIV_VALUE_HOUSING_INFORMATION, route: 'housing' },
  { divId: DOCS_DIV_VALUE_OPERATION_RECORD_CERT, route: 'operationRecordCert' },
  { divId: DOCS_DIV_VALUE_PENSION_BOOK, route: 'pensionBook' },
  { divId: DOCS_DIV_VALUE_REPAYMENT_PLAN, route: 'repaymentPlan' },
  { divId: DOCS_DIV_VALUE_RESIDENCE_CARD, route: 'residenceCard' },
  { divId: DOCS_DIV_VALUE_VEHICLE_QUALIFICATION_INFO, route: 'skill' },
  { divId: DOCS_DIV_VALUE_TRANSFER_BANK_ACCOUNT, route: 'transferBankAccount' },
  { divId: DOCS_DIV_VALUE_UNIFORM_LOAN_APPLICATION_CONFIRM, route: 'uniformLoanConfirm' },
  { divId: DOCS_DIV_VALUE_VEHICLE_INSPECTION_OF_COMMUTE, route: 'vehicleInspection' },
  { divId: DOCS_DIV_VALUE_COMMUTER_VEHICLE_INSURANCE, route: 'vehicleInsurance' },
  { divId: DOCS_DIV_VALUE_WORKING_CONDITIONS_NOTICE, route: 'workCondition' },
];
