import { Switch, Route, Redirect } from 'react-router-dom';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import routes from '../router';

function Container() {
  return (
    <div className="nav-container">
      <NavBar />
      <Switch>
        {routes.map((route, idx) => (
          <Route
            key={idx}
            path={route.path}
            exact={route.exact}
            name={route.name}
            // eslint-disable-next-line react/jsx-props-no-spreading
            render={(props) => <route.component {...props} />}
          />
        ))}
        <Redirect to="/" />
      </Switch>
      <Footer />
    </div>
  );
}

export default Container;
