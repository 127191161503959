export const RELATED_DOCS = {
  workingResume: {
    id: 1,
    name: '履歴書',
    imageOnly: true,
  },
  workingcurriculumVitae: {
    id: 2,
    name: '職務経歴書',
    imageOnly: true,
  },
  pensionBook: {
    id: 3,
    name: '年金手帳',
    imageOnly: false,
  },
  disabilityCert: {
    id: 4,
    name: '障害者手帳',
    imageOnly: false,
  },
  withholdingSlip: {
    id: 5,
    name: '源泉徴収票',
    imageOnly: true,
  },
  workCondition: {
    id: 6,
    name: '労働条件通知書',
    imageOnly: false,
  },
  // a: {
  //   id: 7,
  //   name: '雇用契約書',
  // },
  identityGuarantee: {
    id: 8,
    name: '身元保証書',
    imageOnly: true,
  },
  commutingDiagram: {
    id: 9,
    name: '通勤略図',
    imageOnly: true,
  },
  powerOfAttorney: {
    id: 10,
    name: '委任状',
    imageOnly: true,
  },
  cityPrefecturalPaymentSlip: {
    id: 11,
    name: '市県民納付書',
    imageOnly: true,
  },
  citizenCard: {
    id: 12,
    name: '住民票',
    imageOnly: true,
  },
  healthCert: {
    id: 13,
    name: '健康診断書',
    imageOnly: false,
  },
  operationRecordCert: {
    id: 14,
    name: '運転記録証明書',
    imageOnly: false,
  },
  aptitudeTest: {
    id: 15,
    name: '初任者診断_適正診断',
    imageOnly: false,
  },
  driverLicense: {
    id: 16,
    name: '自動車運転免許証',
    imageOnly: false,
  },
  vehicleInspection: {
    id: 17,
    name: '通勤車両の車検証',
    imageOnly: false,
  },
  vehicleInsurance: {
    id: 18,
    name: '通勤車両の保険',
    imageOnly: false,
  },
  residenceCard: {
    id: 19,
    name: '在留カード',
    imageOnly: false,
  },
  // a: {
  //   id: 20,
  //   name: 'マイナンバー',
  // },
  employmentInsuranceCert: {
    id: 21,
    name: '雇用保険証',
    imageOnly: false,
  },
  skill: {
    id: 22,
    name: '車両等資格情報',
    imageOnly: false,
  },
  healthInsuranceCard: {
    id: 23,
    name: '健康保険証',
    imageOnly: false,
  },
  writtenOath: {
    id: 24,
    name: '誓約書',
    imageOnly: true,
  },
  transferBankAccount: {
    id: 25,
    name: '振込銀行口座',
    imageOnly: false,
  },
  deductionForDepends: {
    id: 26,
    name: '扶養控除等異動申告書',
    imageOnly: true,
  },
  housing: {
    id: 27,
    name: '住居情報',
    imageOnly: false,
  },
  family: {
    id: 28,
    name: '家族関係諸異動届',
    imageOnly: false,
  },
  // a: {
  //   id: 29,
  //   name: '緊急連絡先',
  // },
  commuteCostApplication: {
    id: 30,
    name: '通勤手当支給申請書',
    imageOnly: false,
  },
  // uniformLoanConfirm: {
  //   id: 31,
  //   name: '作業着_制服貸与申請確認書',
  // },
  // equipmentLoanConfirm: {
  //   id: 32,
  //   name: '備品貸与確認書',
  // },
  accidentReport: {
    id: 33,
    name: '事故報告書',
    imageOnly: false,
  },
  repaymentPlan: {
    id: 34,
    name: '返済計画書',
    imageOnly: false,
  },
  other: {
    id: 36,
    name: 'その他',
    imageOnly: true,
  },
  dailyReport: {
    id: 37,
    name: '日報',
    imageOnly: true,
  },
};
