import { InputGroup, Button, FormControl } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';

export default function SearchBar({ keyword, setKeyword, otherSearchBar }) {
  return otherSearchBar ? (
    <div className="other-search-bar">
      <Search size={20} className="mr-r-md" />
      <FormControl
        placeholder="検索"
        className="input-keyword"
        size="sm"
        onKeyPress={(e) => {
          if (e.key === 'Enter') setKeyword(e.target.value);
        }}
        onChange={(e) => setKeyword(e.target.value)}
        value={keyword}
      />
      <Button
        className="mr-l-md search-btn"
        size="sm"
        variant="light"
        onClick={() => setKeyword(keyword)}
      >
        検索
      </Button>
    </div>
  ) : (
    <InputGroup size="sm" className="w-30-i mr-t-md">
      <FormControl
        placeholder="書類を検索"
        onKeyPress={(e) => {
          if (e.key === 'Enter') setKeyword(e.target.value);
        }}
        onChange={(e) => setKeyword(e.target.value)}
        value={keyword}
      />
      <InputGroup.Text>
        <Search size={12} />
      </InputGroup.Text>
    </InputGroup>
  );
}
