import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import axiosInstance from '../../../api/base';
import { AUTH_CREATEUPDATE } from '../../../api/constants';
import InputDateForm from '../../../components/common/form/InputDateForm';
import { IsAuthorize } from '../../../redux/slices/auth';
import { setSuccessToast } from '../../../redux/slices/toast';
import { generateDateString } from '../../../utils/convert';

export default function EmployeeDisabilityCertEdit({ history, match }) {
  const [employeeDisabilityCert, setEmployeeDisabilityCert] = useState({
    disable_hb_issuance_ymd: '',
  });

  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    disable_hb_issuance_ymd_year: Yup.number().nullable(),
    disable_hb_issuance_ymd_month: Yup.number().nullable(),
    disable_hb_issuance_ymd_day: Yup.number().nullable(),
  });

  const handleSubmitSend = (params) => {
    axiosInstance
      .post(`/employees/${match.params.id}/employee_disability_cert`, {
        disable_hb_issuance_ymd: generateDateString(
          params.disable_hb_issuance_ymd_year,
          params.disable_hb_issuance_ymd_month,
          params.disable_hb_issuance_ymd_day
        ),
      })
      .then(({ status }) => {
        if (status === 200) {
          dispatch(setSuccessToast(true));
          history.push(`/employee/${match.params.id}/disabilityCert`);
        }
      });
  };

  useEffect(() => {
    axiosInstance
      .get(`/employees/${match.params.id}/employee_disability_cert`)
      .then(({ data, status }) => {
        if (status === 200) {
          const issuanceDate = data.disable_hb_issuance_ymd?.split('-') || [];
          setEmployeeDisabilityCert({
            disable_hb_issuance_ymd_year: parseInt(issuanceDate[0], 10) || '',
            disable_hb_issuance_ymd_month: parseInt(issuanceDate[1], 10) || '',
            disable_hb_issuance_ymd_day: parseInt(issuanceDate[2], 10) || '',
          });
        }
      });
  }, []);

  return (
    <div className="content w-form">
      <h5 className="bold">帳票情報</h5>
      <Formik
        initialValues={employeeDisabilityCert}
        validationSchema={validationSchema}
        onSubmit={handleSubmitSend}
        enableReinitialize
      >
        {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <div className="mt-6 bg-gray2 pa-16 px-10 pos-rel">
              <div className="w-90">
                <InputDateForm
                  title="交付年月日"
                  handleChange={handleChange}
                  valuePrefix="disable_hb_issuance_ymd"
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                />
              </div>
            </div>
            <div className="dis-flex flex-center submit-wrapper">
              <button className="save-btn" type="submit" disabled={!IsAuthorize(AUTH_CREATEUPDATE)}>
                登録
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
