import { Form, Row, Col } from 'react-bootstrap';
import { objectByString } from '../../../utils/convert';

export default function InputText(props) {
  const {
    children,
    values,
    handleChange,
    handleBlur,
    errors,
    title,
    fieldKey,
    nestedFieldKey,
    notRequired,
    suffix,
    inputStyle,
    colSm,
    titleSm,
    disabled,
    helpText,
    placeholder,
  } = props;

  const error = fieldKey ? errors[fieldKey] : objectByString(errors, nestedFieldKey);
  const value = fieldKey ? values[fieldKey] : objectByString(values, nestedFieldKey);
  return (
    <Form.Group as={Row} className={`${error && helpText ? 'mr-b-xl' : 'mb-8'} mt-8`}>
      <Form.Label column sm={`${titleSm || '3'}`} className={`pl-10 ${!notRequired && 'required'}`}>
        {title}
      </Form.Label>
      <Col sm={`${colSm || '9'}`}>
        <Form.Control
          disabled={disabled}
          placeholder={placeholder}
          className={`${inputStyle || ''}`}
          name={fieldKey || nestedFieldKey}
          value={value}
          required={!notRequired}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={error}
        />
        {!error && <div className="text-unit pos-abs">{suffix}</div>}
        <Form.Control.Feedback type="invalid" as="small" className="pos-abs">
          {error}
        </Form.Control.Feedback>
        {helpText && !error && <Form.Text className="text-muted">{helpText}</Form.Text>}
      </Col>
      {children}
    </Form.Group>
  );
}
