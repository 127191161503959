/* eslint-disable camelcase */
import { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import InputDateForm from '../../../components/common/form/InputDateForm';
import axiosInstance from '../../../api/base';
import InputDropdown from '../../../components/common/form/InputDropdown';
import { AUTH_CREATEUPDATE, AUTH_DELETE, DIV_ID_SKILL } from '../../../api/constants';
import InputText from '../../../components/common/form/InputText';
import { generateDateString } from '../../../utils/convert';
import { setSuccessToast } from '../../../redux/slices/toast';
import InputTextArea from '../../../components/common/form/InputTextArea';
import HeaderForm from '../../../components/HeaderForm';
import { IsAuthorize } from '../../../redux/slices/auth';

const Schema = yup.object().shape({
  skill_div: yup.number().required(' '),
});

export default function SkillEdit(props) {
  const [skillInfor, setSkillInfor] = useState({});
  const [relas, setRelations] = useState([]);
  const [deleteShowing, setDeleteShowing] = useState(false);
  const dispatch = useDispatch();

  const deleteThisCheckup = () => {
    axiosInstance
      .delete(`/employees/${props.match.params.id}/employee_skills/${props.match.params.skill_id}`)
      .then((response) => {
        if (response.data) {
          dispatch(setSuccessToast(true));
          props.history.push(`/employee/${props.match.params.id}/skill`);
        }
      });
  };

  useEffect(() => {
    if (props.match.params.skill_id) {
      axiosInstance
        .get(`/employees/${props.match.params.id}/employee_skills/${props.match.params.skill_id}`)
        .then((response) => {
          const {
            skill_div,
            license_number,
            issuance_ymd,
            expiration_date,
            assign_ymd,
            dismissal_ymd,
            freememo,
          } = response.data;
          const issuanceDate = issuance_ymd?.split('-') || [];
          const expirationDate = expiration_date?.split('-') || [];
          const assignDate = assign_ymd?.split('-') || [];
          const dismissalDate = dismissal_ymd?.split('-') || [];

          setSkillInfor({
            skill_div,
            license_number,
            issuance_ymd_year: parseInt(issuanceDate[0], 10) || '',
            issuance_ymd_month: parseInt(issuanceDate[1], 10) || '',
            issuance_ymd_day: parseInt(issuanceDate[2], 10) || '',
            expiration_date_year: parseInt(expirationDate[0], 10) || '',
            expiration_date_month: parseInt(expirationDate[1], 10) || '',
            expiration_date_day: parseInt(expirationDate[2], 10) || '',
            assign_ymd_year: parseInt(assignDate[0], 10) || '',
            assign_ymd_month: parseInt(assignDate[1], 10) || '',
            assign_ymd_day: parseInt(assignDate[2], 10) || '',
            dismissal_ymd_year: parseInt(dismissalDate[0], 10) || '',
            dismissal_ymd_month: parseInt(dismissalDate[1], 10) || '',
            dismissal_ymd_day: parseInt(dismissalDate[2], 10) || '',
            freememo,
          });
        });
    }
  }, []);

  useEffect(() => {
    axiosInstance.get(`divs/${DIV_ID_SKILL}`).then((response) => {
      setRelations(response.data.sort((cur, next) => cur.display_order - next.display_order));
    });
  }, []);

  const handleSubmitContact = (params) => {
    const {
      license_number,
      skill_div,
      issuance_ymd_year,
      issuance_ymd_month,
      issuance_ymd_day,
      expiration_date_year,
      expiration_date_month,
      expiration_date_day,
      assign_ymd_year,
      assign_ymd_month,
      assign_ymd_day,
      dismissal_ymd_year,
      dismissal_ymd_month,
      dismissal_ymd_day,
      freememo,
    } = params;
    const { skill_id } = props.match.params;
    axiosInstance({
      method: skill_id ? 'patch' : 'post',
      url: `/employees/${props.match.params.id}/employee_skills`,
      data: {
        skill_div: Number(skill_div),
        license_number,
        issuance_ymd: generateDateString(issuance_ymd_year, issuance_ymd_month, issuance_ymd_day),
        expiration_date: generateDateString(
          expiration_date_year,
          expiration_date_month,
          expiration_date_day
        ),
        assign_ymd: generateDateString(assign_ymd_year, assign_ymd_month, assign_ymd_day),
        dismissal_ymd: generateDateString(
          dismissal_ymd_year,
          dismissal_ymd_month,
          dismissal_ymd_day
        ),
        skill_id: skill_id || null,
        freememo,
      },
    }).then((response) => {
      if (response.data) {
        dispatch(setSuccessToast(true));
        props.history.push(`/employee/${props.match.params.id}/skill`);
      }
    });
  };
  return (
    <div className="content w-form">
      <HeaderForm
        deleteShowing={deleteShowing}
        setDeleteShowing={setDeleteShowing}
        deleteThisCheckup={deleteThisCheckup}
        // eslint-disable-next-line react/destructuring-assignment
        haveBtnDelete={props.match.params.skill_id && IsAuthorize(AUTH_DELETE)}
      />
      <Formik
        validationSchema={Schema}
        onSubmit={handleSubmitContact}
        enableReinitialize
        initialValues={skillInfor}
      >
        {({ handleSubmit, handleChange, values, errors, touched, setFieldValue }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <div className="mt-6 bg-gray2 pa-16 px-10 pos-rel">
              <div className="w-90">
                <InputDropdown
                  title="種別"
                  values={values}
                  errors={errors}
                  touched={touched}
                  fieldKey="skill_div"
                  handleChange={handleChange}
                  options={relas}
                  optionKey="div_value"
                  optionName="div_value_nm"
                />
                <InputText
                  title="番号"
                  notRequired
                  fieldKey="license_number"
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
                <InputDateForm
                  title="取得年月日"
                  titleRequired={false}
                  handleChange={handleChange}
                  valuePrefix="issuance_ymd"
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                />
                <InputDateForm
                  title="有効期限"
                  titleRequired={false}
                  handleChange={handleChange}
                  valuePrefix="expiration_date"
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                />
                <InputDateForm
                  title="選任日"
                  titleRequired={false}
                  handleChange={handleChange}
                  valuePrefix="assign_ymd"
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                />
                <InputDateForm
                  title="解任日"
                  titleRequired={false}
                  handleChange={handleChange}
                  valuePrefix="dismissal_ymd"
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                />
                <InputTextArea values={values} handleChange={handleChange} />
              </div>
            </div>
            <div className="dis-flex flex-center submit-wrapper">
              <button className="save-btn" type="submit" disabled={!IsAuthorize(AUTH_CREATEUPDATE)}>
                登録
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
