import { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import axiosInstance from '../../../api/base';
import InputDateForm from '../../../components/common/form/InputDateForm';
import InputText from '../../../components/common/form/InputText';
import HeaderForm from '../../../components/HeaderForm';
import { generateDateString } from '../../../utils/convert';
import { REGEX_BOTH_FULL_HALF_NUMBER } from '../../../utils/const';
import { handleBlur, handleChangePhone } from '../../../utils/handleInput';
import { setSuccessToast } from '../../../redux/slices/toast';
import { AUTH_CREATEUPDATE, AUTH_DELETE } from '../../../api/constants';
import { IsAuthorize } from '../../../redux/slices/auth';

yup.addMethod(yup.string, 'fullAndHalf', function validate(errorMessage) {
  return this.test(`fullAndHalf`, errorMessage, function validate2(value) {
    const { path, createError } = this;
    return (
      !value ||
      REGEX_BOTH_FULL_HALF_NUMBER.test(value) ||
      createError({ path, message: errorMessage })
    );
  });
});

const Schema = yup.object().shape({
  vehicle_regist_no: yup.string().required(' ').fullAndHalf(' '),
  car_model: yup.string().required(' '),
  inspection_expiration_date_year: yup.number().required(' '),
  inspection_expiration_date_month: yup.number().required(' '),
  inspection_expiration_date_day: yup.number().required(' '),
});

export default function VehicleInspectionEdit(props) {
  const [vehicleInspection, setVehicleInspection] = useState({
    inspection_expiration_date: '',
  });
  const [deleteShowing, setDeleteShowing] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.match.params.inspectionId && !props.location.state) {
      axiosInstance
        .get(
          `/employees/${props.match.params.id}/employee_commute_vehicles/${props.match.params.inspectionId}`
        )
        .then((response) => {
          const consultPlanDate = response.data.inspection_expiration_date?.split('-') || [];

          setVehicleInspection({
            ...response.data,
            inspection_expiration_date_year: parseInt(consultPlanDate[0], 10) || '',
            inspection_expiration_date_month: parseInt(consultPlanDate[1], 10) || '',
            inspection_expiration_date_day: parseInt(consultPlanDate[2], 10) || '',
          });
        });
    } else if (props.match.params.inspectionId && props.location.state) {
      const consultPlanDate = props.location.state.inspection_expiration_date?.split('-') || [];
      setVehicleInspection({
        ...props.location.state,
        inspection_expiration_date_year: parseInt(consultPlanDate[0], 10) || '',
        inspection_expiration_date_month: parseInt(consultPlanDate[1], 10) || '',
        inspection_expiration_date_day: parseInt(consultPlanDate[2], 10) || '',
      });
    } else {
      const startDate = props.location.state.inspection_expiration_date?.split('-') || [];
      setVehicleInspection({
        inspection_expiration_date_year: parseInt(startDate[0], 10) || '',
        inspection_expiration_date_month: parseInt(startDate[1], 10) || '',
        inspection_expiration_date_day: parseInt(startDate[2], 10) || '',
      });
    }
  }, []);

  const handleSubmitSend = (params) => {
    axiosInstance({
      method: props.match.params.inspectionId ? 'patch' : 'post',
      url: `/employees/${props.match.params.id}/employee_commute_vehicles`,
      data: {
        ...params,
        inspection_expiration_date: generateDateString(
          params.inspection_expiration_date_year,
          params.inspection_expiration_date_month,
          params.inspection_expiration_date_day
        ),
      },
    }).then((response) => {
      if (response.data) {
        dispatch(setSuccessToast(true));
        props.history.push(`/employee/${props.match.params.id}/vehicleInspection`);
      }
    });
  };

  const deleteThisUniform = () => {
    axiosInstance
      .delete(
        `/employees/${props.match.params.id}/employee_commute_vehicles/${props.match.params.inspectionId}`
      )
      .then((response) => {
        if (response.data) {
          dispatch(setSuccessToast(true));
          props.history.push(`/employee/${props.match.params.id}/vehicleInspection`);
        }
      });
  };

  return (
    <div className="content w-form">
      <HeaderForm
        deleteShowing={deleteShowing}
        setDeleteShowing={setDeleteShowing}
        deleteThisCheckup={deleteThisUniform}
        // eslint-disable-next-line react/destructuring-assignment
        haveBtnDelete={props.match.params.inspectionId && IsAuthorize(AUTH_DELETE)}
      />
      <Formik
        validationSchema={Schema}
        onSubmit={handleSubmitSend}
        enableReinitialize
        initialValues={vehicleInspection}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <div className="mt-6 bg-gray2 pa-16 pos-rel">
              <div className="w-90">
                <InputText
                  title="自動車登録番号"
                  values={values}
                  errors={errors}
                  touched={touched}
                  fieldKey="vehicle_regist_no"
                  handleChange={(e) => handleChangePhone(e, setFieldValue)}
                  handleBlur={(e) => handleBlur(e, setFieldValue, setFieldTouched)}
                />
                <InputText
                  title="車名"
                  values={values}
                  errors={errors}
                  touched={touched}
                  fieldKey="car_model"
                  handleChange={handleChange}
                />
                <InputDateForm
                  title="有効期限"
                  titleRequired
                  handleChange={handleChange}
                  valuePrefix="inspection_expiration_date"
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                />
              </div>
            </div>
            <div className="dis-flex flex-center submit-wrapper">
              <button className="save-btn" type="submit" disabled={!IsAuthorize(AUTH_CREATEUPDATE)}>
                登録
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
