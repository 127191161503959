import React, { useEffect, useState } from 'react';
import { Navbar, Nav, Breadcrumb, Button } from 'react-bootstrap';
import { ArrowLeftCircle } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { useHistory } from 'react-router-dom';

import axiosInstance from '../api/base';
import { logoutAction, selectAuth } from '../redux/slices/auth';
import router from '../router';
import logo from '../assets/images/logo.svg';
import { RELATED_DOCS } from '../views/employee/relatedDocsImages/Enum';

function NavBar() {
  const { authUser } = useSelector(selectAuth);
  const history = useHistory();
  const breadcrumbs = useBreadcrumbs(router);
  const breadcrumbsWithoutHome = breadcrumbs.slice(1);

  const [company, setCompany] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    const init = async () => {
      const res = await axiosInstance.get(`/companies/${authUser.company_cd}`);
      setCompany(res.data);
    };
    init();
  }, []);

  const handleLogoutClick = (e) => {
    e.preventDefault();
    dispatch(logoutAction());
    window.location.assign(`${window.location.protocol}//${window.location.host}`);
  };
  return (
    <div className="header-sticky">
      <Navbar bg="transparent" expand="lg">
        <Navbar.Collapse id="basic-navbar-nav">
          <Navbar.Brand className="logo" onClick={() => history.push('/')}>
            <img src={logo} className="logo" alt="Pastel" />
          </Navbar.Brand>
          <Nav className="mr-auto"> </Nav>
          <div className="user">
            <img src={authUser.avatar} className="avatar" alt="Avatar" />
            <div className="user-info">
              <span className="name">
                {authUser.user_last_name || '氏'} {authUser.user_first_name || '名'}
              </span>
              <span className="department">{company && company.company_nm}</span>
            </div>
          </div>
        </Navbar.Collapse>
      </Navbar>
      {breadcrumbsWithoutHome.length ? (
        <Breadcrumb>
          <Button variant="link" className="back-button" onClick={() => window.history.back()}>
            <ArrowLeftCircle color="#4ea391" size={18} />
            <span style={{ marginLeft: '0.6rem' }}>戻る</span>
          </Button>
          {breadcrumbsWithoutHome.map(({ key, match, breadcrumb }, i) => {
            if (/^\d+$/.test(breadcrumb?.props?.children?.replaceAll('-', ''))) return null;
            if (breadcrumb?.props?.children === 'EditUploadedRelatedDocs') return null;
            const tmp = breadcrumb?.key?.split('/');
            return (
              <Breadcrumb.Item
                key={key}
                onClick={() => history.push(match.url)}
                active={i === breadcrumbsWithoutHome.length - 1}
              >
                {tmp && tmp.length > 0 && RELATED_DOCS[`${tmp[tmp.length - 1]}`]?.imageOnly
                  ? RELATED_DOCS[`${tmp[tmp.length - 1]}`].name
                  : breadcrumb}
              </Breadcrumb.Item>
            );
          })}
          <Button variant="link" className="logout-button" onClick={handleLogoutClick}>
            <span>ログアウト</span>
          </Button>
        </Breadcrumb>
      ) : (
        <Breadcrumb>
          <Button
            variant="link"
            style={{ visibility: 'hidden' }}
            onClick={() => window.history.back()}
          >
            <ArrowLeftCircle color="#4ea391" size={18} />
            <span style={{ marginLeft: '0.6rem' }}>戻る</span>
          </Button>
          <Button variant="link" className="logout-button" onClick={handleLogoutClick}>
            <span>ログアウト</span>
          </Button>
        </Breadcrumb>
      )}

      {/* <h2 className="title">{}</h2> */}
      <div className="line" />
    </div>
  );
}

// NavBar.propTypes = {
//   title: PropTypes.string,
//   breadcrumbs: PropTypes.array,
// };

export default NavBar;
