import { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import FileHandler from '../../../components/FileHandler';
import HistoryList from '../../../components/HistoryList';
import InfoPageHeader from '../../../components/InfoPageHeader';
import axiosInstance from '../../../api/base';
import { DOCS_DIV_VALUE_OPERATION_RECORD_CERT } from '../../../api/constants';
import { computeDate } from '../../../utils/convert';

export default function OperationRecordInfo(props) {
  const [records, setRecords] = useState([]);
  const [images, setImages] = useState([]);

  useEffect(() => {
    axiosInstance
      .get(
        `/employees/${props.match.params.id}/employee_related_docs/test/div/${DOCS_DIV_VALUE_OPERATION_RECORD_CERT}`
      )
      .then((response) => {
        setImages(response.data);
      });

    axiosInstance
      .get(`employees/${props.match.params.id}/employee_drive_record_certs`)
      .then((response) => {
        setRecords(response.data);
      });
  }, []);

  const gotoPage = (subroute, state = {}) => {
    props.history.push({
      pathname: `/employee/${props.match.params.id}/operationRecordCert/${subroute}`,
      state,
    });
  };

  return (
    <div className="content">
      <Row>
        <Col>
          <InfoPageHeader onCreate={() => gotoPage('new')} />
          {records.length > 0 && (
            <FileHandler
              centerEdit
              className="divider-btm"
              onEditClicked={() => gotoPage(`${records[0].drive_record_cert_id}/edit`, records[0])}
            >
              <span>登録日：</span>
              <span className="text-prim">{computeDate(records[0].regist_datetime)}</span>
            </FileHandler>
          )}
        </Col>
        <Col>
          <InfoPageHeader onCreate={() => gotoPage('uploadRelatedDocs')} imageHeader />
          {images[0] && (
            <FileHandler
              centerEdit
              imageHandler
              className="divider-btm"
              onEditClicked={() => gotoPage(`editUploadedRelatedDocs/${images[0].related_docs_id}`)}
            >
              <span>登録日：</span>
              <span className="text-prim">{computeDate(images[0].related_docs_ymd)}</span>
            </FileHandler>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <HistoryList list={records}>
            {records.slice(1)?.map((record) => (
              <FileHandler
                grayIcon
                centerEdit
                className="divider-btm"
                onEditClicked={() => gotoPage(`${record.drive_record_cert_id}/edit`, record)}
              >
                <span>登録日：</span>
                <span className="text-prim">{computeDate(record.regist_datetime)}</span>
              </FileHandler>
            ))}
          </HistoryList>
        </Col>
        <Col>
          <HistoryList list={images}>
            {images.slice(1).map((image, key) => (
              <FileHandler
                centerEdit
                imageHandler
                grayIcon
                className="divider-btm"
                key={key}
                onEditClicked={() => gotoPage(`editUploadedRelatedDocs/${image.related_docs_id}`)}
              >
                <span>更新日：</span>
                <span className="text-prim">{computeDate(image.related_docs_ymd)}</span>
              </FileHandler>
            ))}
          </HistoryList>
        </Col>
      </Row>
    </div>
  );
}
