import { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import InputDateForm from '../../../components/common/form/InputDateForm';
import axiosInstance from '../../../api/base';
import {
  AUTH_CREATEUPDATE,
  AUTH_DELETE,
  DIV_ID_ACCIDENT,
  DIV_ID_COMPENSATION,
} from '../../../api/constants';
import { generateDateString } from '../../../utils/convert';
import { setSuccessToast } from '../../../redux/slices/toast';
import InputTextArea from '../../../components/common/form/InputTextArea';
import InputDropdown from '../../../components/common/form/InputDropdown';
import HeaderForm from '../../../components/HeaderForm';
import { IsAuthorize } from '../../../redux/slices/auth';

const Schema = yup.object().shape({
  occur_ymd_year: yup.number().required(' '),
  occur_ymd_month: yup.number().required(' '),
  occur_ymd_day: yup.number().required(' '),
  accident_div: yup.number().required(' '),
  compensation_div: yup.number().required(' '),
  freememo: yup.string().notRequired(),
});

export default function AccidentReportEdit(props) {
  const [accidentReport, setReport] = useState({});
  const [deleteShowing, setDeleteShowing] = useState(false);
  const [accidentTypes, setAccidentTypes] = useState([]);
  const [compensationTypes, setCompensationTypes] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    axiosInstance.get(`/divs/${DIV_ID_ACCIDENT}`).then((response) => {
      setAccidentTypes(response.data.sort((cur, next) => cur.display_order - next.display_order));
    });
    axiosInstance.get(`/divs/${DIV_ID_COMPENSATION}`).then((response) => {
      setCompensationTypes(
        response.data.sort((cur, next) => cur.display_order - next.display_order)
      );
    });
    if (props.match.params.accReportId) {
      axiosInstance
        .get(
          `/employees/${props.match.params.id}/accident_reports/${props.match.params.accReportId}`
        )
        .then((response) => {
          const occurDate = response.data.occur_ymd?.split('-') || [];

          setReport({
            ...response.data,
            occur_ymd_year: parseInt(occurDate[0], 10) || '',
            occur_ymd_month: parseInt(occurDate[1], 10) || '',
            occur_ymd_day: parseInt(occurDate[2], 10) || '',
          });
        });
    } else {
      setReport({
        occur_ymd_year: '',
        occur_ymd_month: '',
        occur_ymd_day: '',
        accident_div: '',
        compensation_div: '',
      });
    }
  }, []);

  const handleSubmitSend = (params) => {
    axiosInstance({
      method: props.match.params.accReportId ? 'patch' : 'post',
      url: `/employees/${props.match.params.id}/accident_reports`,
      data: {
        ...params,
        occur_ymd: generateDateString(
          params.occur_ymd_year,
          params.occur_ymd_month,
          params.occur_ymd_day
        ),
      },
    }).then((response) => {
      if (response.data) {
        dispatch(setSuccessToast(true));
        props.history.push(`/employee/${props.match.params.id}/accidentReport`);
      }
    });
  };
  const deleteThisCheckup = () => {
    axiosInstance
      .delete(
        `/employees/${props.match.params.id}/accident_reports/${props.match.params.accReportId}`
      )
      .then((response) => {
        if (response.data) props.history.push(`/employee/${props.match.params.id}/accidentReport`);
      });
  };
  return (
    <div className="content w-form">
      <HeaderForm
        deleteShowing={deleteShowing}
        setDeleteShowing={setDeleteShowing}
        deleteThisCheckup={deleteThisCheckup}
        // eslint-disable-next-line react/destructuring-assignment
        haveBtnDelete={props.match.params.accReportId && IsAuthorize(AUTH_DELETE)}
      />
      <Formik
        validationSchema={Schema}
        onSubmit={handleSubmitSend}
        enableReinitialize
        initialValues={accidentReport}
      >
        {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <div className="mt-6 bg-gray2 pa-16 pos-rel">
              <div className="w-90">
                <InputDateForm
                  title="事故発生日"
                  titleRequired
                  handleChange={handleChange}
                  valuePrefix="occur_ymd"
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                />
                <InputDropdown
                  title="事故種別"
                  values={values}
                  errors={errors}
                  touched={touched}
                  fieldKey="accident_div"
                  handleChange={handleChange}
                  options={accidentTypes}
                  optionKey="div_value"
                  optionName="div_value_nm"
                />
                <InputDropdown
                  title="補償種別"
                  values={values}
                  errors={errors}
                  touched={touched}
                  fieldKey="compensation_div"
                  handleChange={handleChange}
                  options={compensationTypes}
                  optionKey="div_value"
                  optionName="div_value_nm"
                />
                <InputTextArea values={values} handleChange={handleChange} />
              </div>
            </div>
            <div className="dis-flex flex-center submit-wrapper">
              <button className="save-btn" type="submit" disabled={!IsAuthorize(AUTH_CREATEUPDATE)}>
                登録
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
