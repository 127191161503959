import { convertFullToHalf } from './convert';

export const handleBlur = (event, setFieldValue, setFieldTouched) => {
  const { value, name } = event.target;
  const valueConverted = convertFullToHalf(value);
  setFieldValue(name, valueConverted);
  setFieldTouched(name, true);
};

export const handleChangePhone = (event, setFieldValue) => {
  const { value, name } = event.target;
  const valueConverted = value.replace(/[^0-9０-９]/g, '');
  setFieldValue(name, valueConverted);
};
