import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axiosInstance from '../../../api/base';
import { addImages, clearImages, clearSequence } from '../../../redux/slices/relatedDocs';
import { RELATED_DOCS } from './Enum';
import RelatedDocsImagesList from './List';
import RelatedDocsImagesSelect from './Select';

export default function RelatedDocsImagesEdit({ history, match }) {
  const { deletedSequence } = useSelector((state) => state.relatedDocs);
  const dispatch = useDispatch();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [lastUploadedDate, setLastUploadedDate] = useState('');
  const [relatedDocsId, setRelatedDocsId] = useState('');

  const addFile = (selectedFile) => {
    setSelectedFiles([...selectedFiles, selectedFile]);
  };

  const deleteFile = (deleteIndex) => {
    setSelectedFiles([...selectedFiles.splice(deleteIndex, 1)]);
  };

  const onUpload = async () => {
    const params = new URLSearchParams({
      related_docs_div: RELATED_DOCS[match.params.relatedDocsName].id,
      related_docs_ymd: moment().format('YYYY-MM-DD'),
    }).toString();

    const formData = new FormData();

    deletedSequence.forEach((seq) => {
      formData.append('delete_seq_list', seq);
    });

    selectedFiles.forEach((file) => {
      formData.append('files', file);
    });

    const { status } = await axiosInstance.put(
      `/employees/${match.params.id}/employee_related_docs/test/${relatedDocsId}?${params}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    if (status === 200) {
      dispatch(clearImages());
      dispatch(clearSequence());
      history.push(`/employee/${match.params.id}/${match.params.relatedDocsName}`);
    }
  };

  useEffect(() => {
    const fetchRelatedDocs = async () => {
      dispatch(clearImages());
      dispatch(clearSequence());

      if (match.params.relatedDocsId) {
        const { status, data } = await axiosInstance.get(
          `/employees/${match.params.id}/employee_related_docs/${match.params.relatedDocsId}`
        );

        if (status === 200 && data.length > 0) {
          const convData = data.map((rec) => {
            rec.name = rec.save_docs_url;
            rec.employeeCd = rec.employee_cd;
            rec.relatedDocsId = rec.related_docs_id;
            rec.relatedDocsSeq = rec.related_docs_seq;
            return rec;
          });
          dispatch(addImages(convData));
          setLastUploadedDate(data[0].related_docs_ymd);
          setRelatedDocsId(data[0].related_docs_id);
        }
      } else {
        const { status, data } = await axiosInstance.get(
          `/employees/${match.params.id}/employee_related_docs/div/${
            RELATED_DOCS[match.params.relatedDocsName].id
          }/last_uploaded_images`
        );

        if (status === 200 && data.length > 0) {
          const convData = data.map((rec) => {
            rec.name = rec.save_docs_url;
            rec.employeeCd = rec.employee_cd;
            rec.relatedDocsId = rec.related_docs_id;
            rec.relatedDocsSeq = rec.related_docs_seq;
            return rec;
          });
          dispatch(addImages(convData));
          setLastUploadedDate(data[0].related_docs_ymd);
          setRelatedDocsId(data[0].related_docs_id);
        }
      }
    };

    fetchRelatedDocs();
  }, []);

  return (
    <div className="content">
      <RelatedDocsImagesList
        deleteFile={deleteFile}
        listType="edit"
        lastUploadedDate={lastUploadedDate}
      />

      <RelatedDocsImagesSelect addFile={addFile} />

      <div className="text-center">
        <button
          type="button"
          className="btn-update"
          disabled={selectedFiles.length === 0 && deletedSequence.length === 0}
          onClick={onUpload}
        >
          変更を保存
        </button>
      </div>
    </div>
  );
}
