import { Form, Row, Col } from 'react-bootstrap';
import { objectByString } from '../../../utils/convert';

export default function InputDropdown(props) {
  const {
    children,
    values,
    handleChange,
    errors,
    title,
    fieldKey,
    nestedFieldKey,
    notRequired,
    options,
    optionKey,
    optionName,
    colSm,
    titleSm,
    disabled,
    helpText,
    defaultValue,
  } = props;

  const error = fieldKey ? errors[fieldKey] : objectByString(errors, nestedFieldKey);
  const value = fieldKey ? values[fieldKey] : objectByString(values, nestedFieldKey);

  return (
    <Form.Group as={Row} className={`${error && helpText ? 'mr-b-xl' : 'mb-8'}`}>
      <Form.Label column sm={`${titleSm || '3'}`} className={`pl-10 ${!notRequired && 'required'}`}>
        {title}
      </Form.Label>
      <Col sm={`${colSm || '9'}`}>
        <Form.Control
          as="select"
          disabled={disabled}
          name={fieldKey || nestedFieldKey}
          value={value}
          onChange={handleChange}
          isInvalid={error}
          defaultValue=""
        >
          <option value="" disabled hidden>
            {defaultValue || '選択してください'}
          </option>
          {options?.map((option) => (
            <option key={option[optionKey]} value={option[optionKey]}>
              {option[optionName]}
            </option>
          ))}
        </Form.Control>
        <Form.Control.Feedback type="invalid" as="small" className="pos-abs">
          {error}
        </Form.Control.Feedback>
        {helpText && !error && <Form.Text className="text-muted">{helpText}</Form.Text>}
      </Col>
      {children}
    </Form.Group>
  );
}
