import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import './styles/main.scss';

// Import page and views
import Container from './page/Container';
import Login from './page/Login';

import { selectAuth, authAction } from './redux/slices/auth';

function App() {
  const dispatch = useDispatch();

  const auth = useSelector(selectAuth);
  const isAuthenticated = !!auth.authUser;

  useEffect(() => {
    dispatch(authAction());
  }, []);

  if (auth.loading) {
    return null;
  }

  return (
    <BrowserRouter>
      <Switch>
        {!isAuthenticated && <Route path="/login" component={Login} />}
        {!isAuthenticated && <Redirect to="/login" />}

        {isAuthenticated && <Route path="/" component={Container} />}
        {isAuthenticated && <Redirect to="/" />}
      </Switch>
    </BrowserRouter>
  );
}

export default App;
