import { useTable, useSortBy, useGlobalFilter, useBlockLayout, usePagination } from 'react-table';
import { useSticky } from 'react-table-sticky';
import { ArrowUp, ArrowDown } from 'react-bootstrap-icons';
import { useCallback } from 'react';
import SearchBar from './SearchBar';
import Pagination from './Pagination';

export default function Table({
  columns,
  data,
  onClickRow,
  children,
  otherSearchBar,
  noPagin = false,
  pageSize = null,
  sticky = false,
}) {
  const ourGlobalFilterFunction = useCallback(
    (listRows, _ids, query) =>
      listRows.filter((row) =>
        Object.keys(row.values).find((key) => {
          if (typeof row.values[key] === 'string') return row.values[key].includes(query);
          if (typeof row.values[key] === 'object') return row.values[key].join(' ').includes(query);
          return false;
        })
      ),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    pageCount,
    page,
    rows,
    nextPage,
    previousPage,
    gotoPage,
    canPreviousPage,
    canNextPage,
    setGlobalFilter,
  } = noPagin
    ? useTable(
        {
          columns,
          data,
        },
        useGlobalFilter,
        useSortBy,
        useBlockLayout,
        useSticky
      )
    : useTable(
        {
          columns,
          initialState: pageSize && { pageSize },
          data,
          globalFilter: ourGlobalFilterFunction,
        },
        useGlobalFilter,
        useSortBy,
        useBlockLayout,
        usePagination,
        useSticky
      );

  const { globalFilter, pageIndex } = state;
  return (
    columns && (
      <>
        <SearchBar
          keyword={globalFilter}
          setKeyword={setGlobalFilter}
          otherSearchBar={otherSearchBar}
        />
        {children}
        <table {...getTableProps()} className={sticky ? 'sticky-table' : 'custom-table'}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    <span>
                      {(column.isSorted && (column.isSortedDesc ? <ArrowDown /> : <ArrowUp />)) ||
                        ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {(data.length > 0 && (
            <tbody {...getTableBodyProps()}>
              {noPagin
                ? rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()} key={i} onClick={() => onClickRow(row.values)}>
                        {row.cells.map((cell) => (
                          <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        ))}
                      </tr>
                    );
                  })
                : page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()} key={i} onClick={() => onClickRow(row.values)}>
                        {row.cells.map((cell) => (
                          <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        ))}
                      </tr>
                    );
                  })}
            </tbody>
          )) || <div className="no-data-txt">テーブル内のデータなし</div>}
        </table>
        {noPagin || (
          <Pagination
            pageIndex={pageIndex}
            total={pageCount}
            previousPage={previousPage}
            canPreviousPage={canPreviousPage}
            nextPage={nextPage}
            canNextPage={canNextPage}
            gotoPage={(pageNo) => gotoPage(pageNo)}
          />
        )}
      </>
    )
  );
}
