import { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import FileHandler from '../../../components/FileHandler';
import HistoryList from '../../../components/HistoryList';
import InfoPageHeader from '../../../components/InfoPageHeader';
import axiosInstance from '../../../api/base';
import { DOCS_DIV_VALUE_VEHICLE_QUALIFICATION_INFO } from '../../../api/constants';
import { computeDate } from '../../../utils/convert';

export default function SkillInfo(props) {
  const [skills, setSkills] = useState([]);
  const [images, setImages] = useState([]);

  useEffect(() => {
    axiosInstance
      .get(
        `/employees/${props.match.params.id}/employee_related_docs/test/div/${DOCS_DIV_VALUE_VEHICLE_QUALIFICATION_INFO}`
      )
      .then((response) => {
        setImages(response.data);
      });

    axiosInstance.get(`employees/${props.match.params.id}/employee_skills`).then((response) => {
      setSkills(response.data);
    });
  }, []);

  const gotoPage = (subroute, state = {}) => {
    props.history.push({
      pathname: `/employee/${props.match.params.id}/skill/${subroute}`,
      state,
    });
  };

  return (
    <div className="content">
      <Row>
        <Col>
          <InfoPageHeader onCreate={() => gotoPage('new')} />
          {skills.length > 0 && (
            <FileHandler
              className="divider-btm"
              onEditClicked={() => gotoPage(`${skills[0].skill_id}/edit`, skills[0])}
            >
              <span>種別：</span>
              <span className="text-prim">{skills[0].skill.div_value_nm}</span>
              <br />
              <span className="text-lght">更新日：</span>
              <span>{computeDate(skills[0].update_datetime)}</span>
              <br />
              {skills[0].issuance_ymd && (
                <>
                  <span className="text-lght">取得日：</span>
                  <span>{computeDate(skills[0].issuance_ymd)}</span>
                  <br />
                </>
              )}
              {skills[0].expiration_date && (
                <>
                  <span className="text-lght">有効期限：</span>
                  <span>{computeDate(skills[0].expiration_date)}</span>
                  <br />
                </>
              )}
              {skills[0].assign_ymd && (
                <>
                  <span className="text-lght">選任日：</span>
                  <span>{computeDate(skills[0].assign_ymd)}</span>
                  <br />
                </>
              )}
              {skills[0].dismissal_ymd && (
                <>
                  <span className="text-lght">解任日：</span>
                  <span>{computeDate(skills[0].dismissal_ymd)}</span>
                  <br />
                </>
              )}
              {skills[0].freememo && (
                <>
                  <span className="text-lght">備考：</span>
                  <span>{skills[0].freememo}</span>
                </>
              )}
            </FileHandler>
          )}
        </Col>
        <Col>
          <InfoPageHeader onCreate={() => gotoPage('uploadRelatedDocs')} imageHeader />
          {images[0] && (
            <FileHandler
              imageHandler
              className="divider-btm"
              onEditClicked={() => gotoPage(`editUploadedRelatedDocs/${images[0].related_docs_id}`)}
            >
              <span>更新日：</span>
              <span className="text-prim">{computeDate(images[0].related_docs_ymd)}</span>
            </FileHandler>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <HistoryList list={skills}>
            {skills.slice(1)?.map((skill) => (
              <FileHandler
                grayIcon
                className="divider-btm"
                onEditClicked={() => gotoPage(`${skill.skill_id}/edit`, skill)}
              >
                <span>種別：</span>
                <span className="text-prim">{skill.skill.div_value_nm}</span>
                <br />
                <span className="text-lght">更新日：</span>
                <span>{computeDate(skill.update_datetime)}</span>
                <br />
                {skill.issuance_ymd && (
                  <>
                    <span className="text-lght">取得日：</span>
                    <span>{computeDate(skill.issuance_ymd)}</span>
                    <br />
                  </>
                )}
                {skill.expiration_date && (
                  <>
                    <span className="text-lght">有効期限：</span>
                    <span>{computeDate(skill.expiration_date)}</span>
                    <br />
                  </>
                )}
                {skill.assign_ymd && (
                  <>
                    <span className="text-lght">選任日：</span>
                    <span>{computeDate(skill.assign_ymd)}</span>
                    <br />
                  </>
                )}
                {skill.dismissal_ymd && (
                  <>
                    <span className="text-lght">解任日：</span>
                    <span>{computeDate(skill.dismissal_ymd)}</span>
                    <br />
                  </>
                )}
                {skill.freememo && (
                  <>
                    <span className="text-lght">備考：</span>
                    <span>{skill.freememo}</span>
                  </>
                )}
              </FileHandler>
            ))}
          </HistoryList>
        </Col>
        <Col>
          <HistoryList list={images}>
            {images.slice(1).map((image, key) => (
              <FileHandler
                centerEdit
                imageHandler
                grayIcon
                className="mt-1 divider-btm"
                key={key}
                onEditClicked={() => gotoPage(`editUploadedRelatedDocs/${image.related_docs_id}`)}
              >
                <span>更新日：</span>
                <span className="text-prim">{computeDate(image.related_docs_ymd)}</span>
              </FileHandler>
            ))}
          </HistoryList>
        </Col>
      </Row>
    </div>
  );
}
