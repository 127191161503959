import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import SuccessToast from './components/common/toast/Success';
import App from './App';
import store from './redux/store';

const renderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 6,
    backgroundColor: 'rgba(78,  163, 145, 0.8)',
    zIndex: 2,
  };
  return <div style={{ ...style, ...thumbStyle }} {...props} />;
};

const CustomScrollbars = (props) => (
  <Scrollbars renderThumbHorizontal={renderThumb} renderThumbVertical={renderThumb} {...props} />
);

const windowStyle = {
  width: '100vw',
  height: 'calc(100vh - 4rem)',
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <CustomScrollbars autoHide autoHideTimeout={500} autoHideDuration={0} style={windowStyle}>
        <SuccessToast />
        <App />
      </CustomScrollbars>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
