import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import InfoPageHeader from '../../../components/InfoPageHeader';
import axiosInstance from '../../../api/base';
import FileHandler from '../../../components/FileHandler';
import { DriverLicensesVariations } from '../Detail';
import { computeDate } from '../../../utils/convert';
import { toIso8601str } from '../../../utils/date';
import { DOCS_DIV_VALUE_DRIVER_LICENSE } from '../../../api/constants';

function DriverLicenseInfo(props) {
  const [existsFiles, setExistsFiles] = useState([]);
  const [dataInfos, setDataInfos] = useState(null);

  const addFile = async () => {
    props.history.push(`/employee/${props.match.params.id}/driverLicense/uploadRelatedDocs`);
  };

  const editFile = async (relatedDocsId) => {
    if (existsFiles.length > 0) {
      props.history.push(
        `/employee/${props.match.params.id}/driverLicense/editUploadedRelatedDocs/${relatedDocsId}`
      );
    }
  };

  useEffect(() => {
    const init = async () => {
      const resD = await axiosInstance.get(
        `/employees/${props.match.params.id}/employee_driver_licenses`
      );
      setDataInfos(resD.data);
      const resF = await axiosInstance.get(
        `/employees/${props.match.params.id}/employee_related_docs/test/div/${DOCS_DIV_VALUE_DRIVER_LICENSE}`
      );
      setExistsFiles(resF.data);
    };
    init();
  }, []);

  const gotoPage = (path, state = {}, params = {}) => {
    props.history.push({
      pathname: `/employee/${props.match.params.id}/${path}`,
      search: `?${new URLSearchParams(params).toString()}`,
      state,
    });
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col>
            <InfoPageHeader
              onCreate={() => gotoPage('driverLicense/new')}
              hideButton={dataInfos?.length}
            />
            {dataInfos?.map((license) => (
              <FileHandler
                className="divider-btm"
                key={0}
                onEditClicked={() => gotoPage(`driverLicense/${license?.issuance_ymd}/edit`)}
              >
                <span>帳票：</span>
                <span className="text-prim">運転免許証</span>
                <br />
                <span className="text-lght">更新日：</span>
                <span>{computeDate(license?.update_datetime)}</span>
                <br />
                <span className="text-lght">取得年月日：</span>
                <span>{computeDate(license?.issuance_ymd)}</span>
                <br />
                <span className="text-lght">有効期限：</span>
                <span>{computeDate(license?.expiration_date)}</span>
                <br />
                <span className="text-lght">種類：</span>
                <DriverLicensesVariations driverLicensesIndex={0} driverLicenses={dataInfos} />
                <br />
                <span className="text-lght">条件：</span>
                <span>{license?.conditions}</span>
                <br />
              </FileHandler>
            ))}
          </Col>
          <Col>
            <InfoPageHeader onCreate={() => addFile()} imageHeader />
            {existsFiles.length > 0 && (
              <FileHandler
                imageHandler
                className="divider-btm"
                onEditClicked={() => editFile(existsFiles[0].related_docs_id)}
              >
                <span>更新日：</span>
                <span className="text-prim">
                  {computeDate(toIso8601str(existsFiles[0].save_docs_last_update_ymd))}
                </span>
              </FileHandler>
            )}
          </Col>
        </Row>
        {existsFiles.length > 1 && (
          <div className="mt-6 bg-gray pa-16">
            <div className="mb-4 text-lght">以前アップロードされた画像</div>
            <div className="divider-btm" />
            {existsFiles.slice(1).map((image, key) => (
              <FileHandler
                centerEdit
                imageHandler
                grayIcon
                className="divider-btm"
                key={key}
                onEditClicked={() => editFile(image.related_docs_id)}
              >
                <span>更新日：</span>
                <span className="text-prim">{computeDate(image.related_docs_ymd)}</span>
              </FileHandler>
            ))}
          </div>
        )}
        <Row>
          <Col md={{ span: 6, offset: 6 }}>
            {!existsFiles.length && (
              <div className="no-data-txt-sm sz-16 w-100">該当データなし</div>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default DriverLicenseInfo;
