import { createSlice } from '@reduxjs/toolkit';

export const relatedDocsSlice = createSlice({
  name: 'relatedDocs',

  initialState: {
    uploadedImages: [],
    deletedSequence: [],
  },

  reducers: {
    addImage: (state, { payload }) => {
      state.uploadedImages = [...state.uploadedImages, payload];
    },

    addImages: (state, { payload }) => {
      state.uploadedImages = [...state.uploadedImages, ...payload];
    },

    deleteImage: (state, { payload }) => {
      state.uploadedImages = [...state.uploadedImages.filter((_, index) => index !== payload)];
    },

    clearImages: (state) => {
      state.uploadedImages = [];
    },

    addSequence: (state, { payload }) => {
      state.deletedSequence = [...state.deletedSequence, payload];
    },

    clearSequence: (state) => {
      state.deletedSequence = [];
    },
  },
});

export const { addImage, addImages, deleteImage, clearImages, addSequence, clearSequence } =
  relatedDocsSlice.actions;

export default relatedDocsSlice.reducer;
