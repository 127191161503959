import { useEffect, useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { ArrowClockwise } from 'react-bootstrap-icons';
import {
  monthOptionList,
  getDayOptionListInMonth,
  listOptionExpirationYear,
  listOptionBirthdayYear,
  yearOptionList,
} from '../../utils/date';

function InputYmd(props) {
  const {
    initialDate,
    returnDate,
    isDisable,
    isExpirationDate = false,
    isBirthday = false,
    isClickSubmit,
    require = false,
  } = props;
  const [dateYear, setDateYear] = useState('');
  const [dateMonth, setDateMonth] = useState('');
  const [dateDate, setDateDate] = useState('');

  let listOptionYear = [];
  if (isExpirationDate) {
    listOptionYear = listOptionExpirationYear;
  } else listOptionYear = isBirthday ? listOptionBirthdayYear : yearOptionList;

  const setTargetDateYear = (value) => {
    setDateYear(value);
    try {
      returnDate(new Date(value, dateMonth - 1, 1));
      getDayOptionListInMonth(value, dateMonth);
    } catch {
      returnDate(null);
    }
  };
  const setTargetDateMonth = (value) => {
    setDateMonth(value);
    try {
      returnDate(new Date(dateYear, value - 1, 1));
      getDayOptionListInMonth(dateYear, value);
    } catch {
      returnDate(null);
    }
  };
  const setTargetDateDate = (value) => {
    setDateDate(value);
    try {
      returnDate(new Date(dateYear, dateMonth - 1, value));
    } catch {
      returnDate(null);
    }
  };

  const resetValue = () => {
    setTimeout(() => {
      setDateYear('');
      setDateMonth('');
      setDateDate('');
    }, 0);
    returnDate(null);
  };

  useEffect(() => {
    const initDate = () => {
      if (initialDate) {
        try {
          const date = new Date(initialDate);
          setDateYear(date.getFullYear());
          setDateMonth(date.getMonth() + 1);
          setDateDate(date.getDate());
        } catch {
          setDateYear('');
          setDateMonth('');
          setDateDate('');
        }
      }
    };
    initDate();
  }, [initialDate]);

  return (
    <Row className="position-relative">
      <ArrowClockwise
        onClick={() => resetValue()}
        className="position-absolute top-0"
        style={{ top: '12px', right: '-25px', cursor: 'pointer' }}
      />
      <Col sm={6}>
        <Form.Control
          as="select"
          size="md"
          className="form-year"
          onChange={(e) => setTargetDateYear(e.currentTarget.value)}
          value={dateYear}
          disabled={isDisable}
          isInvalid={isClickSubmit && require && !dateYear}
        >
          <option value="" disabled hidden>
            選択してください
          </option>
          {listOptionYear.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </Form.Control>
      </Col>
      <Col sm={3}>
        <Form.Control
          as="select"
          size="md"
          className="form-datemonth"
          onChange={(e) => setTargetDateMonth(e.currentTarget.value)}
          value={dateMonth}
          disabled={isDisable}
          isInvalid={isClickSubmit && require && !dateMonth}
        >
          <option value="" disabled hidden>
            -
          </option>
          {monthOptionList.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </Form.Control>
        <div className="time-unit dropdown-unit pos-abs align-left">月</div>
      </Col>
      <Col sm={3}>
        <Form.Control
          as="select"
          size="md"
          className="form-datemonth"
          onChange={(e) => setTargetDateDate(e.currentTarget.value)}
          value={dateDate}
          disabled={isDisable}
          isInvalid={isClickSubmit && require && !dateDate}
        >
          <option value="" disabled hidden>
            -
          </option>
          {getDayOptionListInMonth(parseInt(dateMonth, 10), parseInt(dateYear, 10)).map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </Form.Control>
        <div className="time-unit dropdown-unit pos-abs align-left">日</div>
      </Col>
    </Row>
  );
}

export default InputYmd;
