import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import AvatarEditor from 'react-avatar-editor';
import axiosInstance from '../../../api/base';
import { DOCS_DIV_VALUE_AVATAR } from '../../../api/constants';
import '../../../styles/components/modalUploadAvatar.scss';
import noAvatar from '../../../assets/images/avatar.png';

export default function UploadAvatarModal({ avatarUrl, employeeCD, hideModal, isShown }) {
  const [uploadedAvatar, setUploadedAvatar] = useState(null);
  const [scale, setScale] = useState(1);
  const avatarEditor = useRef(null);

  const handleScale = (e) => {
    setScale(parseFloat(e.target.value));
  };

  const handleUploadAvatar = (e) => {
    setUploadedAvatar(e.target.files[0]);
  };

  const handleHideModal = () => {
    setUploadedAvatar(null);
    setScale(1);
    hideModal();
  };

  const handleSubmit = () => {
    fetch(avatarEditor.current.getImageScaledToCanvas().toDataURL())
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], 'avatar', { type: 'image/png' });
        console.log(file);

        const params = new URLSearchParams({
          related_docs_div: DOCS_DIV_VALUE_AVATAR,
          related_docs_ymd: moment().format('YYYY-MM-DD'),
        }).toString();

        const formData = new FormData();
        formData.append('files', file);

        axiosInstance
          .post(`/employees/${employeeCD}/employee_related_docs/test?${params}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(({ status }) => {
            if (status === 200) {
              setScale(1);
              hideModal();
            }
          });
      });
  };

  useEffect(() => {
    setUploadedAvatar(avatarUrl || uploadedAvatar);
  }, []);

  return (
    <Modal animation={false} show={isShown}>
      <Modal.Body>
        <div className="upload-avatar">
          <AvatarEditor
            ref={avatarEditor}
            image={uploadedAvatar || noAvatar}
            color={[0, 0, 0, 0.3]}
            scale={parseFloat(scale)}
            borderRadius={500}
          />

          <input
            className="upload-avatar__scale-input"
            defaultValue="1"
            max="2"
            min="1"
            name="scale"
            onChange={handleScale}
            step="0.01"
            type="range"
          />
        </div>
        <div className="upload-avatar__footer">
          <div className="upload-avatar__input">
            <input name="newImage" type="file" id="file" onChange={handleUploadAvatar} />
            <label htmlFor="file">＋画像アップロード</label>
          </div>
          <div className="upload-avatar__action">
            <Button className="btn-cancel" onClick={() => handleHideModal()}>
              キャンセル
            </Button>
            <Button
              className="btn-submit"
              onClick={() => handleSubmit()}
              disabled={uploadedAvatar === avatarUrl || !uploadedAvatar}
            >
              完了
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
