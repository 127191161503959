import { useState, useEffect } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import InputText from '../../../components/common/form/InputText';
import InputDropdown from '../../../components/common/form/InputDropdown';
import axiosInstance from '../../../api/base';
import {
  AUTH_CREATEUPDATE,
  AUTH_DELETE,
  DIV_ID_EMG_CONTACT_RELATIONSHIP,
} from '../../../api/constants';
import { handleBlur, handleChangePhone } from '../../../utils/handleInput';
import { setSuccessToast } from '../../../redux/slices/toast';
import HeaderForm from '../../../components/HeaderForm';
import { IsAuthorize } from '../../../redux/slices/auth';

const Schema = yup.object().shape({
  emg_contact_last_name: yup.string().required(' '),
  emg_contact_last_name_kn: yup
    .string()
    .required(' ')
    .trim()
    .matches(/^([ァ-ン]|ー)+$/, 'カタカナで入力してください'),
  emg_contact_first_name: yup.string().required(' '),
  emg_contact_first_name_kn: yup
    .string()
    .required(' ')
    .trim()
    .matches(/^([ァ-ン]|ー)+$/, 'カタカナで入力してください'),
  emg_contact_tel_areacd: yup.string().required(' '),
  emg_contact_tel_localcd: yup.string().required(' '),

  emg_contact_tel_number: yup.string().required(' '),
  emg_contact_rel_div: yup.string().required(' '),
});

export default function EmergencyContact(props) {
  const [relas, setRelations] = useState([]);
  const [currentContact, setContact] = useState({});
  const [deleteShowing, setDeleteShowing] = useState(false);
  const dispatch = useDispatch();

  const handleSubmitContact = async (params) => {
    params.emg_contact_rel_div = Number(params.emg_contact_rel_div);
    axiosInstance({
      method: Object.keys(currentContact).length ? 'patch' : 'post',
      url: `/employees/${props.match.params.id}/employee_emg_contacts/${
        Object.keys(currentContact).length ? currentContact.contact_priority : ''
      }`,
      data: params,
    }).then((response) => {
      if (response.data) {
        dispatch(setSuccessToast(true));
        props.history.push(`/employee/${props.match.params.id}`);
      }
    });
  };

  const deleteThisCheckup = () => {
    axiosInstance
      .delete(
        `/employees/${props.match.params.id}/employee_emg_contacts/${currentContact?.contact_priority}`
      )
      .then((response) => {
        if (response.data) props.history.push(`/employee/${props.match.params.id}`);
      });
  };

  useEffect(() => {
    setContact(props.location.state || {});
    axiosInstance.get(`divs/${DIV_ID_EMG_CONTACT_RELATIONSHIP}`).then((response) => {
      setRelations(response.data.sort((cur, next) => cur.display_order - next.display_order));
    });
  }, []);

  return (
    <div className="content w-form">
      <HeaderForm
        deleteShowing={deleteShowing}
        setDeleteShowing={setDeleteShowing}
        deleteThisCheckup={deleteThisCheckup}
        haveBtnDelete={IsAuthorize(AUTH_DELETE)}
        hideLabel
      />
      <Formik
        validationSchema={Schema}
        onSubmit={handleSubmitContact}
        enableReinitialize
        initialValues={currentContact}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          setFieldTouched,
          setFieldValue,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <div className="mt-6 bg-gray2 pa-16 pos-rel">
              <div className="w-90">
                <InputText
                  title="姓"
                  fieldKey="emg_contact_last_name"
                  colSm="5"
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
                <InputText
                  title="名"
                  fieldKey="emg_contact_first_name"
                  colSm="5"
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
                <InputText
                  title="姓カナ"
                  fieldKey="emg_contact_last_name_kn"
                  colSm="5"
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
                <InputText
                  title="名カナ"
                  fieldKey="emg_contact_first_name_kn"
                  colSm="5"
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  touched={touched}
                />

                <Form.Group as={Row} className="mb-6">
                  <Form.Label column sm="3" className="pl-10 required">
                    電話番号
                  </Form.Label>
                  <Col
                    as={Row}
                    className={`item-center ${
                      (errors.emg_contact_tel_areacd ||
                        errors.emg_contact_tel_localcd ||
                        errors.emg_contact_tel_number) &&
                      'mb-8'
                    }`}
                  >
                    <Col className="dis-rel">
                      <Form.Control
                        name="emg_contact_tel_areacd"
                        value={values.emg_contact_tel_areacd}
                        required
                        type="text"
                        onBlur={(e) => handleBlur(e, setFieldValue, setFieldTouched)}
                        onChange={(e) => handleChangePhone(e, setFieldValue)}
                        isInvalid={errors.emg_contact_tel_areacd}
                      />

                      <Form.Control.Feedback type="invalid" as="small" className="pos-abs">
                        {errors.emg_contact_tel_areacd}
                      </Form.Control.Feedback>
                    </Col>
                    <Col sm="1" className="text-center">
                      -
                    </Col>
                    <Col className="dis-rel">
                      <Form.Control
                        name="emg_contact_tel_localcd"
                        value={values.emg_contact_tel_localcd}
                        required
                        type="text"
                        onBlur={(e) => handleBlur(e, setFieldValue, setFieldTouched)}
                        onChange={(e) => handleChangePhone(e, setFieldValue)}
                        isInvalid={errors.emg_contact_tel_localcd}
                      />
                      <Form.Control.Feedback type="invalid" as="small" className="pos-abs">
                        {errors.emg_contact_tel_localcd}
                      </Form.Control.Feedback>
                    </Col>
                    <Col sm="1" className="text-center">
                      -
                    </Col>
                    <Col className="dis-rel">
                      <Form.Control
                        name="emg_contact_tel_number"
                        value={values.emg_contact_tel_number}
                        required
                        type="text"
                        onBlur={(e) => handleBlur(e, setFieldValue, setFieldTouched)}
                        onChange={(e) => handleChangePhone(e, setFieldValue)}
                        isInvalid={errors.emg_contact_tel_number}
                      />
                      <Form.Control.Feedback type="invalid" as="small" className="pos-abs">
                        {errors.emg_contact_tel_number}
                      </Form.Control.Feedback>
                    </Col>
                  </Col>
                  <Col md={{ span: 9, offset: 3 }} />
                </Form.Group>
                <InputDropdown
                  title="続柄"
                  values={values}
                  errors={errors}
                  touched={touched}
                  fieldKey="emg_contact_rel_div"
                  handleChange={handleChange}
                  options={relas}
                  optionKey="div_value"
                  optionName="div_value_nm"
                />
              </div>
            </div>
            <div className="dis-flex flex-center submit-wrapper">
              <button className="save-btn" type="submit" disabled={!IsAuthorize(AUTH_CREATEUPDATE)}>
                登録
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
