import { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import axiosInstance from '../../../api/base';
import InputDateForm from '../../../components/common/form/InputDateForm';
import InputDropdown from '../../../components/common/form/InputDropdown';
import { AUTH_CREATEUPDATE, AUTH_DELETE, DIV_ID_INSURANCE } from '../../../api/constants';
import { generateDateString } from '../../../utils/convert';
import HeaderForm from '../../../components/HeaderForm';
import { IsAuthorize } from '../../../redux/slices/auth';

const Schema = yup.object().shape({
  insurance_div: yup.number().required(' '),
  expiration_date_year: yup.number().required(' '),
  expiration_date_month: yup.number().required(' '),
  expiration_date_day: yup.number().required(' '),
});

export default function VehicleInsuranceEdit(props) {
  const [insuranceTypes, setInsuranceTypes] = useState([]);
  const [vehicleInsurance, setVehicleInsurance] = useState({
    expiration_date_year: '',
    expiration_date_month: '',
    expiration_date_day: '',
  });
  const [deleteShowing, setDeleteShowing] = useState(false);

  useEffect(() => {
    if (props.match.params.insuranceId && props.location.state) {
      const consultPlanDate = props.location.state.insurance.expiration_date?.split('-') || [];
      setVehicleInsurance({
        ...props.location.state.insurance,
        expiration_date_year: parseInt(consultPlanDate[0], 10) || '',
        expiration_date_month: parseInt(consultPlanDate[1], 10) || '',
        expiration_date_day: parseInt(consultPlanDate[2], 10) || '',
      });
      setInsuranceTypes(props.location.state.insuranceTypes);
    } else {
      axiosInstance.get(`divs/${DIV_ID_INSURANCE}`).then((res) => setInsuranceTypes(res.data));
    }
  }, []);

  const handleSubmitSend = (params) => {
    const { insuranceId } = props.match.params;
    axiosInstance({
      method: insuranceId ? 'put' : 'post',
      url: `/employees/${props.match.params.id}/employee_commuter_vehicle_insurances/${
        insuranceId || ''
      }`,
      data: {
        insurance_div: Number(params.insurance_div),
        expiration_date: generateDateString(
          params.expiration_date_year,
          params.expiration_date_month,
          params.expiration_date_day
        ),
      },
    }).then((response) => {
      if (response.data) props.history.push(`/employee/${props.match.params.id}/vehicleInsurance`);
    });
  };

  const deleteThisCheckup = () => {
    axiosInstance
      .delete(
        `/employees/${props.match.params.id}/employee_commuter_vehicle_insurances/${props.match.params.insuranceId}`
      )
      .then((response) => {
        if (response.data)
          props.history.push(`/employee/${props.match.params.id}/vehicleInsurance`);
      });
  };

  return (
    <div className="content w-form">
      <HeaderForm
        deleteShowing={deleteShowing}
        setDeleteShowing={setDeleteShowing}
        deleteThisCheckup={deleteThisCheckup}
        // eslint-disable-next-line react/destructuring-assignment
        haveBtnDelete={props.match.params.insuranceId && IsAuthorize(AUTH_DELETE)}
      />
      <Formik
        validationSchema={Schema}
        onSubmit={handleSubmitSend}
        enableReinitialize
        initialValues={vehicleInsurance}
      >
        {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <div className="mt-6 bg-gray2 pa-16 pos-rel">
              <div className="w-90">
                <InputDropdown
                  title="保険区分"
                  values={values}
                  errors={errors}
                  touched={touched}
                  fieldKey="insurance_div"
                  handleChange={handleChange}
                  options={insuranceTypes}
                  optionKey="div_value"
                  optionName="div_value_nm"
                />
                <InputDateForm
                  title="有効期限"
                  titleRequired
                  handleChange={handleChange}
                  valuePrefix="expiration_date"
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                />
              </div>
            </div>
            <div className="dis-flex flex-center submit-wrapper">
              <button className="save-btn" type="submit" disabled={!IsAuthorize(AUTH_CREATEUPDATE)}>
                登録
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
