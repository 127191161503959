import { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import FileHandler from '../../../components/FileHandler';
import HistoryList from '../../../components/HistoryList';
import InfoPageHeader from '../../../components/InfoPageHeader';
import axiosInstance from '../../../api/base';
import {
  DOCS_DIV_VALUE_COMMUTER_VEHICLE_INSURANCE,
  DIV_ID_INSURANCE,
} from '../../../api/constants';
import { getNewestByUpdateDatetime } from '../Detail';
import { computeDate } from '../../../utils/convert';

export default function VehicleInsuranceInfo(props) {
  const [oldInsurances, setOldInsurances] = useState([]);
  const [newestInsurances, setNewestInsurances] = useState([]);
  const [insurances, setInsurances] = useState([]);
  const [images, setImages] = useState([]);
  const [insuranceTypes, setInsuranceTypes] = useState([]);

  useEffect(() => {
    axiosInstance
      .get(
        `/employees/${props.match.params.id}/employee_related_docs/test/div/${DOCS_DIV_VALUE_COMMUTER_VEHICLE_INSURANCE}`
      )
      .then((response) => {
        setImages(response.data);
      });
    axiosInstance.get(`/divs/${DIV_ID_INSURANCE}`).then((response) => {
      setInsuranceTypes(response.data.sort((cur, next) => cur.display_order - next.display_order));
    });
  }, []);

  useEffect(() => {
    axiosInstance
      .get(`employees/${props.match.params.id}/employee_commuter_vehicle_insurances`)
      .then((response) => {
        if (response.data && response.data.length > 0) {
          insuranceTypes.forEach((type) => {
            const insurancesSameType = response.data.filter(
              (insurance) => insurance.insurance_div === type.div_value
            );
            const newest = getNewestByUpdateDatetime(insurancesSameType);
            if (newest) {
              setNewestInsurances((prev) => [...prev, newest]);
            }
          });
          setInsurances(response.data);
        }
      });
  }, [insuranceTypes]);

  useEffect(() => {
    const oldOnes = insurances.filter(
      (insurance) => newestInsurances.findIndex((newOne) => insurance.id === newOne.id) === -1
    );
    setOldInsurances(oldOnes);
  }, [insurances, newestInsurances]);

  const gotoPage = (subroute, state = {}) => {
    props.history.push({
      pathname: `/employee/${props.match.params.id}/vehicleInsurance/${subroute}`,
      state,
    });
  };

  return (
    <div className="content">
      <Row>
        <Col>
          <InfoPageHeader onCreate={() => gotoPage('new', { insuranceTypes })} />
          {newestInsurances.length > 0 &&
            newestInsurances.map((insurance) => (
              <FileHandler
                className="divider-btm"
                key={insurance.id}
                onEditClicked={() =>
                  gotoPage(`${insurance.id}/edit`, { insurance, insuranceTypes })
                }
              >
                <span>保険区分：</span>
                <span className="text-prim">
                  {
                    insuranceTypes.find((el) => el.div_value === insurance.insurance_div)
                      ?.div_value_nm
                  }
                </span>
                <br />
                <span className="text-lght">更新日：</span>
                <span>{computeDate(insurance.update_datetime)}</span>
                <br />
                <span className="text-lght">有効期限：</span>
                <span>{computeDate(insurance.expiration_date)}</span>
              </FileHandler>
            ))}
        </Col>
        <Col>
          <InfoPageHeader onCreate={() => gotoPage('uploadRelatedDocs')} imageHeader />

          {images.length > 0 && (
            <FileHandler
              centerEdit
              imageHandler
              className="divider-btm"
              onEditClicked={() => gotoPage(`editUploadedRelatedDocs/${images[0].related_docs_id}`)}
            >
              <span>更新日：</span>
              <span className="text-prim">{computeDate(images[0].related_docs_ymd)}</span>
            </FileHandler>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          {/* {oldInsurances.length > 0 && ( */}
          <HistoryList list={oldInsurances} showAllList>
            {oldInsurances?.map((insurance) => (
              <FileHandler
                grayIcon
                className="divider-btm"
                key={insurance.id}
                onEditClicked={() =>
                  gotoPage(`${insurance.id}/edit`, { insurance, insuranceTypes })
                }
              >
                <span>保険区分：</span>
                <span className="text-prim">
                  {
                    insuranceTypes.find((el) => el.div_value === insurance.insurance_div)
                      ?.div_value_nm
                  }
                </span>
                <br />
                <span className="text-lght">更新日：</span>
                <span>{computeDate(insurance.update_datetime)}</span>
                <br />
                <span className="text-lght">有効期限：</span>
                <span>{computeDate(insurance.expiration_date)}</span>
              </FileHandler>
            ))}
          </HistoryList>
          {/* )} */}
        </Col>
        <Col>
          <HistoryList list={images}>
            {images.slice(1).map((image, key) => (
              <FileHandler
                centerEdit
                imageHandler
                grayIcon
                className="divider-btm"
                key={key}
                onEditClicked={() => gotoPage(`editUploadedRelatedDocs/${image.related_docs_id}`)}
              >
                <span>更新日：</span>
                <span className="text-prim">{computeDate(image.related_docs_ymd)}</span>
              </FileHandler>
            ))}
          </HistoryList>
        </Col>
      </Row>
    </div>
  );
}
