/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';

import { Button, Col, Form, Row } from 'react-bootstrap';

import { ArrowClockwise } from 'react-bootstrap-icons';
import { InputLabel, Label } from '../../components/Input/InputLabel';
import {
  monthOptionList,
  getDayOptionListInMonth,
  getYYYY,
  getMM,
  getDD,
  toYYYYMMDD,
  listOptionBirthdayYear,
} from '../../utils/date';

// import { employee_info } from '../../dummy/employee_info.json';
import { DIV_ID_NATIONALITY, DIV_ID_BLOOD_TYPE, AUTH_CREATEUPDATE } from '../../api/constants';
import axiosInstance from '../../api/base';
import {
  MSG_VALIDATE_ALPHABET,
  MSG_VALIDATE_KATAKANA,
  REGEX_ALPHABET,
  REGEX_BOTH_FULL_HALF_NUMBER,
  REGEX_EMAIL_FULLWIDTH,
  REGEX_KATAKANA,
} from '../../utils/const';
import { handleBlur, handleChangePhone } from '../../utils/handleInput';
import { setSuccessToast } from '../../redux/slices/toast';
import { IsAuthorize } from '../../redux/slices/auth';

Yup.addMethod(Yup.string, 'phone', function validate(errorMessage) {
  return this.test(`phone`, errorMessage, function validate2(value) {
    const { path, createError } = this;
    return REGEX_BOTH_FULL_HALF_NUMBER.test(value) || createError({ path, message: errorMessage });
  });
});

const Schema = Yup.object().shape({
  employee_last_name: Yup.string().required(' '),
  employee_first_name: Yup.string().required(' '),
  employee_last_name_kn: Yup.string()
    .required(' ')
    .trim()
    .matches(REGEX_KATAKANA, MSG_VALIDATE_KATAKANA),
  employee_first_name_kn: Yup.string()
    .required(' ')
    .trim()
    .matches(REGEX_KATAKANA, MSG_VALIDATE_KATAKANA),
  employee_roman_last_name: Yup.string()
    .required(' ')
    .matches(REGEX_ALPHABET, MSG_VALIDATE_ALPHABET),
  employee_roman_first_name: Yup.string()
    .required(' ')
    .matches(REGEX_ALPHABET, MSG_VALIDATE_ALPHABET),
  birthday_year: Yup.string().required(' '),
  birthday_month: Yup.string().required(' '),
  birthday_day: Yup.string().required(' '),
  tel_areacd: Yup.string().required(' ').phone('Invalid number'),
  tel_localcd: Yup.string().required(' ').phone('Invalid number'),
  tel_number: Yup.string().required(' ').phone('Invalid number'),
  mail_address: Yup.string()
    .nullable()
    .test('is-email', '有効なメールアドレスを入力する必要があります', (value) => {
      const check = value?.match(REGEX_EMAIL_FULLWIDTH);
      return !value || (check && check[0] === value);
    }),
});

function EmployeeEdit(props) {
  const dispatch = useDispatch();

  const [nationalityOptionList, setNationalityOptionList] = useState([]);
  const [bloodTypeOptionList, setBloodTypeOptionList] = useState([]);
  const [formInitialValues, setFormInitialValues] = useState({
    birthday_year: 0,
    birthday_month: 0,
    birthday_day: 0,
  });
  const resetValue = (setFieldValue, valuePrefix) => {
    const yearKey = `${valuePrefix}_year`;
    const monthKey = `${valuePrefix}_month`;
    const dayKey = `${valuePrefix}_day`;
    setTimeout(() => {
      setFieldValue(dayKey, '');
      setFieldValue(monthKey, '');
      setFieldValue(yearKey, '');
    }, 0);
  };

  const handleSubmitEdit = async (values) => {
    try {
      const formatValues = {
        ...values,
        birthday: toYYYYMMDD(values.birthday_year, values.birthday_month, values.birthday_day),
      };

      // eslint-disable-next-line no-console
      const response = await axiosInstance.patch(
        `/employees/${props.match.params.id}`,
        formatValues
      );
      if (response.status >= 500) {
        throw response.status;
      } else {
        dispatch(setSuccessToast(true));
        props.history.push(`/employee/${props.match.params.id}`);
      }
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    const init = async () => {
      const res = await axiosInstance.get(`/employees/${props.match.params.id}`);
      setFormInitialValues({
        ...res.data.employee_info,
        birthday_year: getYYYY(res.data.employee_info.birthday),
        birthday_month: getMM(res.data.employee_info.birthday),
        birthday_day: getDD(res.data.employee_info.birthday),
      });
      const resNationality = await axiosInstance.get(`/divs/${DIV_ID_NATIONALITY}`);
      setNationalityOptionList(
        resNationality.data.map((e) => ({ value: e.div_value, label: e.div_value_nm }))
      );
      const resBloodType = await axiosInstance.get(`/divs/${DIV_ID_BLOOD_TYPE}`);
      setBloodTypeOptionList(
        resBloodType.data.map((e) => ({ value: e.div_value, label: e.div_value_nm }))
      );
    };
    init();
  }, []);

  return (
    <>
      {/* <NavBar title="基本情報の編集" breadcrumbs={breadcrumbs} /> */}
      <div className="employee-form-body">
        <Formik
          validationSchema={Schema}
          enableReinitialize="true"
          initialValues={formInitialValues}
          onSubmit={handleSubmitEdit}
        >
          {({ handleSubmit, errors, touched, setFieldValue, values, setFieldTouched }) => (
            <Form onSubmit={handleSubmit}>
              <div className="employee-form-main">
                {/* 基本情報 */}
                <Form.Group>
                  <Row className="mb-4">
                    <Col sm={3}>
                      <Label label="姓" isRequired />
                    </Col>
                    <Col sm={3} className="pl-0 pr-0">
                      <InputLabel
                        name="employee_last_name"
                        placeholder="山田"
                        error={touched.employee_last_name && errors.employee_last_name}
                      />
                    </Col>
                    <Col sm={3} className="pa-l-10">
                      <Label label="名" isRequired />
                    </Col>
                    <Col sm={3} className="pl-0 pr-0">
                      <InputLabel
                        name="employee_first_name"
                        placeholder="太郎"
                        error={touched.employee_first_name && errors.employee_first_name}
                      />
                    </Col>
                  </Row>

                  <Row className="mb-4">
                    <Col sm={3}>
                      <Label label="セイ" isRequired />
                    </Col>
                    <Col sm={3} className="pl-0 pr-0">
                      <InputLabel
                        name="employee_last_name_kn"
                        placeholder="ヤマダ"
                        error={touched.employee_last_name_kn && errors.employee_last_name_kn}
                      />
                    </Col>
                    <Col sm={3} className="pa-l-10">
                      <Label label="メイ" isRequired />
                    </Col>
                    <Col sm={3} className="pl-0 pr-0">
                      <InputLabel
                        name="employee_first_name_kn"
                        placeholder="タロウ"
                        error={touched.employee_first_name_kn && errors.employee_first_name_kn}
                      />
                    </Col>
                  </Row>
                </Form.Group>

                {/* ローマ字 */}
                <Form.Group>
                  <Row className="mb-4">
                    <Col sm={3}>
                      <Label label="Last" isRequired />
                    </Col>
                    <Col sm={3} className="pl-0 pr-0">
                      <InputLabel
                        name="employee_roman_last_name"
                        placeholder="Yamada"
                        error={touched.employee_roman_last_name && errors.employee_roman_last_name}
                      />
                    </Col>
                    <Col sm={3} className="pa-l-10">
                      <Label label="First" isRequired />
                    </Col>
                    <Col sm={3} className="pl-0 pr-0">
                      <InputLabel
                        name="employee_roman_first_name"
                        placeholder="Taro"
                        error={
                          touched.employee_roman_first_name && errors.employee_roman_first_name
                        }
                      />
                    </Col>
                  </Row>
                </Form.Group>

                {/* 生年月日 */}
                <Form.Group>
                  <Row className="mb-4 position-relative">
                    <ArrowClockwise
                      onClick={() => resetValue(setFieldValue, 'birthday')}
                      className="position-absolute top-0"
                      style={{ top: '12px', right: '-25px', cursor: 'pointer' }}
                    />
                    <Col sm={3}>
                      <Label label="生年月日" isRequired />
                    </Col>
                    <Col sm={3} className="pl-0 pr-0">
                      <InputLabel
                        name="birthday_year"
                        placeholder="選択してください"
                        error={touched.birthday_year && errors.birthday_year}
                        component={
                          <select
                            className={`select-common w-100 ${
                              !values.birthday_year && 'text-muted'
                            }`}
                            value={String(values.birthday_year) || ''}
                            name="birthday_year"
                            onChange={(e) => setFieldValue('birthday_year', e.target.value)}
                          >
                            <option value="" disabled hidden>
                              選択してください
                            </option>
                            {listOptionBirthdayYear.map((item) => (
                              <option
                                key={item.value}
                                value={item.value}
                                selected={item.value === values.birthday_year ? 'selected' : ''}
                              >
                                {item.label}
                              </option>
                            ))}
                          </select>
                        }
                      />
                    </Col>
                    <Col sm={1} />
                    <Col sm={2} className="pl-0 pr-0 pos-rel">
                      <InputLabel
                        name="birthday_month"
                        placeholder=""
                        error={touched.birthday_month && errors.birthday_month}
                        component={
                          <select
                            className={`select-common w-100 ${
                              !values.birthday_month && 'text-muted'
                            }`}
                            value={String(values.birthday_month) || ''}
                            name="birthday_month"
                            onChange={(e) => setFieldValue('birthday_month', e.target.value)}
                          >
                            <option value="" disabled hidden />
                            {monthOptionList.map((item) => (
                              <option
                                key={item.value}
                                value={item.value}
                                selected={item.value === values.birthday_month ? 'selected' : ''}
                              >
                                {item.label}
                              </option>
                            ))}
                          </select>
                        }
                      />
                      {!errors.birthday_month && (
                        <div className="time-unit dropdown-unit pos-abs">月</div>
                      )}
                    </Col>
                    <Col sm={1} />
                    <Col sm={2} className="pl-0 pr-0 pos-rel">
                      <InputLabel
                        disabled
                        name="birthday_day"
                        placeholder=""
                        error={touched.birthday_day && errors.birthday_day}
                        component={
                          <select
                            className={`select-common w-100 ${
                              !values.birthday_day && 'text-muted'
                            }`}
                            value={values.birthday_day || ''}
                            name="birthday_day"
                            onChange={(e) => setFieldValue('birthday_day', e.target.value)}
                          >
                            <option value="" disabled hidden />
                            {getDayOptionListInMonth(
                              parseInt(values.birthday_month, 10),
                              parseInt(values.birthday_year, 10)
                            ).map((item) => (
                              <option
                                key={item.value}
                                value={item.value}
                                selected={item.value === values.birthday_day ? 'selected' : ''}
                              >
                                {item.label}
                              </option>
                            ))}
                          </select>
                        }
                      />
                      {!errors.birthday_day && (
                        <div className="time-unit dropdown-unit pos-abs">日</div>
                      )}
                    </Col>
                  </Row>

                  <Row className="mb-4">
                    <Col sm={3}>
                      <Label isRequired>性別</Label>
                    </Col>
                    <Col sm={9} className="pl-0 pr-0">
                      <Row>
                        <Col className="text-left">
                          <input
                            type="radio"
                            id="男"
                            name="sex"
                            checked={values.sex === 1}
                            onChange={() => setFieldValue('sex', 1)}
                          />
                          <label htmlFor="男">男</label>
                        </Col>
                        <Col className="text-left">
                          <input
                            type="radio"
                            id="女"
                            name="sex"
                            checked={values.sex === 2}
                            onChange={() => setFieldValue('sex', 2)}
                          />
                          <label htmlFor="女">女</label>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row className="mb-4">
                    <Col sm={3}>
                      <Label isRequired>配偶者</Label>
                    </Col>
                    <Col sm={9} className="pl-0 pr-0">
                      <Row>
                        <Col className="text-left">
                          <input
                            type="radio"
                            id="なし"
                            name="have_spouse_flag"
                            checked={values.have_spouse_flag === 0}
                            onChange={() => setFieldValue('have_spouse_flag', 0)}
                          />
                          <label htmlFor="なし">なし</label>
                        </Col>
                        <Col className="text-left">
                          <input
                            type="radio"
                            id="あり"
                            name="have_spouse_flag"
                            checked={values.have_spouse_flag === 1}
                            onChange={() => setFieldValue('have_spouse_flag', 1)}
                          />
                          <label htmlFor="あり">あり</label>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row className="mb-4">
                    <Col sm={3}>
                      <Label>国籍</Label>
                    </Col>
                    <Col sm={9} className="pl-0 pr-0">
                      <InputLabel
                        name="nationality"
                        isRequired={false}
                        error={touched.nationality && errors.nationality}
                        component={
                          <select
                            className={`select-common w-100 ${!values.nationality && 'text-muted'}`}
                            defaultValue={values.nationality}
                            name="nationality"
                            onChange={(e) => setFieldValue('nationality', e.target.value)}
                          >
                            <option value="" disabled hidden>
                              選択してください
                            </option>
                            {!!nationalityOptionList.length &&
                              nationalityOptionList.map((item) => (
                                <option
                                  key={item.value}
                                  value={item.value}
                                  selected={item.value === values.nationality ? 'selected' : ''}
                                >
                                  {item.label}
                                </option>
                              ))}
                          </select>
                        }
                      />
                    </Col>
                  </Row>

                  <Row className="mb-4">
                    <Col sm={3}>
                      <Label isRequired>電話番号</Label>
                    </Col>
                    <Col className="p-0">
                      <InputLabel
                        name="tel_areacd"
                        placeholder=""
                        error={touched.tel_areacd && errors.tel_areacd}
                        className="input-number__phone"
                        onBlur={(e) => handleBlur(e, setFieldValue, setFieldTouched)}
                        onChange={(e) => handleChangePhone(e, setFieldValue)}
                      />
                    </Col>
                    <div className="w-5 text-center">-</div>
                    <Col className="p-0">
                      <InputLabel
                        name="tel_localcd"
                        placeholder=""
                        error={touched.tel_localcd && errors.tel_localcd}
                        className="input-number__phone"
                        onBlur={(e) => handleBlur(e, setFieldValue, setFieldTouched)}
                        onChange={(e) => handleChangePhone(e, setFieldValue)}
                      />
                    </Col>
                    <div className="w-5 text-center">-</div>
                    <Col className="p-0">
                      <InputLabel
                        name="tel_number"
                        placeholder=""
                        error={touched.tel_number && errors.tel_number}
                        className="input-number__phone"
                        onBlur={(e) => handleBlur(e, setFieldValue, setFieldTouched)}
                        onChange={(e) => handleChangePhone(e, setFieldValue)}
                      />
                    </Col>
                    <Col sm={3} />
                    <Col sm={3} />
                  </Row>

                  <Row className="mb-4">
                    <Col sm={3}>
                      <Label>メールアドレス</Label>
                    </Col>
                    <Col sm={9} className="pl-0 pr-0">
                      <InputLabel
                        name="mail_address"
                        placeholder=""
                        error={touched.mail_address && errors.mail_address}
                        onBlur={(e) => handleBlur(e, setFieldValue, setFieldTouched)}
                      />
                    </Col>
                  </Row>

                  <Row className="mb-4">
                    <Col sm={3}>
                      <Label>血液型</Label>
                    </Col>
                    <Col sm={9} className="pl-0 pr-0">
                      <InputLabel
                        name="blood_type"
                        isRequired={false}
                        error={touched.blood_type && errors.blood_type}
                        component={
                          <select
                            className={`select-common w-100 ${!values.blood_type && 'text-muted'}`}
                            defaultValue={values.blood_type}
                            name="blood_type"
                            onChange={(e) => setFieldValue('blood_type', e.target.value)}
                          >
                            <option value="" disabled hidden>
                              選択してください
                            </option>
                            {!!bloodTypeOptionList.length &&
                              bloodTypeOptionList.map((item) => (
                                <option
                                  key={item.value}
                                  value={item.value}
                                  selected={item.value === values.blood_type ? 'selected' : ''}
                                >
                                  {item.label}
                                </option>
                              ))}
                          </select>
                        }
                      />
                    </Col>
                  </Row>
                </Form.Group>

                <div className="line-spread" />

                <Form.Group>
                  <h2 className="title-main__form">備考</h2>
                  <Row className="mb-4">
                    <Col sm={3}>
                      <Label>メモ</Label>
                    </Col>
                    <Col sm={9} className="pl-0">
                      <Field
                        sm={9}
                        name="freememo"
                        component="textarea"
                        className="freememo"
                        rows={3}
                        onChange={(e) => setFieldValue('freememo', e.target.value)}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </div>
              <div className="dis-flex flex-center submit-wrapper">
                <Button
                  type="submit"
                  className="save-btn"
                  disabled={!IsAuthorize(AUTH_CREATEUPDATE)}
                >
                  変更を保存
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default EmployeeEdit;
