import iconDelete from '../../../assets/images/icon-delete.svg';

export default function DeleteModalButton({ onClick, higher }) {
  return (
    <button
      type="button"
      className={`delete-btn ${higher && 'delete-btn-high'}`}
      size="sm"
      variant="light"
      onClick={onClick}
    >
      <img src={iconDelete} alt="icon-delete" className="mr-2" />
      削除する
    </button>
  );
}
