import { useState, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import InputDateForm from '../../../components/common/form/InputDateForm';
import axiosInstance from '../../../api/base';
import { generateDateString } from '../../../utils/convert';
import { setSuccessToast } from '../../../redux/slices/toast';
import HeaderForm from '../../../components/HeaderForm';
import { AUTH_CREATEUPDATE } from '../../../api/constants';
import { IsAuthorize } from '../../../redux/slices/auth';

const Schema = yup.object().shape({
  basic_pension_no_prefix: yup
    .string()
    .required(' ')
    .trim()
    .matches(/^[0-9]+$/, '半角数字で入力してください')
    .length(4, '数字4文字で入力してください'),
  basic_pension_no_suffix: yup
    .string()
    .required(' ')
    .trim()
    .matches(/^[0-9]+$/, '半角数字で入力してください')
    .length(6, '数字6文字で入力してください'),
  pen_hb_issuance_ymd_year: yup.number().required(' '),
  pen_hb_issuance_ymd_month: yup.number().required(' '),
  pen_hb_issuance_ymd_day: yup.number().required(' '),
  spouse_basic_pension_no_prefix: yup
    .string()
    .trim()
    .matches(/^[0-9]+$/, '半角数字で入力してください')
    .length(4, '数字4文字で入力してください'),
  spouse_basic_pension_no_suffix: yup
    .string()
    .trim()
    .matches(/^[0-9]+$/, '半角数字で入力してください')
    .length(6, '数字6文字で入力してください'),
  spouse_pen_hb_issuance_ymd_year: yup.number(),
  spouse_pen_hb_issuance_ymd_month: yup.number(),
  spouse_pen_hb_issuance_ymd_day: yup.number(),
});

export default function PensionBookEdit(props) {
  const [pensionInfo, setPensionInfo] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    let info = {};
    if (props.location.state) {
      info = { ...props.location.state };
    } else {
      axiosInstance
        .get(`/employees/${props.match.params.id}/employee_social_ins`)
        .then((response) => {
          info = response.data;
        });
    }
    const pensionDate = info.pen_hb_issuance_ymd?.split('-') || [];
    const pensionNo = info.basic_pension_no?.split('-') || [];
    const spousePensionDate = info.spouse_pen_hb_issuance_ymd?.split('-') || [];
    const spousePensionNo = info.spouse_basic_pension_no?.split('-') || [];

    setPensionInfo({
      ...info,
      basic_pension_no_prefix: parseInt(pensionNo[0], 10) || '',
      basic_pension_no_suffix: parseInt(pensionNo[1], 10) || '',
      pen_hb_issuance_ymd_year: parseInt(pensionDate[0], 10) || '',
      pen_hb_issuance_ymd_month: parseInt(pensionDate[1], 10) || '',
      pen_hb_issuance_ymd_day: parseInt(pensionDate[2], 10) || '',
      spouse_basic_pension_no_prefix: parseInt(spousePensionNo[0], 10) || '',
      spouse_basic_pension_no_suffix: parseInt(spousePensionNo[1], 10) || '',
      spouse_pen_hb_issuance_ymd_year: parseInt(spousePensionDate[0], 10) || '',
      spouse_pen_hb_issuance_ymd_month: parseInt(spousePensionDate[1], 10) || '',
      spouse_pen_hb_issuance_ymd_day: parseInt(spousePensionDate[2], 10) || '',
    });
  }, []);

  const handleSubmitContact = (params) => {
    axiosInstance
      .post(`/employees/${props.match.params.id}/employee_social_ins`, {
        pen_hb_issuance_ymd: generateDateString(
          params.pen_hb_issuance_ymd_year,
          params.pen_hb_issuance_ymd_month,
          params.pen_hb_issuance_ymd_day
        ),
        spouse_pen_hb_issuance_ymd: generateDateString(
          params.spouse_pen_hb_issuance_ymd_year,
          params.spouse_pen_hb_issuance_ymd_month,
          params.spouse_pen_hb_issuance_ymd_day
        ),
        basic_pension_no: `${params.basic_pension_no_prefix}-${params.basic_pension_no_suffix}`,
        spouse_basic_pension_no: `${params.spouse_basic_pension_no_prefix}-${params.spouse_basic_pension_no_suffix}`,
      })
      .then((response) => {
        if (response.data) {
          dispatch(setSuccessToast(true));
          props.history.push(`/employee/${props.match.params.id}/pensionBook`);
        }
      });
  };

  return (
    <div className="content w-form">
      <HeaderForm />
      <Formik
        validationSchema={Schema}
        onSubmit={handleSubmitContact}
        enableReinitialize
        initialValues={pensionInfo}
      >
        {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <div className="mt-6 bg-gray2 pa-16 pos-rel">
              <div className="w-90">
                <div className="ml-2">本人</div>
                <InputDateForm
                  title="交付年月日"
                  titleRequired
                  handleChange={handleChange}
                  valuePrefix="pen_hb_issuance_ymd"
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                />
                <Form.Group as={Row} className="mb-8">
                  <Form.Label column sm="3" className="pl-10 required">
                    基礎年金番号
                  </Form.Label>
                  <Col as={Row} className="item-center">
                    <Col className="pos-rel" sm="5">
                      <Form.Control
                        name="basic_pension_no_prefix"
                        value={values.basic_pension_no_prefix}
                        required
                        onChange={handleChange}
                        isInvalid={errors.basic_pension_no_prefix}
                      />
                      <Form.Control.Feedback type="invalid" as="small" className="pos-abs">
                        {errors.basic_pension_no_prefix}
                      </Form.Control.Feedback>
                    </Col>
                    <Col sm="1" className="text-center">
                      -
                    </Col>
                    <Col className="pos-rel" sm="6">
                      <Form.Control
                        name="basic_pension_no_suffix"
                        value={values.basic_pension_no_suffix}
                        required
                        onChange={handleChange}
                        isInvalid={errors.basic_pension_no_suffix}
                      />
                      <Form.Control.Feedback type="invalid" as="small" className="pos-abs">
                        {errors.basic_pension_no_suffix}
                      </Form.Control.Feedback>
                    </Col>
                  </Col>
                  <Col md={{ span: 9, offset: 3 }}>
                    {!errors.basic_pension_no_prefix &&
                      !errors.basic_pension_no_suffix &&
                      !errors.tel_number && (
                        <Form.Text className="text-muted">半角数字で入力</Form.Text>
                      )}
                  </Col>
                </Form.Group>
                <div className="ml-2">配偶者</div>
                <InputDateForm
                  title="交付年月日"
                  handleChange={handleChange}
                  valuePrefix="spouse_pen_hb_issuance_ymd"
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                />
                <Form.Group as={Row} className="mb-8">
                  <Form.Label column sm="3" className="pl-10">
                    基礎年金番号
                  </Form.Label>
                  <Col as={Row} className="item-center">
                    <Col className="pos-rel" sm="5">
                      <Form.Control
                        name="spouse_basic_pension_no_prefix"
                        value={values.spouse_basic_pension_no_prefix}
                        onChange={handleChange}
                        isInvalid={errors.spouse_basic_pension_no_prefix}
                      />
                      <Form.Control.Feedback type="invalid" as="small" className="pos-abs">
                        {errors.spouse_basic_pension_no_prefix}
                      </Form.Control.Feedback>
                    </Col>
                    <Col sm="1" className="text-center">
                      -
                    </Col>
                    <Col className="pos-rel" sm="6">
                      <Form.Control
                        name="spouse_basic_pension_no_suffix"
                        value={values.spouse_basic_pension_no_suffix}
                        onChange={handleChange}
                        isInvalid={errors.spouse_basic_pension_no_suffix}
                      />
                      <Form.Control.Feedback type="invalid" as="small" className="pos-abs">
                        {errors.spouse_basic_pension_no_suffix}
                      </Form.Control.Feedback>
                    </Col>
                  </Col>
                  <Col md={{ span: 9, offset: 3 }}>
                    {!errors.spouse_basic_pension_no_prefix &&
                      !errors.spouse_basic_pension_no_suffix &&
                      !errors.tel_number && (
                        <Form.Text className="text-muted">半角数字で入力</Form.Text>
                      )}
                  </Col>
                </Form.Group>
              </div>
            </div>
            <div className="dis-flex flex-center submit-wrapper">
              <button className="save-btn" type="submit" disabled={!IsAuthorize(AUTH_CREATEUPDATE)}>
                登録
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
