import { Form, Row, Col } from 'react-bootstrap';
import { ArrowClockwise } from 'react-bootstrap-icons';
import { RANGE_OPTION_DATE } from '../../../utils/const';
import {
  yearOptionList,
  monthOptionList,
  getDayOptionListInMonth,
  // listOptionExpirationYear,
  // listOptionBirthdayYear,
  generateListOptionYear,
} from '../../../utils/date';

export default function InputDateForm(props) {
  const {
    valuePrefix,
    values,
    errors,
    handleChange,
    title,
    titleRequired,
    noDay,
    setFieldValue,
    disabled,
    titleSm,
    yearSm,
    monthSm,
    daySm,
    resetStyle,
  } = props;

  let listOptionYear = [];

  const typeDate = valuePrefix.toUpperCase();
  // eslint-disable-next-line no-prototype-builtins
  if (RANGE_OPTION_DATE.hasOwnProperty(typeDate)) {
    listOptionYear = generateListOptionYear(RANGE_OPTION_DATE[typeDate]);
  } else listOptionYear = yearOptionList;

  const yearKey = `${valuePrefix}_year`;
  const monthKey = `${valuePrefix}_month`;
  const dayKey = `${valuePrefix}_day`;

  const resetValue = () => {
    setTimeout(() => {
      if (!noDay) setFieldValue(dayKey, '');
      setFieldValue(monthKey, '');
      setFieldValue(yearKey, '');
    }, 0);
  };

  return (
    <>
      <Form.Group as={Row} className="mb-8 position-relative">
        <ArrowClockwise
          onClick={resetValue}
          className="position-absolute top-0"
          style={
            resetStyle || { top: '14px', right: `${noDay ? '170' : '-10'}px`, cursor: 'pointer' }
          }
        />
        <Form.Label column sm={titleSm || '3'} className={`pl-10 ${titleRequired && 'required'}`}>
          {title}
        </Form.Label>
        <Col sm={yearSm || '3'}>
          <Form.Control
            as="select"
            name={yearKey}
            value={values[yearKey] || ''}
            onChange={handleChange}
            isInvalid={errors[yearKey]}
            disabled={disabled}
          >
            <option value="" disabled hidden>
              -
            </option>
            {listOptionYear.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid" as="small" className="pos-abs">
            {errors[yearKey]}
          </Form.Control.Feedback>
        </Col>
        {!monthSm && <Form.Label column sm="1" className="text-right" />}
        <Col sm={monthSm || '2'}>
          <div className="pos-rel">
            <Form.Control
              as="select"
              required
              name={monthKey}
              value={values[monthKey] || ''}
              onChange={handleChange}
              isInvalid={errors[monthKey]}
              disabled={disabled}
            >
              <option value="" disabled hidden>
                -
              </option>
              {monthOptionList.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </Form.Control>
            {!errors[monthKey] && <div className="time-unit dropdown-unit pos-abs">月</div>}
            <Form.Control.Feedback type="invalid" as="small" className="pos-abs">
              {errors[monthKey]}
            </Form.Control.Feedback>
          </div>
        </Col>
        {!noDay && (
          <>
            {!daySm && <Form.Label column sm="1" className="text-right" />}
            <Col sm={daySm || '2'}>
              <div className="pos-rel">
                <Form.Control
                  as="select"
                  required
                  name={dayKey}
                  value={values[dayKey] || ''}
                  onChange={handleChange}
                  isInvalid={errors[dayKey]}
                  disabled={disabled}
                >
                  <option value="" disabled hidden>
                    -
                  </option>
                  {getDayOptionListInMonth(values[monthKey], values[yearKey]).map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Form.Control>
                {!errors[dayKey] && <div className="time-unit dropdown-unit pos-abs">日</div>}
                <Form.Control.Feedback type="invalid" as="small" className="pos-abs">
                  {errors[dayKey]}
                </Form.Control.Feedback>
              </div>
            </Col>
          </>
        )}
      </Form.Group>
    </>
  );
}
