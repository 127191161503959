/* eslint-disable camelcase */
import { useState, useEffect } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import * as yup from 'yup';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import InputDropdown from '../../../components/common/form/InputDropdown';
import InputText from '../../../components/common/form/InputText';
import axiosInstance, { fetchPostalCode } from '../../../api/base';
import { PREFECTURES } from '../../../utils/const';
import { AUTH_CREATEUPDATE, DIV_ID_EMG_CONTACT_RELATIONSHIP } from '../../../api/constants';
import { handleBlur, handleChangePhone } from '../../../utils/handleInput';
import { setSuccessToast } from '../../../redux/slices/toast';
import HeaderForm from '../../../components/HeaderForm';
import { IsAuthorize } from '../../../redux/slices/auth';

const Schema = yup.object().shape({
  postal_code_1: yup
    .string()
    .nullable()
    .required(' ')
    .trim()
    .matches(/^[0-9]+$/, '半角数字で入力してください')
    .length(3, '数字3文字で入力してください'),
  postal_code_2: yup
    .string()
    .nullable()
    .required(' ')
    .trim()
    .matches(/^[0-9]+$/, '半角数字で入力してください')
    .length(4, '数字4文字で入力してください'),
  pref: yup.string().required(' '),
  city: yup.string().required(' ').nullable(),
  house_number: yup.string().required(' ').nullable(),
  building: yup.string().nullable(),
  householder_name: yup.string().required(' ').nullable(),
  householder_rel_div: yup.number().required(' ').nullable(),
  tel_areacd: yup.string().required(' '),
  tel_localcd: yup.string().required(' '),
  tel_number: yup.string().required(' '),
});

export default function HousingEdit(props) {
  const [housingInfo, setHousingInfo] = useState({});
  const [errorPostalService, setErrorPostalService] = useState('');
  const [relas, setRelations] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.location.state) {
      setHousingInfo({
        ...props.location.state,
      });
    } else {
      axiosInstance
        .get(`/employees/${props.match.params.id}/housingInformation`)
        .then((response) => {
          setHousingInfo(response.data);
        });
    }
    axiosInstance.get(`divs/${DIV_ID_EMG_CONTACT_RELATIONSHIP}`).then((response) => {
      setRelations(response.data.sort((cur, next) => cur.display_order - next.display_order));
    });
  }, []);

  const handleSubmitContact = (params) => {
    axiosInstance
      .patch(`/employees/${props.match.params.id}/housingInformation`, {
        ...params,
        householder_rel_div: parseInt(params.householder_rel_div, 10),
        job_contract_update_datetime: housingInfo.job_contract_update_datetime,
        id: housingInfo.id,
        company_cd: housingInfo.company_cd,
      })
      .then((response) => {
        if (response.data) {
          dispatch(setSuccessToast(true));
          props.history.push(`/employee/${props.match.params.id}/housing`);
        }
      });
  };

  const seachPostalCode = (values) => {
    const postalCode = `${values.postal_code_1}-${values.postal_code_2}`;
    fetchPostalCode(postalCode).then((response) => {
      if (response.results) {
        setHousingInfo({
          ...values,
          pref: response.results[0].address1,
          city: response.results[0].address2,
          house_number: response.results[0].address3,
        });
        setErrorPostalService('');
      } else setErrorPostalService('現在住所検索サービスが利用できません。住所をご入力ください。');
    });
  };

  return (
    <div className="content w-form">
      <HeaderForm />
      <Formik
        validationSchema={Schema}
        onSubmit={handleSubmitContact}
        enableReinitialize
        initialValues={housingInfo}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          setFieldTouched,
          setFieldValue,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <div className="mt-6 bg-gray2 pa-16 pos-rel">
              <div className="w-90">
                <Form.Group as={Row} className="mb-8">
                  <Form.Label column sm="3" className="pl-10 required">
                    郵便番号
                  </Form.Label>
                  <Col as={Row} className="item-center">
                    <Col className="pos-rel">
                      <Form.Control
                        name="postal_code_1"
                        value={values.postal_code_1}
                        onChange={handleChange}
                        required
                        type="text"
                        isInvalid={errors.postal_code_1}
                      />
                      <Form.Control.Feedback type="invalid" as="small" className="pos-abs">
                        {errors.postal_code_1}
                      </Form.Control.Feedback>
                    </Col>
                    <Col sm="1" className="text-center">
                      -
                    </Col>
                    <Col className="pos-rel">
                      <Form.Control
                        name="postal_code_2"
                        value={values.postal_code_2}
                        onChange={handleChange}
                        required
                        type="text"
                        isInvalid={errors.postal_code_2}
                      />
                      <Form.Control.Feedback type="invalid" as="small" className="pos-abs">
                        {errors.postal_code_2}
                      </Form.Control.Feedback>
                    </Col>
                    <Col>
                      <Button
                        variant="link"
                        className="bold text-prim"
                        disabled={errors.postal_code_1 || errors.postal_code_2}
                        onClick={() => seachPostalCode(values)}
                      >
                        <Search size={18} className="mr-r-md" />
                        自動入力
                      </Button>
                    </Col>
                  </Col>
                  <Col
                    md={{ span: 9, offset: 3 }}
                    className={`${
                      !errors.postal_code_1 && !errors.postal_code_2 && errorPostalService && 'mb-8'
                    }`}
                  >
                    {!errors.postal_code_1 && !errors.postal_code_2 && errorPostalService && (
                      <Form.Text className="text-danger">{errorPostalService}</Form.Text>
                    )}
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-8">
                  <Form.Label column sm="3" className="pl-10 required">
                    都道府県
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      as="select"
                      name="pref"
                      value={values.pref}
                      defaultValue={values.pref}
                      onChange={handleChange}
                    >
                      <option value="" disabled hidden>
                        選択してください
                      </option>
                      {PREFECTURES.map((prefecture, index) => (
                        <option key={index} value={prefecture}>
                          {prefecture}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Form.Group>
                <InputText
                  title="市町村区"
                  fieldKey="city"
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
                <InputText
                  title="番地"
                  fieldKey="house_number"
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
                <InputText
                  title="建物名部屋番号"
                  fieldKey="building"
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  touched={touched}
                  notRequired
                />
                <InputText
                  title="世帯主氏名"
                  fieldKey="householder_name"
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
                <InputDropdown
                  title="世帯主続柄"
                  fieldKey="householder_rel_div"
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  touched={touched}
                  options={relas}
                  optionKey="div_value"
                  optionName="div_value_nm"
                />

                <Form.Group as={Row} className="mb-6">
                  <Form.Label column sm="3" className="pl-10 required">
                    電話番号
                  </Form.Label>
                  <Col
                    as={Row}
                    className={`item-center ${
                      (errors.tel_areacd || errors.tel_localcd || errors.tel_number) && 'mb-8'
                    }`}
                  >
                    <Col className="dis-rel">
                      <Form.Control
                        name="tel_areacd"
                        value={values.tel_areacd}
                        required
                        type="text"
                        onBlur={(e) => handleBlur(e, setFieldValue, setFieldTouched)}
                        onChange={(e) => handleChangePhone(e, setFieldValue)}
                        isInvalid={errors.tel_areacd}
                      />

                      <Form.Control.Feedback type="invalid" as="small" className="pos-abs">
                        {errors.tel_areacd}
                      </Form.Control.Feedback>
                    </Col>
                    <Col sm="1" className="text-center">
                      -
                    </Col>
                    <Col className="dis-rel">
                      <Form.Control
                        name="tel_localcd"
                        value={values.tel_localcd}
                        required
                        type="text"
                        onBlur={(e) => handleBlur(e, setFieldValue, setFieldTouched)}
                        onChange={(e) => handleChangePhone(e, setFieldValue)}
                        isInvalid={errors.tel_localcd}
                      />
                      <Form.Control.Feedback type="invalid" as="small" className="pos-abs">
                        {errors.tel_localcd}
                      </Form.Control.Feedback>
                    </Col>
                    <Col sm="1" className="text-center">
                      -
                    </Col>
                    <Col className="dis-rel">
                      <Form.Control
                        name="tel_number"
                        value={values.tel_number}
                        required
                        type="text"
                        onBlur={(e) => handleBlur(e, setFieldValue, setFieldTouched)}
                        onChange={(e) => handleChangePhone(e, setFieldValue)}
                        isInvalid={errors.tel_number}
                      />
                      <Form.Control.Feedback type="invalid" as="small" className="pos-abs">
                        {errors.tel_number}
                      </Form.Control.Feedback>
                    </Col>
                  </Col>
                  <Col md={{ span: 9, offset: 3 }}>
                    {!errors.tel_areacd && !errors.tel_localcd && !errors.tel_number && (
                      <Form.Text className="text-muted">半角数字で入力してください</Form.Text>
                    )}
                  </Col>
                </Form.Group>
              </div>
            </div>
            <div className="dis-flex flex-center submit-wrapper">
              <button className="save-btn" type="submit" disabled={!IsAuthorize(AUTH_CREATEUPDATE)}>
                登録
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
