import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import DeleteModalButton from '../button/DeleteModalButton';

function ModalDelete(props) {
  const { deleteShowing, deleteThisCheckup, setDeleteShowing, isInfo } = props;
  return (
    <>
      <Modal show={deleteShowing} onHide={() => setDeleteShowing(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{isInfo ? '情報の削除' : '帳票の削除'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isInfo ? '情報を削除します。' : '帳票を削除します。'}
          <br />
          よろしいですか？
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            className="w-100-i has-shadow"
            onClick={() => setDeleteShowing(false)}
          >
            キャンセル
          </Button>
          <Button
            variant="outline-danger"
            className="w-100-i has-shadow border-none"
            onClick={deleteThisCheckup}
          >
            削除
          </Button>
        </Modal.Footer>
      </Modal>
      <DeleteModalButton onClick={() => setDeleteShowing(true)} higher />
    </>
  );
}

export default ModalDelete;

ModalDelete.prototype = {
  deleteShowing: PropTypes.bool,
  deleteThisCheckup: PropTypes.func,
  setDeleteShowing: PropTypes.func,
};
