import { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import InputDropdown from '../../../components/common/form/InputDropdown';
import InputText from '../../../components/common/form/InputText';
import InputDateForm from '../../../components/common/form/InputDateForm';
import axiosInstance from '../../../api/base';
import { AUTH_CREATEUPDATE, DIV_ID_SIZE } from '../../../api/constants';
import { generateDateString } from '../../../utils/convert';
import { setSuccessToast } from '../../../redux/slices/toast';
import { IsAuthorize } from '../../../redux/slices/auth';

const Schema = yup.object().shape({
  lend_item_cd: yup.string().required(' '),
  lend_size: yup.string().required(' '),
  waist_size: yup.string().notRequired(),
  lend_num: yup
    .string()
    .required(' ')
    .trim()
    .matches(/^[0-9]+$/, '半角数字で入力してください'),
  lend_ymd_year: yup.number().required(' '),
  lend_ymd_month: yup.number().required(' '),
  lend_ymd_day: yup.number().required(' '),
  lend_memo: yup.string().notRequired(),
});

export default function UquipmentLoanConfirm(props) {
  const [lendUniform, setLendUniform] = useState({});
  const [companyId, setCompanyId] = useState('');
  const [lendItems, setLendItems] = useState([]);
  const [sizes, setSizes] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const { lendId, id } = props.match.params;
    setCompanyId(props.location.state?.companyCd);

    if (props.match.params.lendId) {
      axiosInstance
        .get(`/employees/${id}/employee_uniform_loan_application_confirm/${lendId}`)
        .then((response) => {
          if (response.data) {
            const lendDate = response.data.lend_ymd?.split('-') || [];
            const returnDate = response.data.return_ymd?.split('-') || [];
            setLendUniform({
              uniform_lend_id: response.data.uniform_lend_id,
              employee_cd: props.match.params.id,
              lend_item_cd: response.data.lend_item_cd,
              lend_size: response.data.lend_size,
              waist_size: response.data.waist_size,
              lend_num: parseInt(response.data.lend_num, 10),
              lend_memo: response.data.lend_memo,
              lend_ymd_year: parseInt(lendDate[0], 10) || '',
              lend_ymd_month: parseInt(lendDate[1], 10) || '',
              lend_ymd_day: parseInt(lendDate[2], 10) || '',
              return_ymd_year: parseInt(returnDate[0], 10) || '',
              return_ymd_month: parseInt(returnDate[1], 10) || '',
              return_ymd_day: parseInt(returnDate[2], 10) || '',
            });
          }
        });
    } else {
      setLendUniform({
        employee_cd: props.match.params.id,
        lend_item_cd: '',
        lend_size: '',
        waist_size: '',
        lend_num: '',
        lend_memo: '',
        lend_ymd_year: '',
        lend_ymd_month: '',
        lend_ymd_day: '',
        return_ymd_year: '',
        return_ymd_month: '',
        return_ymd_day: '',
      });
      setCompanyId(props.location.state?.companyCd);
    }
  }, []);

  useEffect(() => {
    axiosInstance.get(`divs/${DIV_ID_SIZE}`).then((response) => {
      setSizes(response.data.sort((cur, next) => cur.display_order - next.display_order));
    });
  }, []);

  useEffect(() => {
    if (companyId) {
      axiosInstance
        .get(`/companies/${companyId}/item_lends`)
        .then((response) => setLendItems(response.data));
    }
  }, [companyId]);

  const handleSubmitSend = (params) => {
    axiosInstance({
      method: props.match.params.lendId ? 'patch' : 'post',
      url: `/employees/${props.match.params.id}/employee_uniform_loan_application_confirm`,
      data: {
        ...params,
        uniform_lend_id: props.match.params.lendId,
        lend_num: Number(params.lend_num),
        lend_ymd: generateDateString(
          params.lend_ymd_year,
          params.lend_ymd_month,
          params.lend_ymd_day
        ),
        return_ymd: generateDateString(
          params.return_ymd_year,
          params.return_ymd_month,
          params.return_ymd_day
        ),
      },
    }).then((response) => {
      if (response.data) {
        dispatch(setSuccessToast(true));
        props.history.push(`/employee/${props.match.params.id}`);
      }
    });
  };
  return (
    <div className="content w-form">
      <h4 className="bold">帳票情報</h4>
      <Formik
        validationSchema={Schema}
        onSubmit={handleSubmitSend}
        enableReinitialize
        initialValues={lendUniform}
      >
        {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <div className="mt-6 bg-gray2 pa-16 pos-rel">
              <div className="w-90">
                <InputDropdown
                  title="種類"
                  values={values}
                  errors={errors}
                  touched={touched}
                  fieldKey="lend_item_cd"
                  handleChange={handleChange}
                  options={lendItems}
                  optionKey="lend_item_cd"
                  optionName="lend_item_nm"
                />
                <InputDropdown
                  title="サイズ"
                  values={values}
                  errors={errors}
                  touched={touched}
                  fieldKey="lend_size"
                  handleChange={handleChange}
                  options={sizes}
                  optionKey="div_value"
                  optionName="div_value_nm"
                />
                <InputText
                  title="ウエストサイズ"
                  values={values}
                  errors={errors}
                  touched={touched}
                  fieldKey="waist_size"
                  handleChange={handleChange}
                />
                <InputText
                  title="枚数"
                  values={values}
                  errors={errors}
                  touched={touched}
                  fieldKey="lend_num"
                  handleChange={handleChange}
                  helpText="半角数字で入力してください"
                />
                <InputDateForm
                  title="貸与日"
                  titleRequired
                  handleChange={handleChange}
                  valuePrefix="lend_ymd"
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                />
                <InputDateForm
                  title="返却日"
                  titleRequired={false}
                  handleChange={handleChange}
                  valuePrefix="return_ymd"
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                />
              </div>
            </div>
            <div className="dis-flex flex-center submit-wrapper">
              <button className="save-btn" type="submit" disabled={!IsAuthorize(AUTH_CREATEUPDATE)}>
                登録
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
