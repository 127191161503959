import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import axiosInstance from '../../../api/base';
import { DIV_ID_RESIDENCE_CARD } from '../../../api/constants';
import InfoPageHeader from '../../../components/InfoPageHeader';
import FileHandler from '../../../components/FileHandler';
import { computeDate } from '../../../utils/convert';

export default function EmployeeResidenceCardIndex({ history, match }) {
  const [employeeResidenceCard, setEmployeeResidenceCard] = useState({ updatedDate: null });
  const [uploadedImages, setUploadedImages] = useState([
    { relatedDocsYmd: null, relatedDocsId: 0 },
  ]);

  useEffect(() => {
    axiosInstance.get(`/employees/${match.params.id}/employee_residence_card`).then(({ data }) => {
      setEmployeeResidenceCard({
        updatedDate: data && data.update_datetime,
      });
    });

    axiosInstance
      .get(`/employees/${match.params.id}/employee_related_docs/test/div/${DIV_ID_RESIDENCE_CARD}`)
      .then(({ data }) => {
        setUploadedImages(
          [...data].map((item) => ({
            relatedDocsYmd: item.related_docs_ymd,
            relatedDocsId: item.related_docs_id,
          }))
        );
      });

    return () => {
      setEmployeeResidenceCard({});
      setUploadedImages({});
    };
  }, []);

  const editEmployeeResidenceCard = () => {
    history.push({
      pathname: `/employee/${match.params.id}/residenceCard/edit`,
    });
  };

  const gotoPage = (subroute) => {
    history.push({
      pathname: `/employee/${match.params.id}/residenceCard/${subroute}`,
    });
  };

  return (
    <div className="content">
      <Row>
        <Col>
          <InfoPageHeader hideButton />
          <FileHandler
            centerEdit
            className="divider-btm"
            onEditClicked={() => editEmployeeResidenceCard()}
          >
            <span>更新日：</span>
            <span className="text-prim">{computeDate(employeeResidenceCard.updatedDate)}</span>
          </FileHandler>
        </Col>
        <Col>
          <InfoPageHeader onCreate={() => gotoPage('uploadRelatedDocs')} imageHeader />
          {uploadedImages.length >= 1 && (
            <FileHandler
              centerEdit
              imageHandler
              className="divider-btm"
              onEditClicked={() =>
                gotoPage(`editUploadedRelatedDocs/${uploadedImages[0].relatedDocsId}`)
              }
            >
              <span>更新日：</span>
              <span className="text-prim">{computeDate(uploadedImages[0].relatedDocsYmd)}</span>
            </FileHandler>
          )}
        </Col>
      </Row>
      {uploadedImages.length > 1 && (
        <div className="mt-6 bg-gray pa-16">
          <div className="mb-4 text-lght">以前アップロードされた画像</div>
          <div className="divider-btm" />
          {uploadedImages.slice(1).map((image, key) => (
            <FileHandler
              centerEdit
              imageHandler
              grayIcon
              className="divider-btm"
              key={key}
              onEditClicked={() => gotoPage(`editUploadedRelatedDocs/${image.relatedDocsId}`)}
            >
              <span>更新日：</span>
              <span className="text-prim">{computeDate(image.relatedDocsYmd)}</span>
            </FileHandler>
          ))}
        </div>
      )}
      <Row>
        <Col md={{ span: 6, offset: 6 }}>
          {!uploadedImages.length && (
            <div className="no-data-txt-sm sz-16 w-100">該当データなし</div>
          )}
        </Col>
      </Row>
    </div>
  );
}
