import { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import InputDateForm from '../../../components/common/form/InputDateForm';
import axiosInstance from '../../../api/base';
import { generateDateString } from '../../../utils/convert';
import { setSuccessToast } from '../../../redux/slices/toast';
import HeaderForm from '../../../components/HeaderForm';
import { AUTH_CREATEUPDATE, AUTH_DELETE } from '../../../api/constants';
import { IsAuthorize } from '../../../redux/slices/auth';

const Schema = yup.object().shape({
  certificate_issue_ymd_year: yup.number().required(' '),
  certificate_issue_ymd_month: yup.number().required(' '),
  certificate_issue_ymd_day: yup.number().required(' '),
});

export default function OperationRecordEdit(props) {
  const [accidentReport, setReport] = useState({});
  const [deleteShowing, setDeleteShowing] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.match.params.opReportId) {
      axiosInstance
        .get(
          `/employees/${props.match.params.id}/employee_drive_record_certs/${props.match.params.opReportId}`
        )
        .then((response) => {
          const occurDate = response.data.certificate_issue_ymd?.split('-') || [];

          setReport({
            certificate_issue_ymd_year: parseInt(occurDate[0], 10) || '',
            certificate_issue_ymd_month: parseInt(occurDate[1], 10) || '',
            certificate_issue_ymd_day: parseInt(occurDate[2], 10) || '',
          });
        });
    } else {
      setReport({
        certificate_issue_ymd_year: '',
        certificate_issue_ymd_month: '',
        certificate_issue_ymd_day: '',
      });
    }
  }, []);

  const handleSubmitSend = (params) => {
    axiosInstance({
      method: props.match.params.opReportId ? 'patch' : 'post',
      url: `/employees/${props.match.params.id}/employee_drive_record_certs`,
      data: {
        ...params,
        certificate_issue_ymd: generateDateString(
          params.certificate_issue_ymd_year,
          params.certificate_issue_ymd_month,
          params.certificate_issue_ymd_day
        ),
        drive_record_cert_id: props.match.params.opReportId,
      },
    }).then((response) => {
      if (response.data) {
        dispatch(setSuccessToast(true));
        props.history.push(`/employee/${props.match.params.id}/operationRecordCert`);
      }
    });
  };

  const deleteThisCheckup = () => {
    axiosInstance
      .delete(
        `/employees/${props.match.params.id}/employee_drive_record_certs/${props.match.params.opReportId}`
      )
      .then((response) => {
        if (response.data)
          props.history.push(`/employee/${props.match.params.id}/operationRecordCert`);
      });
  };

  return (
    <div className="content w-form">
      <HeaderForm
        deleteShowing={deleteShowing}
        setDeleteShowing={setDeleteShowing}
        deleteThisCheckup={deleteThisCheckup}
        // eslint-disable-next-line react/destructuring-assignment
        haveBtnDelete={props.match.params.opReportId && IsAuthorize(AUTH_DELETE)}
      />
      <Formik
        validationSchema={Schema}
        onSubmit={handleSubmitSend}
        enableReinitialize
        initialValues={accidentReport}
      >
        {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <div className="mt-6 bg-gray2 pa-16 pos-rel">
              <div className="w-90">
                <InputDateForm
                  title="証明書発行日"
                  titleRequired
                  handleChange={handleChange}
                  valuePrefix="certificate_issue_ymd"
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                />
              </div>
            </div>
            <div className="dis-flex flex-center submit-wrapper">
              <button className="save-btn" type="submit" disabled={!IsAuthorize(AUTH_CREATEUPDATE)}>
                登録
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
