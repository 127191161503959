import { PencilFill, FileEarmarkFill, Image } from 'react-bootstrap-icons';

export default function FileHandler(props) {
  const { children, className, grayIcon, onEditClicked, centerEdit, imageHandler, noEdit, style } =
    props;
  return (
    <div style={style} className={`file-handler ${className}`}>
      <div className="file-info text-lght">
        {imageHandler ? (
          <Image color={grayIcon ? '#dadada' : 'burlywood'} size={40} className="mr-t-sm" />
        ) : (
          <FileEarmarkFill
            color={grayIcon ? '#dadada' : 'burlywood'}
            size={40}
            className="mr-t-sm"
          />
        )}
        {imageHandler ? '帳票' : '情報'}
      </div>
      {!noEdit && (
        <div className={`item-edit-file ${(centerEdit || true) && 'item-edit-file-center'}`}>
          <PencilFill size={15} />{' '}
          <div
            onClick={() => onEditClicked()}
            onKeyPress={(e) => {
              if (e.key === 'Enter') onEditClicked();
            }}
            role="button"
            tabIndex="0"
          >
            {' '}
            編集
          </div>
        </div>
      )}
      <div className="mr-l-md w-100">{children}</div>
    </div>
  );
}
