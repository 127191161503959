import './style.scss';

export default function CheckBox(props) {
  const { label, checked, onChange, name, isInvalid = false, disabled = false } = props;
  return (
    <label className={`checkbox ${isInvalid && 'invalid'} ${disabled && 'disabled'}`}>
      <input
        type="checkbox"
        name={name}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
      />
      <span>{label}</span>
    </label>
  );
}
