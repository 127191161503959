import { useState, useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import FileHandler from '../../../components/FileHandler';
import InfoPageHeader from '../../../components/InfoPageHeader';
import axiosInstance from '../../../api/base';
import { computeDate } from '../../../utils/convert';
import { EMPLOYEE_DOCS_IMAGE_ONLY } from '../../../api/constants';
import { RELATED_DOCS } from '../relatedDocsImages/Enum';

// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }
export default function EmployeeRelatedDocInfo(props) {
  const [images, setImages] = useState([]);
  const [isLoading, setLoading] = useState(true);
  // const divId = useQuery().get('divId');
  const [divId, setDivId] = useState(0);

  useEffect(() => {
    const paramDivId = RELATED_DOCS[props.match.params.relatedDocsName].id;
    setDivId(paramDivId);
    axiosInstance
      .get(`/employees/${props.match.params.id}/employee_related_docs/test/div/${paramDivId}`)
      .then((response) => {
        setLoading(false);
        setImages(response.data);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const gotoPage = (subroute) => {
    const mapper = EMPLOYEE_DOCS_IMAGE_ONLY.find((r) => r.divId === divId);
    props.history.push({
      pathname: `/employee/${props.match.params.id}/${mapper.route}/${subroute}`,
    });
  };

  return (
    <div className="content">
      {isLoading && (
        <div className="no-data-txt-sm w-100">
          <Spinner animation="border" role="status" variant="primary" />
        </div>
      )}
      {!isLoading && <InfoPageHeader onCreate={() => gotoPage('uploadRelatedDocs')} imageHeader />}
      {images[0] && (
        <>
          <h5 className="mt-3 mb-3">最新</h5>
          <FileHandler
            centerEdit
            imageHandler
            className="border-slim border-rad-4 bg-sec"
            onEditClicked={() => gotoPage(`editUploadedRelatedDocs/${images[0].related_docs_id}`)}
          >
            <span>登録日：</span>
            <span className="text-prim">{computeDate(images[0].related_docs_ymd)}</span>
          </FileHandler>
        </>
      )}
      {images.length > 0 && (
        <div className="mt-6 bg-gray pa-16">
          <span className="text-lght">以前アップロードされた画像</span>
          <div className="mt-6 divider-btm" />
          {images.length > 1 &&
            images.slice(1).map((image, key) => (
              <FileHandler
                centerEdit
                imageHandler
                grayIcon
                className="divider-btm"
                key={key}
                onEditClicked={() => gotoPage(`editUploadedRelatedDocs/${image.related_docs_id}`)}
              >
                <span>更新日：</span>
                <span className="text-prim">{computeDate(image.related_docs_ymd)}</span>
              </FileHandler>
            ))}
          {images.length <= 1 && !isLoading && (
            <>
              <div className="no-data-txt-sm sz-16 w-100">該当データなし</div>
              <div className="divider-btm" />
            </>
          )}
        </div>
      )}
      {!images.length && !isLoading && (
        <div className="no-data-txt-sm sz-16 w-100">該当データなし</div>
      )}
    </div>
  );
}
