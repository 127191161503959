import { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import FileHandler from '../../../components/FileHandler';
import InfoPageHeader from '../../../components/InfoPageHeader';
import axiosInstance from '../../../api/base';
import { DOCS_DIV_VALUE_HOUSING_INFORMATION } from '../../../api/constants';
import { computeDate } from '../../../utils/convert';

export default function HousingInformation(props) {
  const [housingInfo, setHousingInfo] = useState({});
  const [images, setImages] = useState([]);

  useEffect(() => {
    axiosInstance.get(`/employees/${props.match.params.id}/housingInformation`).then((response) => {
      setHousingInfo(response.data);
    });

    axiosInstance
      .get(
        `/employees/${props.match.params.id}/employee_related_docs/test/div/${DOCS_DIV_VALUE_HOUSING_INFORMATION}`
      )
      .then((response) => {
        setImages(response.data);
      });
  }, []);

  const gotoPage = (subroute) => {
    props.history.push({
      pathname: `/employee/${props.match.params.id}/housing/${subroute}`,
      state: housingInfo,
    });
  };
  return (
    <div className="content">
      <Row>
        <Col>
          <InfoPageHeader hideButton />
          <FileHandler centerEdit className="divider-btm" onEditClicked={() => gotoPage('edit')}>
            <span>更新日：</span>
            <span className="text-prim">{computeDate(housingInfo.addr_info_update_datetime)}</span>
          </FileHandler>
        </Col>
        <Col>
          <InfoPageHeader onCreate={() => gotoPage('uploadRelatedDocs')} imageHeader />
          {images[0] && (
            <FileHandler
              centerEdit
              imageHandler
              className="divider-btm"
              onEditClicked={() => gotoPage(`editUploadedRelatedDocs/${images[0].related_docs_id}`)}
            >
              <span>更新日：</span>
              <span className="text-prim">{computeDate(images[0].related_docs_ymd)}</span>
            </FileHandler>
          )}
          {!images.length && <div className="no-data-txt-sm sz-16 w-100">該当データなし</div>}
        </Col>
      </Row>
      {images.length > 1 && (
        <div className="mt-6 bg-gray pa-16">
          <div className="mb-4 text-lght">以前アップロードされた画像</div>
          <div className="divider-btm" />
          {images.slice(1).map((image, key) => (
            <FileHandler
              centerEdit
              imageHandler
              grayIcon
              className="divider-btm"
              key={key}
              onEditClicked={() => gotoPage(`editUploadedRelatedDocs/${image.related_docs_id}`)}
            >
              <span>更新日：</span>
              <span className="text-prim">{computeDate(image.related_docs_ymd)}</span>
            </FileHandler>
          ))}
          {images.length === 1 && <div className="no-data-txt-sm sz-16 w-100">該当データなし</div>}
        </div>
      )}
    </div>
  );
}
