import { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { PencilFill, ChevronContract, ChevronExpand } from 'react-bootstrap-icons';
import AddRecordButton from './common/button/AddRecordButton';

export default function ItemWrapper(props) {
  const [isExpanded, toggleExpand] = useState(false);
  const {
    title,
    children,
    onEditClicked,
    id,
    isAddButton,
    activeDropdown,
    firstItemClicked,
    secondItemClicked,
  } = props;
  return (
    <div className="item-wrapper" id={id}>
      <div className="item-title">
        <h5>{title}</h5>
        <div className="item-btn-group">
          <>
            {activeDropdown && (
              <Dropdown>
                <Dropdown.Toggle bsPrefix="p-0" childBsPrefix="none-btn">
                  <AddRecordButton />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="1" onClick={firstItemClicked}>
                    運転免許証
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item eventKey="2" onClick={secondItemClicked}>
                    資格情報
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
            {isAddButton && (
              <AddRecordButton
                onClick={onEditClicked}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') onEditClicked();
                }}
              />
            )}
            {!isAddButton && !activeDropdown && (
              <>
                <PencilFill size={15} />
                <div
                  onClick={() => onEditClicked()}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') onEditClicked();
                  }}
                  role="button"
                  tabIndex="0"
                >
                  {' '}
                  編集
                </div>
              </>
            )}
            <span>|</span>
          </>
          <div
            onClick={() => toggleExpand(!isExpanded)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') toggleExpand(!isExpanded);
            }}
            role="button"
            tabIndex="0"
          >
            {isExpanded ? '閉じる' : '開く'}{' '}
            {isExpanded ? <ChevronContract size={15} /> : <ChevronExpand size={15} />}
          </div>
        </div>
      </div>
      {isExpanded && children}
    </div>
  );
}
