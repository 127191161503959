import React from 'react';
import { ArrowLeft, ArrowRight } from 'react-bootstrap-icons';

export default function Pagination({
  pageIndex,
  total,
  gotoPage,
  previousPage,
  nextPage,
  canPreviousPage,
  canNextPage,
}) {
  const limitNo = 7;
  const siblingCount = (limitNo - 1) / 2;

  return (
    <div className="dis-flex pagin-container">
      <button type="button" onClick={previousPage} disabled={!canPreviousPage}>
        <ArrowLeft />
      </button>
      {pageIndex > siblingCount && (
        <div>
          <button type="button" onClick={() => gotoPage(0)} className={pageIndex === 0 && 'active'}>
            {1}
          </button>
          {pageIndex <= siblingCount + 1 || '....'}
        </div>
      )}
      {[...Array(limitNo).keys()].map((key) => {
        const isFirstSibling = pageIndex + 1 > siblingCount;
        key = !isFirstSibling ? key : key + pageIndex - siblingCount;
        return (
          key + 1 <= total && (
            <button
              type="button"
              className={pageIndex === key && 'active'}
              key={key}
              onClick={() => gotoPage(key)}
            >
              {key + 1}
            </button>
          )
        );
      })}
      {pageIndex + 1 + siblingCount < total && (
        <div>
          {pageIndex + 1 + siblingCount <= total + 1 && '...'}
          <button
            type="button"
            onClick={() => gotoPage(total - 1)}
            className={pageIndex === total && 'active'}
          >
            {total}
          </button>
        </div>
      )}
      <button type="button" onClick={nextPage} disabled={!canNextPage}>
        <ArrowRight />
      </button>
    </div>
  );
}
