import { createSlice } from '@reduxjs/toolkit';

export const toastSlide = createSlice({
  name: 'toast',
  initialState: {
    isShowSuccess: false,
  },
  reducers: {
    setSuccessToast: (state, action) => {
      state.isShowSuccess = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSuccessToast } = toastSlide.actions;

// Get value from state
export const getSuccess = (state) => state.toast;

export default toastSlide.reducer;
