import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axiosInstance from '../../../api/base';
import RelatedDocsImagesList from './List';
import RelatedDocsImagesSelect from './Select';
import { RELATED_DOCS } from './Enum';
import { clearImages, clearSequence } from '../../../redux/slices/relatedDocs';

export default function RelatedDocsImagesUpload({ history, match }) {
  const uploadedImages = useSelector((state) => state.relatedDocs.uploadedImages);
  const dispatch = useDispatch();
  const [selectedFiles, setSelectedFiles] = useState([]);

  const addFile = (selectedFile) => {
    setSelectedFiles([...selectedFiles, selectedFile]);
  };

  const deleteFile = (deleteIndex) => {
    setSelectedFiles([...selectedFiles.filter((_, index) => index !== deleteIndex)]);
  };

  const onUpload = async () => {
    const params = new URLSearchParams({
      related_docs_div: RELATED_DOCS[match.params.relatedDocsName].id,
      related_docs_ymd: moment().format('YYYY-MM-DD'),
    }).toString();

    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append('files', file);
    });

    const { status } = await axiosInstance.post(
      `/employees/${match.params.id}/employee_related_docs/test?${params}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    if (status === 200) {
      dispatch(clearImages());
      history.push(`/employee/${match.params.id}/${match.params.relatedDocsName}`);
    }
  };

  useEffect(() => {
    dispatch(clearImages());
    dispatch(clearSequence());
  }, []);

  return (
    <div className="content">
      <RelatedDocsImagesList listType="upload" deleteFile={deleteFile} />

      <RelatedDocsImagesSelect addFile={addFile} />

      <div className="text-center">
        <button
          type="button"
          className="btn-register"
          disabled={uploadedImages.length === 0}
          onClick={onUpload}
        >
          登録
        </button>
      </div>
    </div>
  );
}
