import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import axiosInstance from '../../../api/base';
import { AUTH_CREATEUPDATE, AUTH_DELETE } from '../../../api/constants';
import InputDateForm from '../../../components/common/form/InputDateForm';
import HeaderForm from '../../../components/HeaderForm';
import { IsAuthorize } from '../../../redux/slices/auth';
import { setSuccessToast } from '../../../redux/slices/toast';
import { generateDateString } from '../../../utils/convert';

export default function EmployeeResidenceCardEdit({ history, match }) {
  const [employeeResidenceCard, setEmployeeResidenceCard] = useState({
    expiration_date: '',
  });
  const [deleteShowing, setDeleteShowing] = useState(false);

  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    expiration_date_year: Yup.string().required(' '),
    expiration_date_month: Yup.string().required(' '),
    expiration_date_day: Yup.string().required(' '),
  });

  const handleSubmitSend = (params) => {
    axiosInstance
      .post(`/employees/${match.params.id}/employee_residence_card`, {
        expiration_date: generateDateString(
          params.expiration_date_year,
          params.expiration_date_month,
          params.expiration_date_day
        ),
      })
      .then(({ status }) => {
        if (status === 200) {
          dispatch(setSuccessToast(true));
          history.push(`/employee/${match.params.id}/residenceCard`);
        }
      });
  };

  const deleteThisCheckup = () => {
    axiosInstance
      .delete(`/employees/${match.params.id}/employee_residence_card`)
      .then((response) => {
        if (response.data) history.push(`/employee/${match.params.id}/residenceCard`);
      });
  };

  useEffect(() => {
    axiosInstance
      .get(`/employees/${match.params.id}/employee_residence_card`)
      .then(({ data, status }) => {
        if (status === 200) {
          const expirationDate = data.expiration_date?.split('-') || [];
          setEmployeeResidenceCard({
            expiration_date_year: parseInt(expirationDate[0], 10) || '',
            expiration_date_month: parseInt(expirationDate[1], 10) || '',
            expiration_date_day: parseInt(expirationDate[2], 10) || '',
          });
        }
      });
  }, []);

  return (
    <div className="content w-form">
      <HeaderForm
        deleteShowing={deleteShowing}
        setDeleteShowing={setDeleteShowing}
        deleteThisCheckup={deleteThisCheckup}
        haveBtnDelete={IsAuthorize(AUTH_DELETE)}
      />
      <Formik
        initialValues={employeeResidenceCard}
        validationSchema={validationSchema}
        onSubmit={handleSubmitSend}
        enableReinitialize
      >
        {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <div className="mt-6 bg-gray2 pa-16 px-10 pos-rel">
              <div className="w-90">
                <InputDateForm
                  title="有効期限"
                  titleRequired
                  handleChange={handleChange}
                  valuePrefix="expiration_date"
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                />
              </div>
            </div>
            <div className="dis-flex flex-center submit-wrapper">
              <button className="save-btn" type="submit" disabled={!IsAuthorize(AUTH_CREATEUPDATE)}>
                登録
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
