/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { FieldArray, Formik } from 'formik';
import { Col, Form, Row, Button } from 'react-bootstrap';
import { ArrowClockwise, Search } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import axiosInstance, { fetchPostalCode } from '../../../api/base';
import HeaderForm from '../../../components/HeaderForm';
import iconMinus from '../../../assets/images/icon-minus.svg';
import {
  monthOptionList,
  getDayOptionListInMonth,
  listOptionBirthdayYear,
} from '../../../utils/date';
import {
  DIV_ID_EMG_CONTACT_RELATIONSHIP,
  FMLY_ADDR_TOGETHER_DIV_SAME,
  FMLY_ADDR_TOGETHER_DIV_DIFF,
  AUTH_DELETE,
  AUTH_CREATEUPDATE,
} from '../../../api/constants';
import { generateDateString } from '../../../utils/convert';
import ChangeRecordButton from '../../../components/common/button/AddRecordButton';
import { setSuccessToast } from '../../../redux/slices/toast';
import { IsAuthorize } from '../../../redux/slices/auth';

Yup.addMethod(Yup.string, 'requireFullName', function validate(errorMessage) {
  return this.test(`requireFullName`, errorMessage, function validate2(value) {
    const { path, createError } = this;
    return (
      (value && (value.includes(' ') || value.includes('　'))) ||
      createError({ path, message: errorMessage })
    );
  });
});

const Schema = Yup.object().shape({
  members: Yup.array().of(
    Yup.object().shape({
      fmly_last_name: Yup.string().required(' '),
      fmly_first_name: Yup.string().required(' '),
      fmly_last_name_kn: Yup.string().required(' '),
      fmly_first_name_kn: Yup.string().required(' '),
      fmly_rel_div: Yup.number().required(' '),
      fmly_birthday_year: Yup.string().required(' '),
      fmly_birthday_month: Yup.string().required(' '),
      fmly_birthday_day: Yup.string().required(' '),
      fmly_addr_together_div: Yup.number().required(' '),
      fmly_postal_code_1: Yup.string()
        .nullable()
        .trim()
        .matches(/^[0-9]+$/, '半角数字で入力してください')
        .length(3, '数字3文字で入力してください'),
      fmly_postal_code_2: Yup.string()
        .nullable()
        .trim()
        .matches(/^[0-9]+$/, '半角数字で入力してください')
        .length(4, '数字4文字で入力してください'),
      fmly_addr: Yup.string().nullable(),
      fmly_addr_kn: Yup.string().nullable(),
    })
  ),
});

export default function Edit(props) {
  const [members, setMembers] = useState([]);
  const [relationOL, setRelationOL] = useState([]); // OL = Option List
  const [deleteShowing, setDeleteShowing] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const handleSubmitFamily = async (values) => {
    const params = values.members.map((member) => ({
      ...member,
      fmly_birthday: generateDateString(
        member.fmly_birthday_year,
        member.fmly_birthday_month,
        member.fmly_birthday_day
      ),
      fmly_dpnds_flag: member.fmly_dpnds_flag ? 1 : 0,
      fmly_postal_code_1:
        member.fmly_addr_together_div === FMLY_ADDR_TOGETHER_DIV_DIFF
          ? member.fmly_postal_code_1
          : '',
      fmly_postal_code_2:
        member.fmly_addr_together_div === FMLY_ADDR_TOGETHER_DIV_DIFF
          ? member.fmly_postal_code_2
          : '',
      fmly_addr:
        member.fmly_addr_together_div === FMLY_ADDR_TOGETHER_DIV_DIFF ? member.fmly_addr : '',
      fmly_addr_kn:
        member.fmly_addr_together_div === FMLY_ADDR_TOGETHER_DIV_DIFF ? member.fmly_addr_kn : '',
    }));

    await axiosInstance
      .post(`/employees/${props.match.params.id}/employee_families`, params)
      .then((res) => {
        dispatch(setSuccessToast(true));
      })
      .catch((e) => console.log(e));
    setIsLoading(false);
    props.history.push(`/employee/${props.match.params.id}/family`);
  };

  useEffect(() => {
    setIsLoading(true);
    const init = async () => {
      axiosInstance.get(`/employees/${props.match.params.id}/employee_families`).then((res) => {
        setMembers(
          res.data.map((member) => {
            const fmlyBirthday = member.fmly_birthday?.split('-') || [];
            return {
              ...member,
              fmly_birthday_year: parseInt(fmlyBirthday[0], 10) || '',
              fmly_birthday_month: parseInt(fmlyBirthday[1], 10) || '',
              fmly_birthday_day: parseInt(fmlyBirthday[2], 10) || '',
              errorPostalService: '',
            };
          })
        );
        if (res.data.length === 0) setMembers([{}]);
      });

      axiosInstance.get(`divs/${DIV_ID_EMG_CONTACT_RELATIONSHIP}`).then((res) => {
        setRelationOL(res.data.sort((cur, next) => cur.display_order - next.display_order));
      });
      setIsLoading(false);
    };
    init();
  }, []);

  const changeFieldByIndex = (array, index, key, value) => {
    const newArray = [...array];
    newArray[index][key] = value;
    return newArray;
  };

  const resetValue = (setFieldValue, valuePrefix, values, index) => {
    const yearKey = `${valuePrefix}_year`;
    const monthKey = `${valuePrefix}_month`;
    const dayKey = `${valuePrefix}_day`;
    setTimeout(() => {
      setFieldValue('members', changeFieldByIndex(values.members, index, yearKey, ''));
      setFieldValue('members', changeFieldByIndex(values.members, index, dayKey, ''));
      setFieldValue('members', changeFieldByIndex(values.members, index, monthKey, ''));
    }, 0);
  };

  const deleteThisFamily = () => {
    axiosInstance
      .delete(`/employees/${props.match.params.id}/employee_families`)
      .then((response) => {
        if (response.data) {
          dispatch(setSuccessToast(true));
          props.history.push(`/employee/${props.match.params.id}/family`);
        }
      });
  };

  const getError = (touched, errors, index, field) =>
    touched.members &&
    touched.members[index] &&
    errors.members &&
    errors.members[index] &&
    errors.members[index][field];

  const seachPostalCode = (setFieldValue, valuesmembers, index) => {
    const postalCode = `${valuesmembers[index].fmly_postal_code_1}-${valuesmembers[index].fmly_postal_code_2}`;
    fetchPostalCode(postalCode).then((response) => {
      if (response.results) {
        setFieldValue(
          'members',
          changeFieldByIndex(
            valuesmembers,
            index,
            'fmly_addr',
            response.results[0].address1 +
              response.results[0].address2 +
              response.results[0].address3
          )
        );
      } else
        setFieldValue(
          'members',
          changeFieldByIndex(
            valuesmembers,
            index,
            'errorPostalService',
            '現在住所検索サービスが利用できません。住所をご入力ください。'
          )
        );
    });
  };

  return (
    <div className="content w-form">
      <HeaderForm
        deleteShowing={deleteShowing}
        setDeleteShowing={setDeleteShowing}
        deleteThisCheckup={deleteThisFamily}
        haveBtnDelete={IsAuthorize(AUTH_DELETE)}
      />
      <Formik
        validationSchema={Schema}
        enableReinitialize
        initialValues={{ members }}
        onSubmit={handleSubmitFamily}
      >
        {({ handleSubmit, setFieldValue, values, touched, errors, handleChange }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <div className="mt-6 bg-gray2 pa-16 px-10 pos-rel">
              <FieldArray
                name="members"
                render={(arrayHelpers) => (
                  <>
                    {values.members && values.members.length > 0
                      ? values.members.map((member, index) => (
                          <div className="family-member pos-rel" key={index}>
                            <b>家族{index + 1}</b>

                            <Form.Group as={Row}>
                              <Form.Label column sm="3" className="pl-10 required">
                                氏名
                              </Form.Label>
                              <Col sm="4" className="pos-rel">
                                <Form.Control
                                  value={values.members[index].fmly_last_name}
                                  name={`members[${index}].fmly_last_name`}
                                  onChange={handleChange}
                                  required
                                  type="text"
                                  placeholder="入力してください"
                                  isInvalid={getError(touched, errors, index, 'fmly_last_name')}
                                />
                                <Form.Control.Feedback
                                  type="invalid"
                                  as="small"
                                  className="pos-abs"
                                >
                                  {getError(touched, errors, index, 'fmly_last_name')}
                                </Form.Control.Feedback>
                              </Col>
                              <Col sm="4" className="pos-rel">
                                <Form.Control
                                  value={values.members[index].fmly_first_name}
                                  name={`members[${index}].fmly_first_name`}
                                  onChange={handleChange}
                                  required
                                  type="text"
                                  placeholder="入力してください"
                                  isInvalid={getError(touched, errors, index, 'fmly_first_name')}
                                />
                                <Form.Control.Feedback
                                  type="invalid"
                                  as="small"
                                  className="pos-abs"
                                >
                                  {getError(touched, errors, index, 'fmly_first_name')}
                                </Form.Control.Feedback>
                              </Col>
                            </Form.Group>

                            <Form.Group as={Row}>
                              <Form.Label column sm="3" className="pl-10 required">
                                ふりがな
                              </Form.Label>
                              <Col sm="4" className="pos-rel">
                                <Form.Control
                                  value={values.members[index].fmly_last_name_kn}
                                  name={`members[${index}].fmly_last_name_kn`}
                                  onChange={handleChange}
                                  required
                                  type="text"
                                  placeholder="入力してください"
                                  isInvalid={getError(touched, errors, index, 'fmly_last_name_kn')}
                                />
                                <Form.Control.Feedback
                                  type="invalid"
                                  as="small"
                                  className="pos-abs"
                                >
                                  {getError(touched, errors, index, 'fmly_last_name_kn')}
                                </Form.Control.Feedback>
                              </Col>
                              <Col sm="4" className="pos-rel">
                                <Form.Control
                                  value={values.members[index].fmly_first_name_kn}
                                  name={`members[${index}].fmly_first_name_kn`}
                                  onChange={handleChange}
                                  required
                                  type="text"
                                  placeholder="入力してください"
                                  isInvalid={getError(touched, errors, index, 'fmly_first_name_kn')}
                                />
                                <Form.Control.Feedback
                                  type="invalid"
                                  as="small"
                                  className="pos-abs"
                                >
                                  {getError(touched, errors, index, 'fmly_first_name_kn')}
                                </Form.Control.Feedback>
                              </Col>
                            </Form.Group>

                            <Form.Group as={Row}>
                              <Form.Label column sm="3" className="pl-10 required">
                                続柄
                              </Form.Label>
                              <Col sm="8" className="pos-rel">
                                <Form.Control
                                  as="select"
                                  value={values.members[index].fmly_rel_div}
                                  name={`members[${index}].fmly_rel_div`}
                                  type="number"
                                  onChange={handleChange}
                                  required
                                  isInvalid={getError(touched, errors, index, 'fmly_rel_div')}
                                >
                                  <option value="" disabled hidden>
                                    選択してください
                                  </option>
                                  {relationOL.map((item) => (
                                    <option key={item.div_value} value={item.div_value}>
                                      {item.div_value_nm}
                                    </option>
                                  ))}
                                </Form.Control>
                                <Form.Control.Feedback
                                  type="invalid"
                                  as="small"
                                  className="pos-abs"
                                >
                                  {getError(touched, errors, index, 'fmly_rel_div')}
                                </Form.Control.Feedback>
                              </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-8 position-relative">
                              <ArrowClockwise
                                onClick={() =>
                                  resetValue(setFieldValue, 'fmly_birthday', values, index)
                                }
                                className="position-absolute top-0"
                                style={{ top: '12px', right: '45px', cursor: 'pointer' }}
                              />
                              <Form.Label column sm="3" className="pl-10 required">
                                生年月日
                              </Form.Label>
                              <Col sm="4">
                                <Form.Control
                                  as="select"
                                  value={values.members[index].fmly_birthday_year || ''}
                                  name={`members[${index}].fmly_birthday_year`}
                                  onChange={handleChange}
                                  required
                                  isInvalid={getError(touched, errors, index, 'fmly_birthday_year')}
                                >
                                  <option value="" disabled hidden>
                                    -
                                  </option>
                                  {listOptionBirthdayYear.map((item) => (
                                    <option key={item.value} value={item.value}>
                                      {item.label}
                                    </option>
                                  ))}
                                </Form.Control>
                                <Form.Control.Feedback
                                  type="invalid"
                                  as="small"
                                  className="pos-abs"
                                >
                                  {getError(touched, errors, index, 'fmly_birthday_year')}
                                </Form.Control.Feedback>
                              </Col>
                              <Col sm="2">
                                <div className="pos-rel">
                                  <Form.Control
                                    as="select"
                                    required
                                    value={values.members[index].fmly_birthday_month || ''}
                                    name={`members[${index}].fmly_birthday_month`}
                                    onChange={handleChange}
                                    isInvalid={getError(
                                      touched,
                                      errors,
                                      index,
                                      'fmly_birthday_month'
                                    )}
                                  >
                                    <option value="" disabled hidden>
                                      -
                                    </option>
                                    {monthOptionList.map((item) => (
                                      <option key={item.value} value={item.value}>
                                        {item.label}
                                      </option>
                                    ))}
                                  </Form.Control>
                                  {!getError(touched, errors, index, 'fmly_birthday_month') && (
                                    <div className="time-unit dropdown-unit pos-abs">月</div>
                                  )}
                                  <Form.Control.Feedback
                                    type="invalid"
                                    as="small"
                                    className="pos-abs"
                                  >
                                    {getError(touched, errors, index, 'fmly_birthday_month')}
                                  </Form.Control.Feedback>
                                </div>
                              </Col>
                              <Col sm="2">
                                <div className="pos-rel">
                                  <Form.Control
                                    as="select"
                                    value={values.members[index].fmly_birthday_day || ''}
                                    name={`members[${index}].fmly_birthday_day`}
                                    onChange={handleChange}
                                    required
                                    type="text"
                                    isInvalid={getError(
                                      touched,
                                      errors,
                                      index,
                                      'fmly_birthday_day'
                                    )}
                                  >
                                    <option value="" disabled hidden>
                                      -
                                    </option>
                                    {getDayOptionListInMonth(
                                      values.members[index].fmly_birthday_month,
                                      values.members[index].fmly_birthday_year
                                    ).map((item) => (
                                      <option key={item.value} value={item.value}>
                                        {item.label}
                                      </option>
                                    ))}
                                  </Form.Control>
                                  {!getError(touched, errors, index, 'fmly_birthday_day') && (
                                    <div className="time-unit dropdown-unit pos-abs">日</div>
                                  )}
                                  <Form.Control.Feedback
                                    type="invalid"
                                    as="small"
                                    className="pos-abs"
                                  >
                                    {getError(touched, errors, index, 'fmly_birthday_day')}
                                  </Form.Control.Feedback>
                                </div>
                              </Col>
                            </Form.Group>

                            <Form.Group as={Row}>
                              <Form.Label column sm="3" className="pl-10 required">
                                扶養対象
                              </Form.Label>
                              <Col sm="8" className="pos-rel">
                                <Row>
                                  <Col className="text-left">
                                    <Form.Check
                                      inline
                                      required
                                      label="扶養する"
                                      type="radio"
                                      id={`dpnds-true-${index}`}
                                      checked={values.members[index].fmly_dpnds_flag}
                                      onChange={(e) =>
                                        setFieldValue(
                                          'members',
                                          changeFieldByIndex(
                                            values.members,
                                            index,
                                            'fmly_dpnds_flag',
                                            1
                                          )
                                        )
                                      }
                                    />
                                  </Col>
                                  <Col className="text-left">
                                    <Form.Check
                                      inline
                                      required
                                      label="扶養しない"
                                      type="radio"
                                      id={`dpnds-false-${index}`}
                                      checked={!values.members[index].fmly_dpnds_flag}
                                      onChange={(e) =>
                                        setFieldValue(
                                          'members',
                                          changeFieldByIndex(
                                            values.members,
                                            index,
                                            'fmly_dpnds_flag',
                                            0
                                          )
                                        )
                                      }
                                    />
                                  </Col>
                                </Row>
                                <Form.Control.Feedback
                                  type="invalid"
                                  as="small"
                                  className="pos-abs"
                                  style={{
                                    display: getError(touched, errors, index, 'fmly_dpnds_flag')
                                      ? 'block'
                                      : 'none',
                                  }}
                                >
                                  {getError(touched, errors, index, 'fmly_dpnds_flag')}
                                </Form.Control.Feedback>
                              </Col>
                            </Form.Group>

                            <Form.Group as={Row}>
                              <Form.Label column sm="3" className="pl-10 required">
                                居住状況
                              </Form.Label>
                              <Col sm="8" className="pos-rel">
                                <Row>
                                  <Col className="text-left">
                                    <Form.Check
                                      inline
                                      required
                                      label="同居"
                                      type="radio"
                                      id={`inline-radio-true-${index}`}
                                      checked={
                                        values.members[index].fmly_addr_together_div ===
                                        FMLY_ADDR_TOGETHER_DIV_SAME
                                      }
                                      onChange={(e) =>
                                        setFieldValue(
                                          'members',
                                          changeFieldByIndex(
                                            values.members,
                                            index,
                                            'fmly_addr_together_div',
                                            0
                                          )
                                        )
                                      }
                                    />
                                  </Col>
                                  <Col className="text-left">
                                    <Form.Check
                                      inline
                                      required
                                      label="別居"
                                      type="radio"
                                      id={`inline-radio-false-${index}`}
                                      checked={
                                        values.members[index].fmly_addr_together_div ===
                                        FMLY_ADDR_TOGETHER_DIV_DIFF
                                      }
                                      onChange={(e) =>
                                        setFieldValue(
                                          'members',
                                          changeFieldByIndex(
                                            values.members,
                                            index,
                                            'fmly_addr_together_div',
                                            1
                                          )
                                        )
                                      }
                                    />
                                  </Col>
                                </Row>

                                <Form.Control.Feedback
                                  type="invalid"
                                  as="small"
                                  className="pos-abs"
                                  style={{
                                    display: getError(
                                      touched,
                                      errors,
                                      index,
                                      'fmly_addr_together_div'
                                    )
                                      ? 'block'
                                      : 'none',
                                  }}
                                >
                                  {getError(touched, errors, index, 'fmly_addr_together_div')}
                                </Form.Control.Feedback>
                              </Col>
                            </Form.Group>
                            {values.members[index].fmly_addr_together_div ? (
                              <>
                                <Form.Group as={Row} className="mb-8">
                                  <Form.Label column sm="3" className="pl-10">
                                    郵便番号
                                  </Form.Label>
                                  <Col as={Row} className="item-center">
                                    <Col className="pos-rel">
                                      <Form.Control
                                        name={`members[${index}].fmly_postal_code_1`}
                                        value={values.members[index].fmly_postal_code_1}
                                        onChange={handleChange}
                                        required
                                        type="text"
                                        isInvalid={getError(
                                          touched,
                                          errors,
                                          index,
                                          'fmly_postal_code_1'
                                        )}
                                      />
                                      <Form.Control.Feedback
                                        type="invalid"
                                        as="small"
                                        className="pos-abs"
                                      >
                                        {getError(touched, errors, index, 'fmly_postal_code_1')}
                                      </Form.Control.Feedback>
                                    </Col>
                                    <Col sm="1" className="text-center">
                                      -
                                    </Col>
                                    <Col className="pos-rel">
                                      <Form.Control
                                        name={`members[${index}].fmly_postal_code_2`}
                                        value={values.members[index].fmly_postal_code_2}
                                        onChange={handleChange}
                                        required
                                        type="text"
                                        isInvalid={getError(
                                          touched,
                                          errors,
                                          index,
                                          'fmly_postal_code_2'
                                        )}
                                      />
                                      <Form.Control.Feedback
                                        type="invalid"
                                        as="small"
                                        className="pos-abs"
                                      >
                                        {getError(touched, errors, index, 'fmly_postal_code_2')}
                                      </Form.Control.Feedback>
                                    </Col>
                                    <Col>
                                      <Button
                                        variant="link"
                                        className="bold text-prim"
                                        disabled={
                                          getError(touched, errors, index, 'fmly_postal_code_1') ||
                                          getError(touched, errors, index, 'fmly_postal_code_2')
                                        }
                                        onClick={() =>
                                          seachPostalCode(setFieldValue, values.members, index)
                                        }
                                      >
                                        <Search size={18} className="mr-r-md" />
                                        自動入力
                                      </Button>
                                    </Col>
                                  </Col>
                                  <Col
                                    md={{ span: 9, offset: 3 }}
                                    className={`${
                                      !getError(touched, errors, index, 'fmly_postal_code_1') &&
                                      !getError(touched, errors, index, 'fmly_postal_code_2') &&
                                      values.members[index].errorPostalService &&
                                      'mb-8'
                                    }`}
                                  >
                                    {!getError(touched, errors, index, 'fmly_postal_code_1') &&
                                      !getError(touched, errors, index, 'fmly_postal_code_2') &&
                                      values.members[index].errorPostalService && (
                                        <Form.Text className="text-danger">
                                          {values.members[index].errorPostalService}
                                        </Form.Text>
                                      )}
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                  <Form.Label column sm="3" className="pl-10">
                                    住所
                                  </Form.Label>
                                  <Col sm="8" className="pos-rel">
                                    <Form.Control
                                      value={values.members[index].fmly_addr}
                                      name={`members[${index}].fmly_addr`}
                                      onChange={handleChange}
                                      required
                                      type="text"
                                      placeholder="入力してください"
                                      isInvalid={getError(touched, errors, index, 'fmly_addr')}
                                    />
                                    <Form.Control.Feedback
                                      type="invalid"
                                      as="small"
                                      className="pos-abs"
                                    >
                                      {getError(touched, errors, index, 'fmly_addr')}
                                    </Form.Control.Feedback>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                  <Form.Label column sm="3" className="pl-10">
                                    住所カナ
                                  </Form.Label>
                                  <Col sm="8" className="pos-rel">
                                    <Form.Control
                                      value={values.members[index].fmly_addr_kn}
                                      name={`members[${index}].fmly_addr_kn`}
                                      onChange={handleChange}
                                      required
                                      type="text"
                                      placeholder="入力してください"
                                      isInvalid={getError(touched, errors, index, 'fmly_addr_kn')}
                                    />
                                    <Form.Control.Feedback
                                      type="invalid"
                                      as="small"
                                      className="pos-abs"
                                    >
                                      {getError(touched, errors, index, 'fmly_addr_kn')}
                                    </Form.Control.Feedback>
                                  </Col>
                                </Form.Group>
                              </>
                            ) : null}
                            {values.members.length > 1 && (
                              <button
                                className="belong-dismiss pos-abs"
                                type="button"
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <img alt="minus" src={iconMinus} />
                              </button>
                            )}
                          </div>
                        ))
                      : null}
                    <div className="dis-flex flex-row-reverse">
                      <ChangeRecordButton
                        onClick={() => {
                          arrayHelpers.push({
                            fmly_last_name: '',
                            fmly_first_name: '',
                            fmly_last_name_kn: '',
                            fmly_first_name_kn: '',
                            fmly_rel_div: '',
                            fmly_birthday_year: '',
                            fmly_birthday_month: '',
                            fmly_birthday_day: '',
                            fmly_dpnds_flag: '',
                            fmly_addr_together_div: '',
                          });
                        }}
                        text="家族を追加する"
                      />
                    </div>
                  </>
                )}
              />
            </div>
            <Form.Group className="group-btn submit-wrapper">
              <button className="save-btn" type="submit" disabled={!IsAuthorize(AUTH_CREATEUPDATE)}>
                登録
              </button>
            </Form.Group>
          </Form>
        )}
      </Formik>
    </div>
  );
}
