import AddRecordButton from './common/button/AddRecordButton';
import { IsAuthorize } from '../redux/slices/auth';
import { AUTH_CREATEUPDATE } from '../api/constants';

export default function InfoPageHeader(props) {
  const { onCreate, hideButton, imageHeader } = props;
  return (
    <>
      <div className="dis-flex space-btwn">
        <h3>{imageHeader ? '帳票' : '情報'}</h3>
        {!hideButton && IsAuthorize(AUTH_CREATEUPDATE) && (
          <AddRecordButton onClick={onCreate} imageRecord={imageHeader} />
        )}
      </div>
      <div className="mt-6 divider-btm" />
    </>
  );
}
