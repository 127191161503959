import { Dropdown } from 'react-bootstrap';
import { PrinterFill, Download } from 'react-bootstrap-icons';

export default function PrintButton({
  firstItemClicked,
  secondItemClicked,
  thirdItemClicked,
  children,
}) {
  return (
    <Dropdown>
      <Dropdown.Toggle variant="button" bsPrefix="p-0" childBsPrefix="print-btn">
        <PrinterFill color="black" size={21} className="mr-r-md" />
        <span>書き出し</span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item eventKey="1" onClick={firstItemClicked}>
          <Download color="black" size={18} className="mr-r-md" />
          運転者台帳
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item eventKey="2" onClick={secondItemClicked}>
          <Download color="black" size={18} className="mr-r-md" />
          労働者名簿
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item eventKey="3" onClick={thirdItemClicked}>
          <Download color="black" size={18} className="mr-r-md" />
          社労士用ファイル
        </Dropdown.Item>
      </Dropdown.Menu>
      {children}
    </Dropdown>
  );
}
