import { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import axiosInstance from '../../../api/base';
import InputDateForm from '../../../components/common/form/InputDateForm';
import HeaderForm from '../../../components/HeaderForm';
import { generateDateString } from '../../../utils/convert';
import { setSuccessToast } from '../../../redux/slices/toast';
import { AUTH_CREATEUPDATE, AUTH_DELETE } from '../../../api/constants';
import { IsAuthorize } from '../../../redux/slices/auth';

const Schema = yup.object().shape({
  employment_start_ymd_year: yup.number().required('  '),
  employment_start_ymd_month: yup.number().required('  '),
  employment_start_ymd_day: yup.number().required('  '),
});

export default function WorkConditionEdit(props) {
  const [workCondition, setWorkCondition] = useState({
    employment_start_ymd: '',
    employment_end_ymd: '',
  });
  const [deleteShowing, setDeleteShowing] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!props.location.state) {
      axiosInstance.get(`/employees/${props.match.params.id}`).then((response) => {
        const consultPlanDate = response.data.employee_info.employment_start_ymd?.split('-') || [];
        const consultDate = response.data.employee_info.employment_end_ymd?.split('-') || [];

        setWorkCondition({
          employment_start_ymd: response.data.employee_info.employment_start_ymd,
          employment_end_ymd: response.data.employee_info.employment_end_ymd,
          employment_start_ymd_year: parseInt(consultPlanDate[0], 10) || '',
          employment_start_ymd_month: parseInt(consultPlanDate[1], 10) || '',
          employment_start_ymd_day: parseInt(consultPlanDate[2], 10) || '',
          employment_end_ymd_year: parseInt(consultDate[0], 10) || '',
          employment_end_ymd_month: parseInt(consultDate[1], 10) || '',
          employment_end_ymd_day: parseInt(consultDate[2], 10) || '',
        });
      });
    } else {
      const startDate = props.location.state.employment_start_ymd?.split('-') || [];
      const endDate = props.location.state.employment_end_ymd?.split('-') || [];
      setWorkCondition({
        employment_start_ymd_year: parseInt(startDate[0], 10) || '',
        employment_start_ymd_month: parseInt(startDate[1], 10) || '',
        employment_start_ymd_day: parseInt(startDate[2], 10) || '',
        employment_end_ymd_year: parseInt(endDate[0], 10) || '',
        employment_end_ymd_month: parseInt(endDate[1], 10) || '',
        employment_end_ymd_day: parseInt(endDate[2], 10) || '',
      });
    }
  }, []);

  const handleSubmitSend = (params) => {
    axiosInstance
      .patch(`/employees/${props.match.params.id}/work_condition`, {
        employment_start_ymd: generateDateString(
          params.employment_start_ymd_year,
          params.employment_start_ymd_month,
          params.employment_start_ymd_day
        ),

        employment_end_ymd: generateDateString(
          params.employment_end_ymd_year,
          params.employment_end_ymd_month,
          params.employment_end_ymd_day
        ),
      })
      .then((response) => {
        if (response.data) {
          dispatch(setSuccessToast(true));
          props.history.push(`/employee/${props.match.params.id}/workCondition`);
        }
      });
  };

  const deleteThisWorkCondition = () => {
    axiosInstance.delete(`/employees/${props.match.params.id}/work_condition`).then((response) => {
      if (response.data) {
        dispatch(setSuccessToast(true));
        props.history.push(`/employee/${props.match.params.id}/workCondition`);
      }
    });
  };

  return (
    <div className="content w-form">
      <HeaderForm
        deleteShowing={deleteShowing}
        setDeleteShowing={setDeleteShowing}
        deleteThisCheckup={deleteThisWorkCondition}
        haveBtnDelete={IsAuthorize(AUTH_DELETE)}
      />
      <Formik
        validationSchema={Schema}
        onSubmit={handleSubmitSend}
        enableReinitialize
        initialValues={workCondition}
      >
        {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <div className="mt-6 bg-gray2 pa-16 px-10 pos-rel">
              <div className="w-90">
                <InputDateForm
                  title="契約開始日"
                  titleRequired
                  handleChange={handleChange}
                  valuePrefix="employment_start_ymd"
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                />
                <InputDateForm
                  title="契約終了日"
                  handleChange={handleChange}
                  valuePrefix="employment_end_ymd"
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                />
              </div>
            </div>
            <div className="dis-flex flex-center submit-wrapper">
              <button className="save-btn" type="submit" disabled={!IsAuthorize(AUTH_CREATEUPDATE)}>
                登録
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
