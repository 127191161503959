/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Formik, Field, FieldArray } from 'formik';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { ArrowClockwise } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { InputLabel, Label } from '../../components/Input/InputLabel';
import InputDropdown from '../../components/common/form/InputDropdown';
import ChangeRecordButton from '../../components/common/button/AddRecordButton';
import iconMinus from '../../assets/images/icon-minus.svg';
import {
  yearOptionList,
  monthOptionList,
  getDayOptionListInMonth,
  toIso8601str,
  listOptionBirthdayYear,
  generateListOptionYear,
} from '../../utils/date';
import axiosInstance from '../../api/base';
import {
  DIV_ID_EMPLOYMENT_TYPE,
  DIV_ID_BLOOD_TYPE,
  DIV_ID_JOB,
  DIV_ID_POSITION,
} from '../../api/constants';
import {
  MSG_VALIDATE_ALPHABET,
  MSG_VALIDATE_KATAKANA,
  RANGE_OPTION_DATE,
  REGEX_ALPHABET,
  REGEX_BOTH_FULL_HALF_NUMBER,
  REGEX_EMAIL_FULLWIDTH,
  REGEX_KATAKANA,
} from '../../utils/const';
import { handleBlur, handleChangePhone } from '../../utils/handleInput';
import { setSuccessToast } from '../../redux/slices/toast';

Yup.addMethod(Yup.string, 'phone', function validate(errorMessage) {
  return this.test(`phone`, errorMessage, function validate2(value) {
    const { path, createError } = this;
    return (
      !value ||
      REGEX_BOTH_FULL_HALF_NUMBER.test(value) ||
      createError({ path, message: errorMessage })
    );
  });
});

const Schema = Yup.object().shape({
  employee_last_name: Yup.string().required(' '),
  employee_first_name: Yup.string().required(' '),
  employee_last_name_kn: Yup.string()
    .required(' ')
    .trim()
    .matches(REGEX_KATAKANA, MSG_VALIDATE_KATAKANA),
  employee_first_name_kn: Yup.string()
    .required(' ')
    .trim()
    .matches(REGEX_KATAKANA, MSG_VALIDATE_KATAKANA),
  employee_roman_last_name: Yup.string()
    .required(' ')
    .matches(REGEX_ALPHABET, MSG_VALIDATE_ALPHABET),
  employee_roman_first_name: Yup.string()
    .required(' ')
    .matches(REGEX_ALPHABET, MSG_VALIDATE_ALPHABET),
  tel_areacd: Yup.string().required(' ').phone('Invalid number'),
  tel_localcd: Yup.string().required(' ').phone('Invalid number'),
  tel_number: Yup.string().required(' ').phone('Invalid number'),
  biz_tel_areacd: Yup.string().phone('Invalid number'),
  biz_tel_localcd: Yup.string().phone('Invalid number'),
  biz_tel_number: Yup.string().phone('Invalid number'),
  employee_no: Yup.number().typeError(' ').required(' '),
  week_work_time_hour: Yup.number().typeError(' ').nullable(),
  week_work_time_minute: Yup.number().typeError(' ').nullable(),
  birthday_year: Yup.string().required(' '),
  birthday_month: Yup.string().required(' '),
  birthday_day: Yup.string().required(' '),
  employment_status_div: Yup.string().required(' '),
  job_div: Yup.string().nullable(),
  joined_year: Yup.string().required(' '),
  joined_month: Yup.string().required(' '),
  joined_day: Yup.string().required(' '),
  mail_address: Yup.string()
    .nullable()
    .test('is-email', '有効なメールアドレスを入力する必要があります', (value) => {
      const check = value?.match(REGEX_EMAIL_FULLWIDTH);
      return !value || (check && check[0] === value);
    }),
  mail_address_sub: Yup.string()
    .nullable()
    .test('is-email', '有効なメールアドレスを入力する必要があります', (value) => {
      const check = value?.match(REGEX_EMAIL_FULLWIDTH);
      return !value || (check && check[0] === value);
    }),
  employee_belongs: Yup.array().of(
    Yup.object().shape({
      position: Yup.number().required(' ').nullable(),
      dept_cd: Yup.number().required(' ').nullable(),
      group_nm: Yup.string().notRequired(),
    })
  ),
});

function EmployeeCreate(props) {
  const dispatch = useDispatch();
  const [bloodTypeOptionList, setBloodTypeOptionList] = useState([]);
  const [employmentStatusOptionList, setEmploymentStatusOptionList] = useState([]);
  const [jobDivOptionList, setJobDivOptionList] = useState([]);
  const [departmentOptionList, setDepartmentOptionList] = useState([]);
  const [positionOptionList, setPositionOptionList] = useState([]);
  const [myEmployeeNo, setMyEmployeeNo] = useState('');

  const birthdayYearRef = useRef(null);

  const resetValue = (setFieldValue, valuePrefix) => {
    const yearKey = `${valuePrefix}_year`;
    const monthKey = `${valuePrefix}_month`;
    const dayKey = `${valuePrefix}_day`;
    setTimeout(() => {
      setFieldValue(dayKey, '');
      setFieldValue(monthKey, '');
      setFieldValue(yearKey, '');
    }, 0);
  };

  const handleSubmitCreate = async (values) => {
    try {
      const formatValues = {
        ...values,
        birthday: toIso8601str(
          new Date(values.birthday_year, values.birthday_month - 1, values.birthday_day)
        ),
        joined_ymd: toIso8601str(
          new Date(values.joined_year, values.joined_month - 1, values.joined_day)
        ),
        retire_ymd: values.retire_year
          ? toIso8601str(new Date(values.retire_year, values.retire_month - 1, values.retire_day))
          : null,
      };

      const response = await axiosInstance.post(`/employees/`, formatValues);
      if (response.status >= 500) {
        throw response.status;
      } else {
        if (values.employee_belongs?.length > 0) {
          const responseForBelong = await axiosInstance.post(
            `/employees/${response.data.id}/belong/create`,
            values.employee_belongs.map((belong, idx) => ({
              ...belong,
              company_cd: response.data.company_cd,
              employee_cd: response.data.id,
              main_belong_flag: idx === 0 ? 1 : 0,
            }))
          );
          if (responseForBelong.status >= 500) {
            throw responseForBelong.status;
          }
        }
        dispatch(setSuccessToast(true));
        props.history.push('/employee');
      }
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert(error);
    }
  };

  useEffect(() => {
    const init = async () => {
      const resEmploymentStatus = await axiosInstance.get(`/divs/${DIV_ID_EMPLOYMENT_TYPE}`);
      setEmploymentStatusOptionList(
        resEmploymentStatus.data.map((e) => ({ value: e.div_value, label: e.div_value_nm }))
      );
      const resBloodType = await axiosInstance.get(`/divs/${DIV_ID_BLOOD_TYPE}`);
      setBloodTypeOptionList(
        resBloodType.data.map((e) => ({ value: e.div_value, label: e.div_value_nm }))
      );
      const resJobType = await axiosInstance.get(`/divs/${DIV_ID_JOB}`);
      setJobDivOptionList(
        resJobType.data.map((e) => ({ value: e.div_value, label: e.div_value_nm }))
      );
      const resDepartmentType = await axiosInstance.get(`/departments`);
      setDepartmentOptionList(
        resDepartmentType.data.map((e) => ({ value: e.dept_cd, label: e.dept_nm }))
      );
      const resPositionType = await axiosInstance.get(`/divs/${DIV_ID_POSITION}`);
      setPositionOptionList(
        resPositionType.data.map((e) => ({ value: e.div_value, label: e.div_value_nm }))
      );
      setMyEmployeeNo('1234567890');
    };
    init();
  }, []);

  useEffect(() => {
    birthdayYearRef.current.focus();
  }, []);

  return (
    <>
      <div className="employee-form-body">
        <Formik
          validationSchema={Schema}
          initialValues={{
            employee_last_name: '',
            employee_first_name: '',
            employee_last_name_kn: '',
            employee_first_name_kn: '',
            employee_roman_last_name: '',
            employee_roman_first_name: '',
            tel_areacd: '',
            tel_localcd: '',
            tel_number: '',
            employee_no: myEmployeeNo,
            week_work_time_hour: null,
            week_work_time_minute: null,
            retire_memo: '',
            // birthday
            birthday_year: '',
            birthday_month: '',
            birthday_day: '',
            blood_type: 0,
            employment_status_div: '',
            job_div: null,
            // joined_ymd
            joined_year: '',
            joined_month: '',
            joined_day: '',
            // retire_ymd
            retire_year: '',
            retire_month: '',
            retire_day: '',
            company_cd: '',
            company_position: '',
            company_position_group: '',
            freememo: '',
            mail_address: '',
            employment_term_div: 1,
            sex: 1,
            have_spouse_flag: 0,
            employee_belongs: [],
          }}
          onSubmit={handleSubmitCreate}
        >
          {({
            handleSubmit,
            errors,
            touched,
            setFieldValue,
            values,
            setFieldTouched,
            handleChange,
          }) => (
            <Form className="employee-form">
              <div className="employee-form-main">
                {/* 基本情報 */}
                <Form.Group>
                  <h2 className="title-main__form">基本情報</h2>
                  <Row className="mb-4">
                    <Col sm={3}>
                      <Label label="姓" isRequired />
                    </Col>
                    <Col sm={3} className="pl-0 pr-0">
                      <InputLabel
                        name="employee_last_name"
                        placeholder="山田"
                        error={touched.employee_last_name && errors.employee_last_name}
                      />
                    </Col>
                    <Col sm={3} className="pa-l-10">
                      <Label label="名" isRequired />
                    </Col>
                    <Col sm={3} className="pl-0 pr-0">
                      <InputLabel
                        name="employee_first_name"
                        placeholder="太郎"
                        error={touched.employee_first_name && errors.employee_first_name}
                      />
                    </Col>
                  </Row>

                  <Row className="mb-4">
                    <Col sm={3}>
                      <Label label="セイ" isRequired />
                    </Col>
                    <Col sm={3} className="pl-0 pr-0">
                      <InputLabel
                        name="employee_last_name_kn"
                        placeholder="ヤマダ"
                        error={touched.employee_last_name_kn && errors.employee_last_name_kn}
                      />
                    </Col>
                    <Col sm={3} className="pa-l-10">
                      <Label label="メイ" isRequired />
                    </Col>
                    <Col sm={3} className="pl-0 pr-0">
                      <InputLabel
                        name="employee_first_name_kn"
                        placeholder="タロウ"
                        error={touched.employee_first_name_kn && errors.employee_first_name_kn}
                      />
                    </Col>
                  </Row>
                </Form.Group>

                {/* ローマ字 */}
                <Form.Group>
                  <Row className="mb-4">
                    <Col sm={3}>
                      <Label label="Last" isRequired />
                    </Col>
                    <Col sm={3} className="pl-0 pr-0">
                      <InputLabel
                        name="employee_roman_last_name"
                        placeholder="Yamada"
                        error={touched.employee_roman_last_name && errors.employee_roman_last_name}
                      />
                    </Col>
                    <Col sm={3} className="pa-l-10">
                      <Label label="First" isRequired />
                    </Col>
                    <Col sm={3} className="pl-0 pr-0">
                      <InputLabel
                        name="employee_roman_first_name"
                        placeholder="Taro"
                        error={
                          touched.employee_roman_first_name && errors.employee_roman_first_name
                        }
                      />
                    </Col>
                  </Row>
                </Form.Group>

                {/* 生年月日 */}
                <Form.Group>
                  <Row className="mb-4 position-relative">
                    <ArrowClockwise
                      onClick={() => resetValue(setFieldValue, 'birthday')}
                      className="position-absolute top-0"
                      style={{ top: '12px', right: '-25px', cursor: 'pointer' }}
                    />
                    <Col sm={3}>
                      <Label label="生年月日" isRequired />
                    </Col>
                    <Col sm={3} className="pl-0 pr-0">
                      <InputLabel
                        name="birthday_year"
                        placeholder="選択してください"
                        error={touched.birthday_year && errors.birthday_year}
                        component={
                          <select
                            ref={birthdayYearRef}
                            as="select"
                            className={`select-common w-100 ${
                              !values.birthday_year && 'text-muted'
                            }`}
                            name="birthday_year"
                            onChange={handleChange}
                            value={values.birthday_year || ''}
                          >
                            <option value="" disabled hidden>
                              選択してください
                            </option>
                            {listOptionBirthdayYear.map((item) => (
                              <option key={item.value} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                        }
                      />
                    </Col>

                    <Col sm={1} />
                    <Col sm={2} className="pl-0 pr-0 pos-rel">
                      <InputLabel
                        name="birthday_month"
                        placeholder=""
                        error={touched.birthday_month && errors.birthday_month}
                        component={
                          <select
                            className={`select-common w-100 ${
                              !values.birthday_month && 'text-muted'
                            }`}
                            name="birthday_month"
                            onChange={handleChange}
                            value={values.birthday_month || ''}
                          >
                            <option value="" disabled hidden />
                            {monthOptionList.map((item) => (
                              <option key={item.value} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                        }
                      />
                      {!errors.birthday_month && (
                        <div className="time-unit dropdown-unit pos-abs">月</div>
                      )}
                    </Col>
                    <Col sm={1} />
                    <Col sm={2} className="pl-0 pr-0 pos-rel">
                      <InputLabel
                        disabled
                        name="birthday_day"
                        placeholder=""
                        error={touched.birthday_day && errors.birthday_day}
                        component={
                          <select
                            className={`select-common w-100 ${
                              !values.birthday_day && 'text-muted'
                            }`}
                            value={values.birthday_day || ''}
                            name="birthday_day"
                            onChange={handleChange}
                          >
                            <option value="" disabled hidden />
                            {getDayOptionListInMonth(
                              parseInt(values.birthday_month, 10),
                              parseInt(values.birthday_year, 10)
                            ).map((item) => (
                              <option key={item.value} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                        }
                      />
                      {!errors.birthday_day && (
                        <div className="time-unit dropdown-unit pos-abs">日</div>
                      )}
                    </Col>
                  </Row>

                  <Row className="mb-4">
                    <Col sm={3}>
                      <Label isRequired>性別</Label>
                    </Col>
                    <Col sm={9} className="pl-0 pr-0">
                      <Row>
                        <Col className="text-left">
                          <input
                            type="radio"
                            id="男"
                            name="sex"
                            checked={values.sex === 1}
                            onChange={() => setFieldValue('sex', 1)}
                          />
                          <label htmlFor="男">男</label>
                        </Col>
                        <Col className="text-left">
                          <input
                            type="radio"
                            id="女"
                            name="sex"
                            checked={values.sex === 2}
                            onChange={() => setFieldValue('sex', 2)}
                          />
                          <label htmlFor="女">女</label>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row className="mb-4">
                    <Col sm={3}>
                      <Label isRequired>配偶者</Label>
                    </Col>
                    <Col sm={9} className="pl-0 pr-0">
                      <Row>
                        <Col className="text-left">
                          <input
                            type="radio"
                            id="なし"
                            name="have_spouse_flag"
                            checked={values.have_spouse_flag === 0}
                            onChange={() => setFieldValue('have_spouse_flag', 0)}
                          />
                          <label htmlFor="なし">なし</label>
                        </Col>
                        <Col className="text-left">
                          <input
                            type="radio"
                            id="あり"
                            name="have_spouse_flag"
                            checked={values.have_spouse_flag === 1}
                            onChange={() => setFieldValue('have_spouse_flag', 1)}
                          />
                          <label htmlFor="あり">あり</label>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row className="mb-4">
                    <Col sm={3}>
                      <Label>血液型</Label>
                    </Col>
                    <Col sm={9} className="pl-0 pr-0">
                      <InputLabel
                        name="blood_type"
                        isRequired={false}
                        error={touched.blood_type && errors.blood_type}
                        component={
                          <select
                            className={`select-common w-100 ${!values.blood_type && 'text-muted'}`}
                            defaultValue={String(values.blood_type)}
                            name="blood_type"
                            onChange={(e) => setFieldValue('blood_type', e.target.value)}
                          >
                            <option value="" disabled hidden>
                              選択してください
                            </option>
                            {!!bloodTypeOptionList.length &&
                              bloodTypeOptionList.map((item) => (
                                <option key={item.value} value={item.value}>
                                  {item.label}
                                </option>
                              ))}
                          </select>
                        }
                      />
                    </Col>
                  </Row>

                  <Row className="mb-4">
                    <Col sm={3}>
                      <Label isRequired>電話番号</Label>
                    </Col>
                    <Col className="p-0">
                      <InputLabel
                        name="tel_areacd"
                        placeholder=""
                        error={touched.tel_areacd && errors.tel_areacd}
                        onBlur={(e) => handleBlur(e, setFieldValue, setFieldTouched)}
                        onChange={(e) => handleChangePhone(e, setFieldValue)}
                      />
                    </Col>
                    <div className="w-5 text-center">-</div>
                    <Col className="p-0">
                      <InputLabel
                        name="tel_localcd"
                        placeholder=""
                        error={touched.tel_localcd && errors.tel_localcd}
                        onBlur={(e) => handleBlur(e, setFieldValue, setFieldTouched)}
                        onChange={(e) => handleChangePhone(e, setFieldValue)}
                      />
                    </Col>
                    <div className="w-5 text-center">-</div>
                    <Col className="p-0">
                      <InputLabel
                        name="tel_number"
                        placeholder=""
                        error={touched.tel_number && errors.tel_number}
                        onBlur={(e) => handleBlur(e, setFieldValue, setFieldTouched)}
                        onChange={(e) => handleChangePhone(e, setFieldValue)}
                      />
                    </Col>
                    <Col sm={3} />
                    <Col sm={3} />
                  </Row>

                  <Row className="mb-4">
                    <Col sm={3}>
                      <Label>メールアドレス</Label>
                    </Col>
                    <Col sm={9} className="pl-0 pr-0">
                      <InputLabel
                        name="mail_address"
                        placeholder=""
                        error={touched.mail_address && errors.mail_address}
                        onBlur={(e) => handleBlur(e, setFieldValue, setFieldTouched)}
                      />
                    </Col>
                  </Row>
                </Form.Group>

                <div className="line-spread" />

                <Form.Group>
                  <h2 className="title-main__form">雇用情報</h2>
                  <Row className="mb-4">
                    <Col sm={3}>
                      <Label isRequired>社員番号</Label>
                    </Col>
                    <Col sm={9} className="pl-0 pr-0">
                      <InputLabel
                        name="employee_no"
                        error={touched.employee_no && errors.employee_no}
                        hint="自動で採番されました"
                      />
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col sm={3}>
                      <Label>社用電話番号</Label>
                    </Col>
                    <Col className="p-0">
                      <InputLabel
                        name="biz_tel_areacd"
                        placeholder=""
                        error={touched.biz_tel_areacd && errors.biz_tel_areacd}
                        onBlur={(e) => handleBlur(e, setFieldValue, setFieldTouched)}
                        onChange={(e) => handleChangePhone(e, setFieldValue)}
                      />
                    </Col>
                    <div className="w-5 text-center">-</div>
                    <Col className="p-0">
                      <InputLabel
                        name="biz_tel_localcd"
                        placeholder=""
                        error={touched.biz_tel_localcd && errors.biz_tel_localcd}
                        onBlur={(e) => handleBlur(e, setFieldValue, setFieldTouched)}
                        onChange={(e) => handleChangePhone(e, setFieldValue)}
                      />
                    </Col>
                    <div className="w-5 text-center">-</div>
                    <Col className="p-0">
                      <InputLabel
                        name="biz_tel_number"
                        placeholder=""
                        error={touched.biz_tel_number && errors.biz_tel_number}
                        onBlur={(e) => handleBlur(e, setFieldValue, setFieldTouched)}
                        onChange={(e) => handleChangePhone(e, setFieldValue)}
                      />
                    </Col>
                    <Col sm={3} />
                    <Col sm={3} />
                  </Row>

                  <Row className="mb-4">
                    <Col sm={3}>
                      <Label>社用メールアドレス</Label>
                    </Col>
                    <Col sm={9} className="pl-0 pr-0">
                      <InputLabel
                        name="mail_address_sub"
                        placeholder=""
                        error={touched.mail_address_sub && errors.mail_address_sub}
                        onBlur={(e) => handleBlur(e, setFieldValue, setFieldTouched)}
                      />
                    </Col>
                  </Row>

                  <Row className="mb-4">
                    <Col sm={3}>
                      <Label isRequired>雇用形態</Label>
                    </Col>
                    <Col sm={9} className="pl-0 pr-0">
                      <InputLabel
                        name="employment_status_div"
                        error={touched.employment_status_div && errors.employment_status_div}
                        component={
                          <select
                            className={`select-common w-100 ${
                              !values.employment_status_div && 'text-muted'
                            }`}
                            defaultValue={values.employment_status_div}
                            name="employment_status_div"
                            onChange={(e) => setFieldValue('employment_status_div', e.target.value)}
                          >
                            <option value="" disabled hidden>
                              選択してください
                            </option>
                            {!!employmentStatusOptionList.length &&
                              employmentStatusOptionList.map((item) => (
                                <option key={item.value} value={item.value}>
                                  {item.label}
                                </option>
                              ))}
                          </select>
                        }
                      />
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col sm={3}>
                      <Label isRequired>雇用期間の定め</Label>
                    </Col>
                    <Col sm={9} className="pl-0 pr-0">
                      <Row>
                        <Col>
                          <input
                            type="radio"
                            id="無期"
                            name="employment_term_div"
                            checked={values.employment_term_div === 1}
                            onChange={() => setFieldValue('employment_term_div', 1)}
                          />
                          <label htmlFor="無期">無期</label>
                        </Col>
                        <Col>
                          <input
                            type="radio"
                            id="有期"
                            name="employment_term_div"
                            checked={values.employment_term_div === 2}
                            onChange={() => setFieldValue('employment_term_div', 2)}
                          />
                          <label htmlFor="有期">有期</label>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {/* 業務区分 job_div */}
                  <Row className="mb-4">
                    <Col sm={3}>
                      <Label>業務区分</Label>
                    </Col>
                    <Col sm={9} className="pl-0 pr-0">
                      <InputLabel
                        name="job_div"
                        error={touched.job_div && errors.job_div}
                        component={
                          <select
                            className={`select-common w-100 ${!values.job_div && 'text-muted'}`}
                            defaultValue=""
                            name="job_div"
                            onChange={(e) => setFieldValue('job_div', e.target.value)}
                            placeholder="選択してください"
                          >
                            <option value="" disabled hidden>
                              選択してください
                            </option>
                            {!!jobDivOptionList.length &&
                              jobDivOptionList.map((item) => (
                                <option key={item.value} value={item.value}>
                                  {item.label}
                                </option>
                              ))}
                          </select>
                        }
                      />
                    </Col>
                  </Row>
                  {/* 業務メモ job_content */}
                  <Row className="mb-4">
                    <Col sm={3}>
                      <Label>業務メモ</Label>
                    </Col>
                    <Col sm={9} className="pl-0 pr-0">
                      <InputLabel
                        name="job_content"
                        placeholder="担当車両や担当顧客を入力してください"
                        isRequired={false}
                        error={touched.job_content && errors.job_content}
                      />
                    </Col>
                  </Row>
                  {/* １週間の所定労働時間 week_work_time_hour */}
                  <Row className="mb-4">
                    <Col sm={3}>
                      <Label>１週間の 所定労働時間</Label>
                    </Col>
                    <Col sm={3} className="pl-0 pr-0">
                      <InputLabel
                        name="week_work_time_hour"
                        error={touched.week_work_time_hour && errors.week_work_time_hour}
                        hint="半角数字で入力"
                        suffix="時間"
                      />
                    </Col>
                    <Col sm={3} className="pl-0 pr-0" />
                    <Col sm={3} className="pl-0 pr-0">
                      <InputLabel
                        name="week_work_time_minute"
                        suffix="分"
                        error={touched.week_work_time_minute && errors.week_work_time_minute}
                        hint="半角数字で入力"
                      />
                    </Col>
                  </Row>
                </Form.Group>

                {/* 入社日 joined_ymd */}
                <Form.Group>
                  <Row className="mb-4 position-relative">
                    <ArrowClockwise
                      onClick={() => resetValue(setFieldValue, 'joined')}
                      className="position-absolute top-0"
                      style={{ top: '12px', right: '-25px', cursor: 'pointer' }}
                    />
                    <Col sm={3}>
                      <Label isRequired>入社年月日</Label>
                    </Col>
                    <Col sm={3} className="pl-0 pr-0">
                      <InputLabel
                        name="joined_year"
                        error={touched.joined_year && errors.joined_year}
                        component={
                          <select
                            className={`select-common w-100 ${!values.joined_year && 'text-muted'}`}
                            value={values.joined_year || ''}
                            name="joined_year"
                            onChange={handleChange}
                          >
                            <option value="" disabled hidden>
                              選択してください
                            </option>
                            {generateListOptionYear(RANGE_OPTION_DATE.JOINED_YMD).map((item) => (
                              <option key={item.value} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                        }
                      />
                    </Col>
                    <Col sm={1} />
                    <Col sm={2} className="pl-0 pr-0 pos-rel">
                      <InputLabel
                        name="joined_month"
                        error={touched.joined_month && errors.joined_month}
                        component={
                          <select
                            className={`select-common w-100 ${
                              !values.joined_month && 'text-muted'
                            }`}
                            value={values.joined_month || ''}
                            name="joined_month"
                            onChange={handleChange}
                          >
                            <option value="" disabled hidden />
                            {monthOptionList.map((item) => (
                              <option key={item.value} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                        }
                      />
                      {!errors.joined_month && (
                        <div className="time-unit dropdown-unit pos-abs">月</div>
                      )}
                    </Col>
                    <Col sm={1} />
                    <Col sm={2} className="pl-0 pr-0 pos-rel">
                      <InputLabel
                        name="joined_day"
                        placeholder=""
                        error={touched.joined_day && errors.joined_day}
                        component={
                          <select
                            className={`select-common w-100 ${!values.joined_day && 'text-muted'}`}
                            value={values.joined_day || ''}
                            name="joined_day"
                            onChange={handleChange}
                          >
                            <option value="" disabled hidden />
                            {getDayOptionListInMonth(
                              parseInt(values.joined_month, 10),
                              parseInt(values.joined_year, 10)
                            ).map((item) => (
                              <option key={item.value} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                        }
                      />
                      {!errors.joined_day && (
                        <div className="time-unit dropdown-unit pos-abs">日</div>
                      )}
                    </Col>
                  </Row>
                </Form.Group>

                {/* 退職日 retire_ymd */}
                <Form.Group>
                  <Row className="mb-4 position-relative">
                    <ArrowClockwise
                      onClick={() => resetValue(setFieldValue, 'retire')}
                      className="position-absolute top-0"
                      style={{ top: '12px', right: '-25px', cursor: 'pointer' }}
                    />
                    <Col sm={3}>
                      <Label>退職年月日</Label>
                    </Col>
                    <Col sm={3} className="pl-0 pr-0">
                      <InputLabel
                        name="retire_year"
                        isRequired={false}
                        error={touched.retire_year && errors.retire_year}
                        component={
                          <select
                            className={`select-common w-100 ${!values.retire_year && 'text-muted'}`}
                            value={values.retire_year || ''}
                            name="retire_year"
                            onChange={handleChange}
                          >
                            <option value="" disabled hidden>
                              選択してください
                            </option>
                            {yearOptionList.map((item) => (
                              <option key={item.value} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                        }
                      />
                    </Col>
                    <Col sm={1} />
                    <Col sm={2} className="pl-0 pr-0 pos-rel">
                      <InputLabel
                        name="retire_month"
                        placeholder=""
                        error={touched.retire_month && errors.retire_month}
                        component={
                          <select
                            className={`select-common w-100 ${
                              !values.retire_month && 'text-muted'
                            }`}
                            value={values.retire_month || ''}
                            name="retire_month"
                            onChange={handleChange}
                          >
                            <option value="" disabled hidden />
                            {monthOptionList.map((item) => (
                              <option key={item.value} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                        }
                      />
                      {!errors.retire_month && (
                        <div className="time-unit dropdown-unit pos-abs">月</div>
                      )}
                    </Col>
                    <Col sm={1} />
                    <Col sm={2} className="pl-0 pr-0 pos-rel">
                      <InputLabel
                        name="retire_day"
                        error={touched.retire_day && errors.retire_day}
                        component={
                          <select
                            className={`select-common w-100 ${!values.retire_day && 'text-muted'}`}
                            value={values.retire_day || ''}
                            name="retire_day"
                            onChange={handleChange}
                          >
                            <option value="" disabled hidden />
                            {getDayOptionListInMonth(
                              parseInt(values.retire_month, 10),
                              parseInt(values.retire_year, 10)
                            ).map((item) => (
                              <option key={item.value} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                        }
                      />
                      {!errors.retire_day && (
                        <div className="time-unit dropdown-unit pos-abs">日</div>
                      )}
                    </Col>
                  </Row>
                  {/* 退職メモ retire_memo */}
                  <Row className="mb-4">
                    <Col sm={3}>
                      <Label>退職メモ</Label>
                    </Col>
                    <Col sm={9} className="pl-0 pr-0">
                      <InputLabel
                        name="retire_memo"
                        hint="退職理由などを入力"
                        error={touched.retire_memo && errors.retire_memo}
                        className="w-100"
                      />
                    </Col>
                  </Row>
                </Form.Group>
                {/* 所属先 */}
                <FieldArray
                  name="employee_belongs"
                  render={(arrayHelpers) => {
                    const { employee_belongs } = values;
                    return (
                      <>
                        {employee_belongs &&
                          employee_belongs.length > 0 &&
                          employee_belongs.map((_, index) => (
                            <div className="pos-rel border-slim pa-16 mb-8">
                              <button
                                className="belong-dismiss pos-abs"
                                type="button"
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <img alt="minus" src={iconMinus} />
                              </button>
                              <InputDropdown
                                title="所属先"
                                titleSm="3"
                                colSm="9"
                                values={values}
                                errors={errors}
                                nestedFieldKey={`employee_belongs[${index}].dept_cd`}
                                handleChange={handleChange}
                                options={departmentOptionList}
                                optionKey="value"
                                optionName="label"
                                isRequired
                              />
                              <InputDropdown
                                title="役職"
                                titleSm="3"
                                colSm="3"
                                values={values}
                                errors={errors}
                                nestedFieldKey={`employee_belongs[${index}].position`}
                                handleChange={handleChange}
                                options={positionOptionList}
                                optionKey="value"
                                optionName="label"
                                isRequired
                              >
                                <Form.Label column sm="3" className="pl-10">
                                  グループ
                                </Form.Label>
                                <Col sm="3">
                                  <Form.Control
                                    name={`employee_belongs[${index}].group_nm`}
                                    value={values.employee_belongs[index].group_nm}
                                    onChange={handleChange}
                                  />
                                </Col>
                              </InputDropdown>
                            </div>
                          ))}
                        <div className="dis-flex flex-row-reverse">
                          <ChangeRecordButton
                            onClick={() =>
                              arrayHelpers.push({
                                position: null,
                                dept_cd: null,
                                group_nm: '',
                              })
                            }
                            text="所属を追加する"
                          />
                        </div>
                      </>
                    );
                  }}
                />
                <div className="line-spread" />

                <Form.Group>
                  <h2 className="title-main__form">備考</h2>
                  <Row className="mb-4">
                    <Col sm={3}>
                      <Label>メモ</Label>
                    </Col>
                    <Col sm={9} className="pl-0">
                      <Field
                        sm={9}
                        name="freememo"
                        component="textarea"
                        className="freememo"
                        rows={3}
                        onChange={(e) => setFieldValue('freememo', e.target.value)}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </div>
              <div className="dis-flex flex-center submit-wrapper">
                <Button className="save-btn" onClick={handleSubmit}>
                  新規作成
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default EmployeeCreate;
