import { useDispatch } from 'react-redux';
import IcImage from '../../../assets/images/ic_image.svg';
import { addImage } from '../../../redux/slices/relatedDocs';

export default function RelatedDocsImagesSelect({ addFile }) {
  const dispatch = useDispatch();

  const handleSelectedFile = (selectedFile) => {
    if (selectedFile.length <= 0 || selectedFile[0].type.split('/')[0] !== 'image') {
      return;
    }

    addFile(selectedFile[0]);
    dispatch(addImage({ name: selectedFile[0].name, url: URL.createObjectURL(selectedFile[0]) }));
  };

  const onDrop = (e) => {
    e.preventDefault();
    const selectedFile = e?.dataTransfer?.files;
    handleSelectedFile(selectedFile);
  };

  const onSelect = (e) => {
    const selectedFile = e.target.files;
    handleSelectedFile(selectedFile);
  };

  return (
    <div className="related-docs-images__select__wrapper">
      <div>画像の追加</div>

      <label htmlFor="file" onDragOver={(e) => e.preventDefault()} onDrop={onDrop}>
        <input
          id="file"
          type="file"
          accept="image/*"
          onChange={onSelect}
          onClick={(e) => {
            e.target.value = null;
          }}
        />

        <div>
          <img alt="img-upload" src={IcImage} />
        </div>

        <div>画像が登録されていません</div>

        <div>
          画像を選択または
          <br />
          ドラックアンドドロップでアップロード
        </div>
      </label>
    </div>
  );
}
