import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { ArrowDown } from 'react-bootstrap-icons';
import Table from '../../components/common/table/Table';
import PrintButton from '../../components/common/button/PrintButton';
import ItemWrapper from '../../components/ItemWrapper';
import FileHandler from '../../components/FileHandler';
import { computeDate } from '../../utils/convert';
import { RELATED_DOCS_DIV_VALUE_NAMES } from '../../utils/const';
import noAvatar from '../../assets/images/avatar.png';
import axiosInstance from '../../api/base';
import {
  DOCS_DIV_VALUE_EMPLOYMENT_AGREEMENT,
  DOCS_DIV_VALUE_WORKING_CONDITIONS_NOTICE,
  DIV_ID_INSURANCE,
  EMPLOYEE_DOCS_IMAGE_ONLY,
  DOCS_DIV_ROUTER_MAPPER,
  DOCS_DIV_VALUE_AVATAR,
} from '../../api/constants';
import { getTimeOfService } from '../../utils/date';
import UploadAvatarModal from './avatar/UploadAvatarModal';

export function DriverLicensesVariations({ driverLicensesIndex, driverLicenses }) {
  const variationMapping = {
    large_size: '大型',
    midium_size: '中型',
    regular_size: '普通',
    semi_midium_size: '準中型',
    special_large_size: '大特',
    large_motorcycle: '大自二',
    regular_motorcycle: '普自二',
    special_small_size: '小特',
    scooters: '原付',
    second_large_size: '大二',
    second_midium_size: '中二',
    second_regular_size: '普二',
    second_special_large: '大特二',
    traction: '引・引二',
  };
  return (
    <span>
      {Object.keys(variationMapping)
        .map((key) => driverLicenses[driverLicensesIndex]?.[key] && variationMapping[key])
        .filter((item) => item && typeof item !== 'boolean')
        .join('、')}
    </span>
  );
}

export const getNewestByUpdateDatetime = (unsorted) => {
  if (!unsorted.length) return null;
  return unsorted.sort(
    (prev, next) => moment(next.update_datetime) - moment(prev.update_datetime)
  )[0];
};

function EmployeeDetailInfo({ history, match }) {
  const [isUploadAvatarModalShown, setIsUploadAvatarModalShown] = useState(false);
  const [datas, satDatas] = useState({});
  const [newestCommuteWay, setCommuteWay] = useState({});
  const [newestCommuteVehicle, setCommuteVehicle] = useState({});
  const [vehicleInsurances, setVehicleInsurances] = useState([]);
  const [insuranceTypes, setInsuranceTypes] = useState([]);

  const gotoPage = (path, state = {}, params = {}) => {
    history.push({
      pathname: `/employee/${match.params.id}/${path}`,
      search: `?${new URLSearchParams(params).toString()}`,
      state,
    });
  };

  const onDocListItemClicked = (row) => {
    if (EMPLOYEE_DOCS_IMAGE_ONLY.find((r) => r.divId === row.id)) {
      const mapperImgOnly = EMPLOYEE_DOCS_IMAGE_ONLY.find((r) => r.divId === row.id);
      if (mapperImgOnly) gotoPage(`${mapperImgOnly.route}`);
    } else if (row.id === DOCS_DIV_VALUE_AVATAR) {
      setIsUploadAvatarModalShown(true);
    } else {
      const mapper = DOCS_DIV_ROUTER_MAPPER.find((r) => r.divId === row.id);
      if (mapper) gotoPage(mapper.route);
    }
  };

  const columns = [
    {
      accessor: 'related_docs_div_nm',
      Header: '名称',
      width: 240,
    },
    {
      Cell: ({ value }) => (value ? '登録済' : '未登録'),
      accessor: 'related_docs_id',
      Header: '書類登録状況',
      width: 180,
    },
    {
      accessor: 'regist_datetime',
      Header: '画像登録日',
      width: 180,
      Cell: ({ value }) => computeDate(value),
    },
    {
      accessor: 'expiration_date',
      Header: '有効期限',
      width: 180,
      Cell: ({ value }) => computeDate(value),
    },
    {
      accessor: 'action',
      Header: '',
      disableSortBy: true,
      width: 70,
      Cell: () => (
        <div className="aln-right">
          <span className="text-prim">詳細</span>
        </div>
      ),
    },
    {
      id: 'id',
      accessor: 'related_docs_div',
      width: 0,
      Cell: () => null,
      disableSortBy: true,
    },
  ];

  useEffect(() => {
    const id = match.params.id || '1093809';
    axiosInstance.get(`/employees/${id}`).then((response) => {
      satDatas(response.data);
    });
    axiosInstance.get(`/employees/${id}/employee_commute_ways`).then((response) => {
      setCommuteWay(getNewestByUpdateDatetime(response.data));
    });
    axiosInstance.get(`/employees/${id}/employee_commute_vehicles`).then((response) => {
      setCommuteVehicle(getNewestByUpdateDatetime(response.data));
    });
    axiosInstance.get(`/employees/${id}/employee_commuter_vehicle_insurances`).then((response) => {
      setVehicleInsurances(response.data);
    });
    axiosInstance.get(`/divs/${DIV_ID_INSURANCE}`).then((response) => {
      setInsuranceTypes(response.data.sort((cur, next) => cur.display_order - next.display_order));
    });
  }, []);

  if (datas?.employee_info == null) {
    return <></>;
  }

  const employeeInfo = datas?.employee_info;
  const colapsibles = [
    {
      text: '基本情報',
      id: 'basic-info',
    },
    {
      text: '雇用情報',
      id: 'employment-info',
    },
    {
      text: '緊急連絡先',
      id: 'emergency-contact',
    },
    {
      text: '備品',
      id: 'equipment',
    },
    {
      text: '制服',
      id: 'uniform',
    },
    {
      text: '車両等資格情報',
      id: 'vehicle-qualification-info',
    },
    {
      text: '事故履歴',
      id: 'accident-history',
    },
    {
      text: '帳票書類',
      id: 'form-docs',
    },
  ];
  const selectDocDivById = (id) =>
    employeeInfo.related_docs.find((doc) => doc.related_docs_div === id);

  // 運転者台帳出力
  const downloadDriverLedger = () => {
    axiosInstance
      .get(`/employees/${match.params.id}/reports/driverLedger`, {
        responseType: 'blob',
        dataType: 'binary',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const now = moment().format('YYYYMMDDHHmmss');
        link.setAttribute('download', `運転者台帳_${now}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
  };
  // 労働者名簿出力
  const downloadWorkerList = () => {
    axiosInstance
      .get(`/employees/${match.params.id}/reports/workerList`, {
        responseType: 'blob',
        dataType: 'binary',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const now = moment().format('YYYYMMDDHHmmss');
        link.setAttribute('download', `労働者名簿_${now}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
  };
  // 社労士用ファイル
  const downloadEmployerInfo = () => {
    axiosInstance
      .get(`/employees/${match.params.id}/reports/employerInfo`, {
        responseType: 'blob',
        dataType: 'binary',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const now = moment().format('YYYYMMDDHHmmss');
        link.setAttribute('download', `社労士用ファイル_${now}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="content employee-detail">
      <div className="personal-info">
        <div className="dis-flex">
          <img src={datas?.avatar?.url || noAvatar} className="avatar mr-r-md" alt="" />
          <h1 className="bold">{`${employeeInfo.employee_last_name} ${employeeInfo.employee_first_name}`}</h1>
        </div>
        <PrintButton
          firstItemClicked={() => downloadDriverLedger()}
          secondItemClicked={() => downloadWorkerList()}
          thirdItemClicked={() => downloadEmployerInfo()}
        />
      </div>
      <div className="static-breadcrumb">
        {colapsibles.map((breadcrumb, index, arr) => (
          <div key={index}>
            <ArrowDown size={15} />
            <a href={`#${breadcrumb.id}`} className="text-prim">
              {breadcrumb.text}
            </a>
            {arr.length - 1 === index || ' / '}
          </div>
        ))}
      </div>
      <div className="line" />
      <ItemWrapper
        title={colapsibles[0].text}
        id={colapsibles[0].id}
        onEditClicked={() => gotoPage('edit')}
      >
        <div className="dis-flex text-left">
          <div className="w-50">
            <span className="text-lght">姓：</span>
            {employeeInfo.employee_last_name}
          </div>
          <div className="w-50">
            <span className="text-lght">名：</span>
            {employeeInfo.employee_first_name}
          </div>
        </div>
        <div className="dis-flex text-left">
          <div className="w-50">
            <span className="text-lght">セイ：</span>
            {employeeInfo.employee_last_name_kn}
          </div>
          <div className="w-50">
            <span className="text-lght">メイ：</span>
            {employeeInfo.employee_first_name_kn}
          </div>
        </div>
        <div className="dis-flex text-left">
          <div className="w-50">
            <span className="text-lght">ローマ字姓：</span>
            <span className="text-cap">{employeeInfo.employee_roman_last_name}</span>
          </div>
          <div className="w-50">
            <span className="text-lght">ローマ字名：</span>
            <span className="text-cap">{employeeInfo.employee_roman_first_name}</span>
          </div>
        </div>
        <div className="dis-flex text-left">
          <div className="w-50">
            <span className="text-lght">生年月日：</span>
            {computeDate(employeeInfo.birthday)}
          </div>
          <div className="w-50">
            <span className="text-lght">社員番号：</span>
            {employeeInfo.employee_no}
          </div>
        </div>
        <div className="dis-flex text-left">
          <div className="w-50">
            <span className="text-lght">国籍：</span>
            {employeeInfo.national ? employeeInfo.national.div_value_nm : ''}
          </div>
          <div className="w-50">
            <span className="text-lght">配偶者：</span>
            {employeeInfo.have_spouse_flag === '1' ? '有' : '無'}
          </div>
        </div>
        <div className="dis-flex text-left">
          <div className="w-50">
            <span className="text-lght">電話番号（個人）：</span>
            {employeeInfo.tel_areacd}
            {employeeInfo.tel_localcd && ` - ${employeeInfo.tel_localcd}`}
            {employeeInfo.tel_number && ` - ${employeeInfo.tel_number}`}
          </div>
          <div className="w-50">
            <span className="text-lght">電話番号（会社）：</span>
            {employeeInfo.biz_tel_areacd}
            {employeeInfo.biz_tel_localcd && ` - ${employeeInfo.biz_tel_localcd}`}
            {employeeInfo.biz_tel_number && ` - ${employeeInfo.biz_tel_number}`}
          </div>
        </div>
        <span className="text-lght">血液型：</span>
        {employeeInfo.blood ? employeeInfo.blood.div_value_nm : ''}
        <br />
        <span className="text-lght">備考：</span>
        {employeeInfo.freememo}
        {selectDocDivById(DOCS_DIV_VALUE_EMPLOYMENT_AGREEMENT) && (
          <FileHandler className="border-slim border-rad-4 bg-sec">
            <span>帳票</span>
            <span className="text-prim">
              {RELATED_DOCS_DIV_VALUE_NAMES[DOCS_DIV_VALUE_EMPLOYMENT_AGREEMENT]}
            </span>
            <br />
            <span className="text-lght">登録日</span>
            <span>
              {computeDate(selectDocDivById(DOCS_DIV_VALUE_EMPLOYMENT_AGREEMENT)?.regist_datetime)}
            </span>
          </FileHandler>
        )}
      </ItemWrapper>
      <ItemWrapper
        title={colapsibles[1].text}
        id={colapsibles[1].id}
        onEditClicked={() => gotoPage('employment', employeeInfo)}
      >
        <span className="text-lght">社員番号：</span>
        {employeeInfo.employee_no}
        <br />
        <div className="dis-flex text-left">
          <div className="w-50">
            <span className="text-lght">雇用形態：</span>
            {employeeInfo.employment_status ? employeeInfo.employment_status.div_value_nm : ''}
          </div>
          <div className="w-50">
            <span className="text-lght">業務区分：</span>
            {employeeInfo.job?.div_value_nm}
          </div>
        </div>
        <div className="dis-flex text-left">
          <div className="w-50">
            <span className="text-lght">雇用期間の定め：</span>
            {employeeInfo.employment_term.div_value_nm}
          </div>
          <div className="w-50">
            <span className="text-lght">業務メモ：</span>
            {employeeInfo.job_content}
          </div>
        </div>
        <span className="text-lght">1週間の所定労働時間：</span>
        {employeeInfo.week_work_time_hour}時間
        <br />
        <div className="dis-flex text-left">
          <div className="w-50">
            <span className="text-lght">入社日：</span>
            {computeDate(employeeInfo.joined_ymd)}
          </div>
          <div className="w-50">
            <span className="text-lght">退社日：</span>
            {computeDate(employeeInfo.retire_ymd)}
          </div>
        </div>
        <span className="text-lght">勤続年数：</span>
        {getTimeOfService(employeeInfo.joined_ymd)}
        <br />
        <span className="text-lght">退社メモ：</span>
        {employeeInfo.retire_memo}
        <div className="divider mr-t-md mr-b-md" />
        {employeeInfo.employee_belongs.map((data, index) => (
          <div key={data.dept_cd}>
            <span className="bold">{`所属先 ${index + 1}`}</span>
            <br />
            <span className="text-lght">所属先：</span>
            {data?.department?.dept_maincategory_nm}
            <div className="dis-flex text-left">
              <div className="w-50">
                <span className="text-lght">役職：</span>
                {data.position_label?.div_value_nm}
              </div>
              <div className="w-50">
                <span className="text-lght">グループ：</span>
                {data.group_nm}
              </div>
            </div>
            {selectDocDivById(DOCS_DIV_VALUE_WORKING_CONDITIONS_NOTICE) && (
              <FileHandler
                className="border-slim border-rad-4 bg-sec"
                onEditClicked={() => gotoPage(`workCondition/edit`, employeeInfo)}
              >
                <span>帳票：</span>
                <span className="text-prim">
                  {RELATED_DOCS_DIV_VALUE_NAMES[DOCS_DIV_VALUE_WORKING_CONDITIONS_NOTICE]}
                </span>
                <br />
                <span className="text-lght">更新日：</span>
                <span>{computeDate(employeeInfo.job_contract_update_datetime)}</span>
              </FileHandler>
            )}
          </div>
        ))}
        <h5 className="mr-t-bg">通勤情報</h5>
        {newestCommuteWay && (
          <FileHandler
            className="divider-btm"
            onEditClicked={() =>
              gotoPage(
                `commuteCostApplication/${newestCommuteWay?.commute_way_id}/edit`,
                newestCommuteWay
              )
            }
          >
            <span>帳票：</span>
            <span className="text-prim">通勤手当支給申請</span>
            <br />
            <span className="text-lght">更新日：</span>
            <span>{computeDate(newestCommuteWay?.update_datetime)}</span>
          </FileHandler>
        )}
        {newestCommuteVehicle && (
          <FileHandler
            className="divider-btm"
            onEditClicked={() =>
              gotoPage(
                `vehicleInspection/${newestCommuteVehicle?.commute_vehicle_id}/edit`,
                newestCommuteVehicle
              )
            }
          >
            <span>帳票：</span>
            <span className="text-prim">通勤車両の車検証</span>
            <br />
            <span className="text-lght">更新日：</span>
            <span>{computeDate(newestCommuteVehicle?.update_datetime)}</span>
          </FileHandler>
        )}
        {insuranceTypes.map((type, index) => {
          const typedVehicles = vehicleInsurances.filter((i) => i.insurance_div === type.div_value);
          const newestVehicleInsurance = getNewestByUpdateDatetime(typedVehicles);
          if (!newestVehicleInsurance) return <></>;
          return (
            <FileHandler
              key={index}
              className="divider-btm"
              onEditClicked={() =>
                gotoPage(`vehicleInsurance/${newestVehicleInsurance.id}/edit`, {
                  newestVehicleInsurance,
                  insuranceTypes,
                })
              }
            >
              <span>帳票：</span>
              <span className="text-prim">通勤車両の保険</span>
              <br />
              <span className="text-lght">更新日：</span>
              <span>{computeDate(newestVehicleInsurance.update_datetime)}</span>
              <br />
              <span className="text-lght">区分：</span>
              <span>{type.div_value_nm}</span>
            </FileHandler>
          );
        })}
      </ItemWrapper>
      <ItemWrapper
        title={colapsibles[2].text}
        id={colapsibles[2].id}
        onEditClicked={() => gotoPage('emergencyContact')}
        isAddButton
      >
        {employeeInfo.emg_contacts.map((data, index) => (
          <FileHandler
            key={index}
            className="divider-btm"
            onEditClicked={() => gotoPage('emergencyContact', data)}
          >
            <span>氏名：</span>
            <span className="text-prim">
              {`${data.emg_contact_last_name} ${data.emg_contact_first_name}`}
            </span>
            <div className="dis-flex text-left">
              <div className="w-50">
                <span className="text-lght">氏名カナ：</span>
                <span>{`${data.emg_contact_last_name_kn} ${data.emg_contact_first_name_kn}`}</span>
              </div>
              <div className="w-50">
                <span className="text-lght">登録日：</span>
                <span>{computeDate(data.regist_datetime)}</span>
              </div>
            </div>
            <div className="dis-flex text-left">
              <div className="w-50">
                <span className="text-lght">続柄：</span>
                <span>{data.emg_contact_rel ? data.emg_contact_rel.div_value_nm : ''}</span>
              </div>
              <div className="w-50">
                <span className="text-lght">電話番号：</span>
                {`${data.emg_contact_tel_areacd}-${data.emg_contact_tel_localcd}-${data.emg_contact_tel_number}`}
              </div>
            </div>
          </FileHandler>
        ))}
      </ItemWrapper>
      <ItemWrapper
        title={colapsibles[3].text}
        id={colapsibles[3].id}
        onEditClicked={() =>
          gotoPage('equipmentLoanConfirm', { companyCd: employeeInfo.company_cd })
        }
        isAddButton
      >
        {employeeInfo.fixture_lends.map((data, index) => (
          <FileHandler
            className="divider-btm"
            key={index}
            onEditClicked={() =>
              gotoPage(`equipmentLoanConfirm/${data.lend_seq}`, {
                companyCd: employeeInfo.company_cd,
                data,
              })
            }
          >
            <span>備品区分：</span>
            <span className="text-prim">{data.lend_fixture?.lend_fixture_nm}</span>
            <div className="dis-flex text-left">
              <div className="w-50">
                <span className="text-lght">登録日：</span>
                <span>{computeDate(data.regist_datetime)}</span>
              </div>
              <div className="w-50">
                <span className="text-lght">電話番号：</span>
                <span>{data.tel}</span>
              </div>
            </div>
            <div className="dis-flex text-left">
              <div className="w-50">
                <span className="text-lght">貸与日：</span>
                <span>{computeDate(data.lend_ymd)}</span>
              </div>
              <div className="w-50">
                <span className="text-lght">返却日：</span>
                <span>{computeDate(data.return_ymd)}</span>
              </div>
            </div>
            <span className="text-lght">備考：</span>
            <span>{data.lend_fixture_memo}</span>
          </FileHandler>
        ))}
      </ItemWrapper>
      <ItemWrapper
        title={colapsibles[4].text}
        id={colapsibles[4].id}
        isAddButton
        onEditClicked={() => gotoPage('uniformLoanConfirm', { companyCd: employeeInfo.company_cd })}
      >
        {employeeInfo?.uniform_lends?.map((data, index) => (
          <FileHandler
            className="divider-btm"
            key={index}
            onEditClicked={() =>
              gotoPage(`uniformLoanConfirm/${data.uniform_lend_id}`, {
                companyCd: employeeInfo.company_cd,
                data,
              })
            }
          >
            <div className="dis-flex text-left">
              <div className="w-50">
                <span className="text-lght">種類：</span>
                <span className="text-prim">{data.lend_item?.lend_item_nm}</span>
              </div>
              <div className="w-50">
                <span className="text-lght">枚数：</span>
                <span>{data.lend_num}</span>
              </div>
            </div>
            <div className="dis-flex text-left">
              <div className="w-50">
                <span className="text-lght">サイズ：</span>
                <span>{data.lend_size_rec?.div_value_nm}</span>
              </div>
              <div className="w-50">
                <span className="text-lght">ウェストサイズ：</span>
                <span>{data.waist_size}</span>
              </div>
            </div>
            <div className="dis-flex text-left">
              <div className="w-50">
                <span className="text-lght">貸与日：</span>
                <span>{computeDate(data.lend_ymd)}</span>
              </div>
              <div className="w-50">
                <span className="text-lght">返却日：</span>
                <span>{computeDate(data.return_ymd)}</span>
              </div>
            </div>
          </FileHandler>
        ))}
      </ItemWrapper>
      <ItemWrapper
        title={colapsibles[5].text}
        id={colapsibles[5].id}
        activeDropdown
        firstItemClicked={() => {
          if (employeeInfo?.driver_licenses[0]?.issuance_ymd)
            gotoPage(`driverLicense/${employeeInfo?.driver_licenses[0]?.issuance_ymd}/edit`);
          else gotoPage(`driverLicense/new`);
        }}
        secondItemClicked={() => gotoPage('skill/new')}
      >
        {employeeInfo?.driver_licenses?.length > 0 ? (
          <FileHandler
            className="divider-btm"
            key={0}
            onEditClicked={() =>
              gotoPage(`driverLicense/${employeeInfo?.driver_licenses[0]?.issuance_ymd}/edit`)
            }
          >
            <span>帳票：</span>
            <span className="text-prim">運転免許証</span>
            <br />
            <span className="text-lght">登録日：</span>
            <span>{computeDate(employeeInfo?.driver_licenses[0]?.regist_datetime)}</span>
            <div className="dis-flex text-left">
              <div className="w-50">
                <span className="text-lght">取得年月日：</span>
                <span>{computeDate(employeeInfo?.driver_licenses[0]?.issuance_ymd)}</span>
              </div>
              <div className="w-50">
                <span className="text-lght">有効期限：</span>
                <span>{computeDate(employeeInfo?.driver_licenses[0]?.expiration_date)}</span>
              </div>
            </div>
            <div className="dis-flex text-left">
              <div className="w-50">
                <span className="text-lght">種類：</span>
                <DriverLicensesVariations
                  driverLicensesIndex={0}
                  driverLicenses={employeeInfo.driver_licenses}
                />
              </div>
              <div className="w-50">
                <span className="text-lght">条件：</span>
                <span>{employeeInfo?.driver_licenses[0]?.conditions}</span>
              </div>
            </div>
          </FileHandler>
        ) : (
          <></>
        )}
        {employeeInfo.skills.map((data, index) => (
          <FileHandler
            className="divider-btm"
            key={index}
            onEditClicked={() => gotoPage(`skill/${data.skill_id}/edit`)}
          >
            <span>帳票：</span>
            <span className="text-prim">{data.skill ? data.skill.div_value_nm : ''}</span>
            <br />
            <span className="text-lght">登録日：</span>
            <span>{computeDate(data.regist_datetime)}</span>
            <div className="dis-flex text-left">
              <div className="w-50">
                <span className="text-lght">取得年月日：</span>
                <span>{computeDate(data.issuance_ymd)}</span>
              </div>
              <div className="w-50">
                <span className="text-lght">有効期限：</span>
                <span>{computeDate(data.expiration_date)}</span>
              </div>
            </div>
          </FileHandler>
        ))}
      </ItemWrapper>
      <ItemWrapper
        title={colapsibles[6].text}
        id={colapsibles[6].id}
        isAddButton
        onEditClicked={() => gotoPage('accidentReport/new')}
      >
        {employeeInfo.accident_histories.map((data, index) => (
          <FileHandler
            className="divider-btm"
            key={index}
            onEditClicked={() => gotoPage(`accidentReport/${data.accident_history_id}/edit`)}
          >
            <span>登録日：</span>
            <span className="text-prim">{computeDate(data.regist_datetime)}</span>
            <br />
            <span className="text-lght">事故発生日：</span>
            <span>{computeDate(data.occur_ymd)}</span>
            <br />
            <div className="dis-flex text-left">
              <div className="w-50">
                <span className="text-lght">事故種別：</span>
                <span>{data.accident ? data.accident.div_value_nm : ''}</span>
              </div>
              <div className="w-50">
                <span className="text-lght">補償種別：</span>
                <span>{data.compensation ? data.compensation.div_value_nm : ''}</span>
              </div>
            </div>
            <span className="text-lght">備考：</span>
            <span>{data.freememo}</span>
          </FileHandler>
        ))}
      </ItemWrapper>
      <h5 className="mr-t-bg" id={colapsibles[7].id}>
        {colapsibles[7].text}
      </h5>
      <Table
        data={datas?.related_docs}
        columns={columns}
        onClickRow={onDocListItemClicked}
        noPagin
      />
      <UploadAvatarModal
        avatarUrl={datas?.avatar?.url}
        employeeCD={match.params.id}
        hideModal={() => setIsUploadAvatarModalShown(false)}
        isShown={isUploadAvatarModalShown}
      />
    </div>
  );
}

export default EmployeeDetailInfo;
