/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import axiosInstance from '../../../api/base';
import InputYmd from '../../../components/Input/InputYmd';
import { toIso8601str } from '../../../utils/date';
import CheckBox from '../../../components/Input/CheckBox';
import { setSuccessToast } from '../../../redux/slices/toast';
import HeaderForm from '../../../components/HeaderForm';
import { AUTH_CREATEUPDATE, AUTH_DELETE } from '../../../api/constants';
import { IsAuthorize } from '../../../redux/slices/auth';

function DriverLicenseEdit(props) {
  // eslint-disable-next-line react/destructuring-assignment
  const issuanceDateStr = props.match.params?.issuanceDateStr;
  const editMode = Boolean(issuanceDateStr);
  const [getDate, setGetDate] = useState(null);
  const [licenseNumber, setLicenseNumber] = useState('');
  const [issuanceDate, setIssuanceDate] = useState(null);
  const [condition, setCondition] = useState('');
  const [expirationDate, setExpirationDate] = useState(null);
  const [largeSize, setLargeSize] = useState(false);
  const [midiumSize, setMidiumSize] = useState(false);
  const [regularSize, setRegularSize] = useState(false);
  const [semiMidiumSize, setSemiMidiumSize] = useState(false);
  const [specialLargeSize, setSpecialLargeSize] = useState(false);
  const [largeMotorcycle, setLargeMotorcycle] = useState(false);
  const [regularMotorcycle, setRegularMotorcycle] = useState(false);
  const [specialSmallSize, setSpecialSmallSize] = useState(false);
  const [scootersSize, setScootersSize] = useState(false);
  const [secondLargeSize, setSecondLargeSize] = useState(false);
  const [secondMidiumSize, setSecondMidiumSize] = useState(false);
  const [secondRegularSize, setSecondRegularSize] = useState(false);
  const [secondSpecialLarge, setSecondSpecialLarge] = useState(false);
  const [tractionSize, setTractionSize] = useState(false);
  const [deleteShowing, setDeleteShowing] = useState(false);
  const [isClickSubmit, setIsClickSubmit] = useState(false);

  const multiRadio =
    largeSize ||
    midiumSize ||
    regularSize ||
    semiMidiumSize ||
    specialLargeSize ||
    largeMotorcycle ||
    regularMotorcycle ||
    specialSmallSize ||
    scootersSize ||
    secondLargeSize ||
    secondMidiumSize ||
    secondRegularSize ||
    secondSpecialLarge ||
    tractionSize;

  const dispatch = useDispatch();

  const deleteThisCheckup = () => {
    axiosInstance
      .delete(
        `/employees/${props.match.params.id}/employee_driver_licenses/${props.match.params.issuanceDateStr}`
      )
      .then((response) => {
        if (response.data) props.history.push(`/employee/${props.match.params.id}/driverLicense`);
      });
  };

  const dataSave = async () => {
    setIsClickSubmit(true);
    if (licenseNumber && issuanceDate && expirationDate && multiRadio) {
      const params = {
        employee_cd: props.match.params.id,
        license_number: licenseNumber,
        get_ymd: getDate ? toIso8601str(getDate) : null,
        conditions: condition,
        issuance_ymd: toIso8601str(issuanceDate),
        expiration_date: toIso8601str(expirationDate),
        large_size: largeSize,
        midium_size: midiumSize,
        regular_size: regularSize,
        semi_midium_size: semiMidiumSize,
        special_large_size: specialLargeSize,
        large_motorcycle: largeMotorcycle,
        regular_motorcycle: regularMotorcycle,
        special_small_size: specialSmallSize,
        scooters: scootersSize,
        second_large_size: secondLargeSize,
        second_midium_size: secondMidiumSize,
        second_regular_size: secondRegularSize,
        second_special_large: secondSpecialLarge,
        traction: tractionSize,
      };
      let response = null;
      if (editMode) {
        response = await axiosInstance
          .patch(
            `/employees/${props.match.params.id}/employee_driver_licenses/${issuanceDateStr}`,
            params
          )
          .then((res) => {
            if (res.data) dispatch(setSuccessToast(true));
          });
      } else {
        response = await axiosInstance
          .post(`/employees/${props.match.params.id}/employee_driver_licenses`, params)
          .then((res) => {
            if (res.data) dispatch(setSuccessToast(true));
          });
      }
      if (response?.status >= 500) {
        throw response.status;
      }
      props.history.push(`/employee/${props.match.params.id}/driverLicense`);
    }
  };

  useEffect(() => {
    const init = async () => {
      if (editMode) {
        const res = await axiosInstance.get(
          `/employees/${props.match.params.id}/employee_driver_licenses/${issuanceDateStr}`
        );
        setLicenseNumber(res.data.license_number);
        setGetDate(res.data.get_ymd);
        setIssuanceDate(res.data.issuance_ymd);
        setCondition(res.data.conditions);
        setExpirationDate(res.data.expiration_date);
        setLargeSize(res.data.large_size);
        setMidiumSize(res.data.midium_size);
        setRegularSize(res.data.regular_size);
        setSemiMidiumSize(res.data.semi_midium_size);
        setSpecialLargeSize(res.data.special_large_size);
        setLargeMotorcycle(res.data.large_motorcycle);
        setRegularMotorcycle(res.data.regular_motorcycle);
        setSpecialSmallSize(res.data.special_small_size);
        setScootersSize(res.data.scooters);
        setSecondLargeSize(res.data.second_large_size);
        setSecondMidiumSize(res.data.second_midium_size);
        setSecondRegularSize(res.data.second_regular_size);
        setSecondSpecialLarge(res.data.second_special_large);
        setTractionSize(res.data.traction);
      }
    };
    init();
  }, []);

  return (
    <>
      <div className="content driver-license w-form">
        <HeaderForm
          isInfo
          deleteShowing={deleteShowing}
          setDeleteShowing={setDeleteShowing}
          deleteThisCheckup={deleteThisCheckup}
          haveBtnDelete={props.match.params.issuanceDateStr && IsAuthorize(AUTH_DELETE)}
        />
        <div className="driver-license-content">
          <div className="form">
            <Form.Group as={Row} className="mb-6 pos-rel">
              <Form.Label column sm="2" className="ml-10 required">
                免許証番号
              </Form.Label>
              <Col sm={5} className="select-list">
                <Form.Control
                  type="text"
                  size="lg"
                  className="form-text"
                  onChange={(e) => setLicenseNumber(e.currentTarget.value)}
                  value={licenseNumber}
                  isInvalid={!licenseNumber && isClickSubmit}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-10">
              <Form.Label column sm="2" className="ml-10">
                取得年月日
              </Form.Label>
              <Col sm={8} className="select-list">
                <InputYmd initialDate={getDate} returnDate={setGetDate} />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-6">
              <Form.Label column sm="2" className="ml-10 required">
                種類
              </Form.Label>
              <Col sm={6} className="checkbox-list">
                <div className="element">
                  <CheckBox
                    isInvalid={!multiRadio}
                    label="大型自動車免許（大型）"
                    checked={largeSize}
                    onChange={(e) => setLargeSize(e.currentTarget.checked)}
                  />
                  <CheckBox
                    isInvalid={!multiRadio}
                    label="中型自動車免許（中型）"
                    checked={midiumSize}
                    onChange={(e) => setMidiumSize(e.currentTarget.checked)}
                  />
                  <CheckBox
                    isInvalid={!multiRadio}
                    label="普通自動車免許（普通）"
                    checked={regularSize}
                    onChange={(e) => setRegularSize(e.currentTarget.checked)}
                  />
                  <CheckBox
                    isInvalid={!multiRadio}
                    label="準中型自動車運転免許（準中型）"
                    checked={semiMidiumSize}
                    onChange={(e) => setSemiMidiumSize(e.currentTarget.checked)}
                  />
                  <CheckBox
                    isInvalid={!multiRadio}
                    label="大型特殊自動車免許（大特）"
                    checked={specialLargeSize}
                    onChange={(e) => setSpecialLargeSize(e.currentTarget.checked)}
                  />
                  <CheckBox
                    isInvalid={!multiRadio}
                    label="大型自動二輪車免許（大自二）"
                    checked={largeMotorcycle}
                    onChange={(e) => setLargeMotorcycle(e.currentTarget.checked)}
                  />
                  <CheckBox
                    isInvalid={!multiRadio}
                    label="普通自動二輪車免許（普自二）"
                    checked={regularMotorcycle}
                    onChange={(e) => setRegularMotorcycle(e.currentTarget.checked)}
                  />
                  <CheckBox
                    isInvalid={!multiRadio}
                    label="小型特殊自動車免許（小特）"
                    checked={specialSmallSize}
                    onChange={(e) => setSpecialSmallSize(e.currentTarget.checked)}
                  />
                  <CheckBox
                    isInvalid={!multiRadio}
                    label="原動機付自転車免許（原付）"
                    checked={scootersSize}
                    onChange={(e) => setScootersSize(e.currentTarget.checked)}
                  />
                  <CheckBox
                    isInvalid={!multiRadio}
                    label="大型自動車第二種運転免許（大二）"
                    checked={secondLargeSize}
                    onChange={(e) => setSecondLargeSize(e.currentTarget.checked)}
                  />
                  <CheckBox
                    isInvalid={!multiRadio}
                    label="中型自動車第二種運転免許（中二）"
                    checked={secondMidiumSize}
                    onChange={(e) => setSecondMidiumSize(e.currentTarget.checked)}
                  />
                  <CheckBox
                    isInvalid={!multiRadio}
                    label="普通自動車第二種運転免許（普二）"
                    checked={secondRegularSize}
                    onChange={(e) => setSecondRegularSize(e.currentTarget.checked)}
                  />
                  <CheckBox
                    isInvalid={!multiRadio}
                    label="大型特殊自動車第二種免許（大特二）"
                    checked={secondSpecialLarge}
                    onChange={(e) => setSecondSpecialLarge(e.currentTarget.checked)}
                  />
                  <CheckBox
                    isInvalid={!multiRadio}
                    label="牽引自動車第一種運転免許（け引）"
                    checked={tractionSize}
                    onChange={(e) => setTractionSize(e.currentTarget.checked)}
                  />
                </div>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-6">
              <Form.Label column sm="2" className="ml-10">
                条件
              </Form.Label>
              <Col sm={8} className="select-list">
                <Form.Control
                  as="textarea"
                  size="lg"
                  rows={1}
                  className="form-textarea"
                  onChange={(e) => setCondition(e.currentTarget.value)}
                  value={condition}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-6">
              <Form.Label column sm="2" className="ml-10 required">
                交付年月日
              </Form.Label>
              <Col sm={8} className="select-list">
                <InputYmd
                  initialDate={issuanceDate}
                  returnDate={setIssuanceDate}
                  isClickSubmit={isClickSubmit}
                  require
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-6">
              <Form.Label column sm="2" className="ml-10 required">
                有効期限
              </Form.Label>
              <Col sm={8} className="select-list">
                <InputYmd
                  initialDate={expirationDate}
                  returnDate={setExpirationDate}
                  isExpirationDate
                  isClickSubmit={isClickSubmit}
                  require
                />
              </Col>
            </Form.Group>
          </div>
        </div>
        <div className="dis-flex flex-center submit-wrapper">
          <Button
            className="save-btn"
            disabled={!IsAuthorize(AUTH_CREATEUPDATE)}
            onClick={() => dataSave()}
          >
            登録
          </Button>
        </div>
      </div>
    </>
  );
}

export default DriverLicenseEdit;
