import { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import HistoryList from '../../../components/HistoryList';
import FileHandler from '../../../components/FileHandler';
import InfoPageHeader from '../../../components/InfoPageHeader';
import axiosInstance from '../../../api/base';
import { DOCS_DIV_VALUE_ACCIDENT_REPORT } from '../../../api/constants';
import { computeDate } from '../../../utils/convert';
import { RELATED_DOCS_DIV_VALUE_NAMES } from '../../../utils/const';

export default function AccidentReportInfo(props) {
  const [accidentReports, setAccidentReports] = useState([]);
  const [images, setImages] = useState([]);

  useEffect(() => {
    axiosInstance
      .get(
        `/employees/${props.match.params.id}/employee_related_docs/test/div/${DOCS_DIV_VALUE_ACCIDENT_REPORT}`
      )
      .then((response) => {
        setImages(response.data);
      });

    axiosInstance.get(`employees/${props.match.params.id}/accident_reports`).then((response) => {
      setAccidentReports(response.data);
    });
  }, []);

  const gotoPage = (subroute) => {
    props.history.push(`/employee/${props.match.params.id}/accidentReport/${subroute}`);
  };

  return (
    <div className="content">
      <Row>
        <Col>
          <InfoPageHeader onCreate={() => gotoPage('new')} />
          {accidentReports.length > 0 && (
            <FileHandler
              className="divider-btm"
              onEditClicked={() => gotoPage(`${accidentReports[0].accident_history_id}/edit`)}
            >
              <span>更新日：</span>
              <span className="text-prim">{computeDate(accidentReports[0].update_datetime)}</span>
              <br />
              <span className="text-lght">事故発生日：</span>
              <span>{computeDate(accidentReports[0].occur_ymd)}</span>
              <br />
              <span className="text-lght">事故種別：</span>
              <span>{accidentReports[0].accident.div_value_nm}</span>
              <br />
              <span className="text-lght">補償種別：</span>
              <span>{accidentReports[0].compensation.div_value_nm}</span>
            </FileHandler>
          )}
        </Col>
        <Col>
          <InfoPageHeader onCreate={() => gotoPage('uploadRelatedDocs')} imageHeader />
          {images[0] && (
            <FileHandler
              imageHandler
              // style={{ height: '156px' }}
              className="divider-btm"
              onEditClicked={() => gotoPage(`editUploadedRelatedDocs/${images[0].related_docs_id}`)}
            >
              <span>更新日：</span>
              <span className="text-prim">{computeDate(images[0].related_docs_ymd)}</span>
              <br />
              <span className="text-lght">帳票区分：</span>
              <span>{RELATED_DOCS_DIV_VALUE_NAMES[DOCS_DIV_VALUE_ACCIDENT_REPORT]}</span>
            </FileHandler>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <HistoryList list={accidentReports}>
            {accidentReports.slice(1).map((report, key) => (
              <FileHandler
                className="divider-btm"
                key={key}
                onEditClicked={() => gotoPage(`${report.accident_history_id}/edit`)}
              >
                <span>更新日：</span>
                <span className="text-prim">{computeDate(report.update_datetime)}</span>
                <br />
                <span className="text-lght">事故発生日：</span>
                <span>{computeDate(report.occur_ymd)}</span>
                <br />
                <span className="text-lght">事故種別：</span>
                <span>{report.accident.div_value_nm}</span>
                <br />
                <span className="text-lght">補償種別：</span>
                <span>{report.compensation.div_value_nm}</span>
              </FileHandler>
            ))}
          </HistoryList>
        </Col>
        <Col>
          <HistoryList list={images}>
            {images.slice(1).map((image, key) => (
              <FileHandler
                centerEdit
                imageHandler
                grayIcon
                className="mt-1 divider-btm"
                key={key}
                // style={{ height: '156px' }}
                onEditClicked={() => gotoPage(`editUploadedRelatedDocs/${image.related_docs_id}`)}
              >
                <span>更新日：</span>
                <span className="text-prim">{computeDate(image.related_docs_ymd)}</span>
                <br />
                <span className="text-lght">帳票区分：</span>
                <span>{RELATED_DOCS_DIV_VALUE_NAMES[DOCS_DIV_VALUE_ACCIDENT_REPORT]}</span>
              </FileHandler>
            ))}
          </HistoryList>
        </Col>
      </Row>
    </div>
  );
}
