export const PREFECTURES = [
  '北海道',
  '青森県',
  '岩手県',
  '宮城県',
  '秋田県',
  '山形県',
  '福島県',
  '茨城県',
  '栃木県',
  '群馬県',
  '埼玉県',
  '千葉県',
  '東京都',
  '神奈川県',
  '新潟県',
  '富山県',
  '石川県',
  '福井県',
  '山梨県',
  '長野県',
  '岐阜県',
  '静岡県',
  '愛知県',
  '三重県',
  '滋賀県',
  '京都府',
  '大阪府',
  '兵庫県',
  '奈良県',
  '和歌山県',
  '鳥取県',
  '島根県',
  '岡山県',
  '広島県',
  '山口県',
  '徳島県',
  '香川県',
  '愛媛県',
  '高知県',
  '福岡県',
  '佐賀県',
  '長崎県',
  '熊本県',
  '大分県',
  '宮崎県',
  '鹿児島県',
  '沖縄県',
];

export const RELATED_DOCS_DIV_VALUE_NAMES = [
  '', // zero index filler, since enum.py RelatedDocsDivValue starts at 1
  '履歴書',
  '職務経歴書',
  '年金手帳',
  '障害者手帳',
  '源泉徴収票',
  '契約情報',
  '雇用契約書',
  '身元保証書',
  '通勤経路',
  '委任状',
  '市県民納付書',
  '住民票',
  '健康診断書',
  '運転記録証明書',
  '適正診断結果',
  '運転免許証',
  '通勤車両車検証',
  '通勤車両保険',
  '在留カード',
  'マイナンバー',
  '雇用保険証',
  '資格者証',
  '健康保険証',
  '誓約書',
  '銀行口座',
  '扶養控除等異動申告書',
  '住居情報',
  '家族情報',
  '緊急連絡先',
  '通勤手当',
  '作業着/制服',
  '備品',
  '事故情報',
  '返済情報',
  '顔写真',
  'その他',
  '運転日報',
];

export const REGEX_BOTH_FULL_HALF_NUMBER = /^[0-9０-９]+$/;
export const REGEX_EMAIL_FULLWIDTH =
  /^[\w-.。Ａ-Ｚ０-９ａ-ｚ－＿]+@([\w-Ａ-Ｚ０-９ａ-ｚ]+[.。])+[\w-]{2,4}$/g;
export const REGEX_KATAKANA = /^([ァ-ンｧ-ﾝﾞﾟ]|ー)+$/;
export const REGEX_ALPHABET = /^[a-zA-Z ]*$/;

// msg valdidation

export const MSG_VALIDATE_KATAKANA = 'カタカナで入力してください。';
export const MSG_VALIDATE_ALPHABET = 'ローマ字で入力してください。';

export const RANGE_OPTION_DATE = {
  EMPLOYMENT_START_YMD: 1,
  EMPLOYMENT_END_YMD: 5,
  JOINED_YMD: 1,
  DISABLE_HB_ISSUANCE_YMD: 0,
  INSPECTION_EXPIRATION_DATE: 5,
  COMMUTE_START_YMD: 1,
  FMLY_BIRTHDAY: -10,
  REPAYMENT_START_YM: 1,
  REPAYMENT_END_YM: 10,
  EXPIRATION_DATE: 10,
  CHECKUP_PLANS_YMD: 2,
  CHECKUP_RES_EXPIRED_YMD: 5,
  CONSULT_PLANS_YMD: 2,
};
