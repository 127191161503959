import { useState, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import InputDateForm from '../../../components/common/form/InputDateForm';
import InputDropdown from '../../../components/common/form/InputDropdown';
import axiosInstance from '../../../api/base';
import { AUTH_CREATEUPDATE, AUTH_DELETE, DIV_ID_DIAGNOSIS_RESULT } from '../../../api/constants';
import { generateDateString } from '../../../utils/convert';
import CheckBox from '../../../components/Input/CheckBox';
import { setSuccessToast } from '../../../redux/slices/toast';
import InputTextArea from '../../../components/common/form/InputTextArea';
import HeaderForm from '../../../components/HeaderForm';
import { IsAuthorize } from '../../../redux/slices/auth';

export default function HealthCertEdit(props) {
  const [healthCertificate, setHealthCert] = useState({});
  const [deleteShowing, setDeleteShowing] = useState(false);
  const [diagnosisResults, setResults] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    axiosInstance.get(`/divs/${DIV_ID_DIAGNOSIS_RESULT}`).then((response) => {
      setResults(response.data.sort((cur, next) => cur.display_order - next.display_order));
    });
    if (props.match.params.healthCheckupId && !props.location.state) {
      axiosInstance
        .get(
          `/employees/${props.match.params.id}/employee_health_checkups/${props.match.params.healthCheckupId}`
        )
        .then((response) => {
          const consultPlanDate = response.data.consult_plans_ymd?.split('-') || [];
          const consultDate = response.data.consult_ymd?.split('-') || [];

          setHealthCert({
            ...response.data,
            consult_plans_ymd_year: parseInt(consultPlanDate[0], 10) || '',
            consult_plans_ymd_month: parseInt(consultPlanDate[1], 10) || '',
            consult_plans_ymd_day: parseInt(consultPlanDate[2], 10) || '',
            consult_ymd_year: parseInt(consultDate[0], 10) || '',
            consult_ymd_month: parseInt(consultDate[1], 10) || '',
            consult_ymd_day: parseInt(consultDate[2], 10) || '',
          });
        });
    } else if (props.match.params.healthCheckupId && props.location.state) {
      const consultPlanDate = props.location.state.consult_plans_ymd?.split('-') || [];
      const consultDate = props.location.state.consult_ymd?.split('-') || [];

      setHealthCert({
        ...props.location.state,
        consult_plans_ymd_year: parseInt(consultPlanDate[0], 10) || '',
        consult_plans_ymd_month: parseInt(consultPlanDate[1], 10) || '',
        consult_plans_ymd_day: parseInt(consultPlanDate[2], 10) || '',
        consult_ymd_year: parseInt(consultDate[0], 10) || '',
        consult_ymd_month: parseInt(consultDate[1], 10) || '',
        consult_ymd_day: parseInt(consultDate[2], 10) || '',
      });
    } else {
      setHealthCert({
        consult_plans_ymd_year: '',
        consult_plans_ymd_month: '',
        consult_plans_ymd_day: '',
        undecided_flag: false,
        consult_ymd_year: '',
        consult_ymd_month: '',
        consult_ymd_day: '',
        checkup_res_div: null,
        checkup_res_freememo: '',
      });
    }
  }, []);

  const handleSubmitSend = (params, { setErrors }) => {
    const consultPlan = generateDateString(
      params.consult_plans_ymd_year,
      params.consult_plans_ymd_month,
      params.consult_plans_ymd_day
    );
    if (!consultPlan && !params.undecided_flag) {
      setErrors({
        consult_plans_ymd_month: ' ',
        consult_plans_ymd_year: ' ',
        consult_plans_ymd_day: ' ',
      });
    } else
      axiosInstance({
        method: props.match.params.healthCheckupId ? 'patch' : 'post',
        url: `/employees/${props.match.params.id}/employee_health_checkups/${
          props.match.params.healthCheckupId || ''
        }`,
        data: {
          ...params,
          consult_plans_ymd: consultPlan,
          consult_ymd: params.consult_ymd_day
            ? generateDateString(
                params.consult_ymd_year,
                params.consult_ymd_month,
                params.consult_ymd_day
              )
            : null,
        },
      }).then((response) => {
        if (response.data) {
          dispatch(setSuccessToast(true));
          props.history.push(`/employee/${props.match.params.id}/healthCert`);
        }
      });
  };
  const deleteThisCheckup = () => {
    axiosInstance
      .delete(
        `/employees/${props.match.params.id}/employee_health_checkups/${props.match.params.healthCheckupId}`
      )
      .then((response) => {
        if (response.data) props.history.push(`/employee/${props.match.params.id}/healthCert`);
      });
  };
  return (
    <div className="content w-form">
      <HeaderForm
        deleteShowing={deleteShowing}
        setDeleteShowing={setDeleteShowing}
        deleteThisCheckup={deleteThisCheckup}
        // eslint-disable-next-line react/destructuring-assignment
        haveBtnDelete={props.match.params.healthCheckupId && IsAuthorize(AUTH_DELETE)}
      />
      <Formik onSubmit={handleSubmitSend} enableReinitialize initialValues={healthCertificate}>
        {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <div className="mt-6 bg-gray2 pa-16 pos-rel">
              <div className="w-90">
                <InputDateForm
                  title="受診予定日"
                  titleRequired
                  handleChange={handleChange}
                  valuePrefix="consult_plans_ymd"
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  disabled={values.undecided_flag}
                />
                <Row>
                  <Form.Group as={Col} md={{ span: 9, offset: 3 }} className="mb-3">
                    <CheckBox
                      name="undecided_flag"
                      label="受診予定日は未定"
                      checked={values.undecided_flag}
                      onChange={handleChange}
                      isInvalid={!values.undecided_flag && !values.consult_plans_ymd_year}
                      disabled={values.consult_plans_ymd_year}
                    />
                  </Form.Group>
                </Row>
                <InputDateForm
                  title="受診日"
                  handleChange={handleChange}
                  valuePrefix="consult_ymd"
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                />
                <InputDropdown
                  title="診断結果区分"
                  values={values}
                  errors={errors}
                  touched={touched}
                  fieldKey="checkup_res_div"
                  handleChange={handleChange}
                  options={diagnosisResults}
                  optionKey="div_value"
                  optionName="div_value_nm"
                  notRequired
                />
                <InputTextArea
                  name="checkup_res_freememo"
                  values={values}
                  handleChange={handleChange}
                />
              </div>
            </div>
            <div className="dis-flex flex-center submit-wrapper">
              <button className="save-btn" type="submit" disabled={!IsAuthorize(AUTH_CREATEUPDATE)}>
                登録
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
