import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import axiosInstance from '../../../api/base';
import { DOCS_DIV_VALUE_HEALTH_INSURANCE_CARD } from '../../../api/constants';
import InfoPageHeader from '../../../components/InfoPageHeader';
import FileHandler from '../../../components/FileHandler';
import { computeDate } from '../../../utils/convert';

export default function EmployeeHealthInsuranceCardIndex({ history, match }) {
  const [employeeHealthInsuranceCard, setEmployeeHealthInsuranceCard] = useState({
    ehiUpdateDatetime: '',
  });

  const [uploadedImages, setUploadedImages] = useState([
    { relatedDocsYmd: null, relatedDocsId: 0 },
  ]);

  useEffect(() => {
    axiosInstance
      .get(`/employees/${match.params.id}/employee_health_insurance_card`)
      .then(({ data }) => {
        setEmployeeHealthInsuranceCard({
          ehiUpdateDatetime: data && data.ehi_update_datetime,
        });
      });

    axiosInstance
      .get(
        `/employees/${match.params.id}/employee_related_docs/test/div/${DOCS_DIV_VALUE_HEALTH_INSURANCE_CARD}`
      )
      .then(({ data }) => {
        setUploadedImages(
          [...data].map((item) => ({
            relatedDocsYmd: item.related_docs_ymd,
            relatedDocsId: item.related_docs_id,
          }))
        );
      });

    return () => {
      setEmployeeHealthInsuranceCard({});
      setUploadedImages({});
    };
  }, []);

  const editEmployeeHealthInsuranceCard = () => {
    history.push({
      pathname: `/employee/${match.params.id}/healthInsuranceCard/edit`,
    });
  };

  const gotoPage = (subroute) => {
    history.push({
      pathname: `/employee/${match.params.id}/healthInsuranceCard/${subroute}`,
    });
  };

  return (
    <div className="content">
      <Row>
        <Col>
          <InfoPageHeader hideButton />
          <FileHandler
            centerEdit
            className="divider-btm"
            onEditClicked={() => editEmployeeHealthInsuranceCard()}
          >
            <span>更新日：</span>
            <span className="text-prim">
              {computeDate(employeeHealthInsuranceCard.ehiUpdateDatetime)}
            </span>
          </FileHandler>
        </Col>
        <Col>
          <InfoPageHeader onCreate={() => gotoPage('uploadRelatedDocs')} imageHeader />
          {uploadedImages.length >= 1 && (
            <FileHandler
              centerEdit
              imageHandler
              className="divider-btm"
              onEditClicked={() =>
                gotoPage(`editUploadedRelatedDocs/${uploadedImages[0].relatedDocsId}`)
              }
            >
              <span>登録日：</span>
              <span className="text-prim">{computeDate(uploadedImages[0].relatedDocsYmd)}</span>
            </FileHandler>
          )}
          {!uploadedImages.length && (
            <div className="no-data-txt-sm sz-16 w-100">該当データなし</div>
          )}
        </Col>
      </Row>

      {uploadedImages.length > 1 && (
        <div className="mt-6 bg-gray pa-16">
          <div className="mb-4 text-lght">以前アップロードされた画像</div>
          <div className="divider-btm" />
          {uploadedImages.slice(1).map((image, key) => (
            <FileHandler
              centerEdit
              imageHandler
              grayIcon
              className="divider-btm"
              key={key}
              onEditClicked={() => gotoPage(`editUploadedRelatedDocs/${image.relatedDocsId}`)}
            >
              <span>更新日：</span>
              <span className="text-prim">{computeDate(image.relatedDocsYmd)}</span>
            </FileHandler>
          ))}
        </div>
      )}
      <Row>
        <Col md={{ span: 6, offset: 6 }}>
          {!uploadedImages.length && (
            <div className="no-data-txt-sm sz-16 w-100">該当データなし</div>
          )}
        </Col>
      </Row>
    </div>
  );
}
