import moment from 'moment/moment';
import 'moment/locale/ja';

const thisYear = new Date().getFullYear();
const yearList = Array.from(new Array(100).keys(), (i) => thisYear - i);

// get list year after numberYear
export const generateListOptionYear = (numberYear = 0) => {
  const listYear = Array.from(new Array(100).keys(), (i) => thisYear + numberYear - i);
  const listOptionYear = listYear.map((item) => ({
    value: item,
    label: `${item}年(${moment(String(item)).locale('ja').format('NNNNyo')})`,
  }));
  return listOptionYear;
};

export const yearOptionList = yearList.map((item) => ({
  value: item,
  label: `${item}年(${moment(String(item)).locale('ja').format('NNNNyo')})`,
}));

export const listOptionExpirationYear = generateListOptionYear(5);
export const listOptionBirthdayYear = generateListOptionYear(-10);

const monthList = Array.from(new Array(12).keys(), (i) => i + 1);
export const monthOptionList = monthList.map((item) => ({ value: item, label: item }));

export function getDayListInMonth(month, year) {
  if (!month || !year) {
    return [];
  }
  const date = new Date(year, month - 1, 1);
  const days = [];
  while (date.getMonth() === month - 1) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return days;
}

export function getDayOptionListInMonth(month, year) {
  const days = getDayListInMonth(month, year);
  const dayOptionList = days.map((day) => ({ value: day.getDate(), label: day.getDate() }));
  return dayOptionList;
}

export function getYYYY(dateStr) {
  if (dateStr && new Date(dateStr)) {
    return new Date(dateStr).getFullYear();
  }
  return null;
}

export function getMM(dateStr) {
  if (dateStr && new Date(dateStr)) {
    return new Date(dateStr).getMonth() + 1;
  }
  return null;
}

export function getDD(dateStr) {
  if (dateStr && new Date(dateStr)) {
    return new Date(dateStr).getDate();
  }
  return null;
}

export function toYYYYMMDD(y, m, d) {
  if (y && m && d) {
    return `${y}-${m < 10 ? `0${m}` : m}-${d < 10 ? `0${d}` : d}`;
  }
  return null;
}

/**
 * 日付形式に変換して返す
 * @param {moment.Moment} momentInstance
 * @param {string} formatString default:YYYY-MM-DD
 * @returns {string}
 */
export function toUtcIso8601str(selectedDate, formatString) {
  if (!formatString) {
    formatString = 'YYYY-MM-DD';
  }
  return moment(selectedDate).clone().utc().format(formatString);
}

/**
 * 日付形式に変換して返す
 * @param {moment.Moment} momentInstance
 * @param {string} formatString default:YYYY-MM-DD
 * @returns {string}
 */
export function toIso8601str(selectedDate, formatString) {
  if (!formatString) {
    formatString = 'YYYY-MM-DD';
  }
  return moment(selectedDate).clone().format(formatString);
}

export function getTimeOfService(date) {
  const now = moment(moment().format('YYYY-MM')); // "now"
  const joinedDate = moment(moment(date, 'YYYY-MM-DD').format('YYYY-MM')); // some date
  const years = now.diff(joinedDate, 'years');
  const months = now.diff(joinedDate, 'months');
  const rs = years > 0 ? `${years}年${months % 12}ヶ月` : `${months % 12}ヶ月`;
  return rs;
}
