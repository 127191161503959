/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import * as yup from 'yup';
import { Formik, FieldArray } from 'formik';
import { useDispatch } from 'react-redux';
import { setSuccessToast } from '../../../redux/slices/toast';
import InputDateForm from '../../../components/common/form/InputDateForm';
import InputDropdown from '../../../components/common/form/InputDropdown';
import InputText from '../../../components/common/form/InputText';
import iconMinus from '../../../assets/images/icon-minus.svg';
import ChangeRecordButton from '../../../components/common/button/AddRecordButton';
import axiosInstance from '../../../api/base';
import {
  DIV_ID_EMPLOYMENT_TYPE,
  DIV_ID_EMPLOYMENT_TERM,
  DIV_ID_POSITION,
  AUTH_CREATEUPDATE,
} from '../../../api/constants';
import { generateDateString } from '../../../utils/convert';
import { handleBlur, handleChangePhone } from '../../../utils/handleInput';
import { REGEX_EMAIL_FULLWIDTH } from '../../../utils/const';
import { IsAuthorize } from '../../../redux/slices/auth';

const Schema = yup.object().shape({
  employee_no: yup.string().required(' '),
  employment_status_div: yup.number().required(' '),
  employment_term_div: yup.number().required(' '),
  enroll_status_div: yup.number().nullable().required(' '),
  job_content: yup.string().nullable().notRequired(),
  week_work_time_hour: yup
    .string()
    .nullable()
    .matches(/^[0-9]+$/, '半角数字で入力'),
  week_work_time_minute: yup
    .string()
    .nullable()
    .matches(/^[0-9]+$/, '半角数字で入力'),
  joined_ymd_year: yup.number().required(' '),
  joined_ymd_month: yup.number().required(' '),
  joined_ymd_day: yup.number().required(' '),
  mail_address_sub: yup
    .string()
    .notRequired()
    .nullable()
    .test('is-email', '有効なメールアドレスを入力する必要があります', (value) => {
      const check = value?.match(REGEX_EMAIL_FULLWIDTH);
      return !value || (check && check[0] === value);
    }),
  employee_belongs: yup.array().of(
    yup.object().shape({
      dept_cd: yup.number().required(' ').nullable(),
      position: yup.number().required(' ').nullable(),
      group_nm: yup.string().notRequired().nullable(),
    })
  ),
});

export default function Employment(props) {
  const dispatch = useDispatch();

  const [employeeInfo, setEmployeeInfo] = useState({});
  const [jobs, setJobs] = useState([]);
  const [enrollStatuses, setEnrollStatuses] = useState([]);
  const [terms, setTerms] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [positions, setPositions] = useState([]);
  const [belongsToDelete, setBelongsToDelete] = useState([]);
  const [departmentErrors, setDepartmentErrors] = useState([]);

  const handleSubmitEmployment = async (values) => {
    const params = {
      ...values,
      joined_ymd: generateDateString(
        values.joined_ymd_year,
        values.joined_ymd_month,
        values.joined_ymd_day
      ),
      retire_ymd: values.retire_ymd_day
        ? generateDateString(values.retire_ymd_year, values.retire_ymd_month, values.retire_ymd_day)
        : null,
    };
    const response = await axiosInstance.patch(
      `/employees/${props.match.params.id}/employeement`,
      params
    );
    if (response.status >= 500) {
      throw response.status;
    }
    const sanitizedBelongs = params.employee_belongs.map((unsantizedBelong, idx) => ({
      ...unsantizedBelong,
      department: undefined,
      position_label: undefined,
      position: Number(unsantizedBelong.position),
      main_belong_flag: idx === 0 ? 1 : 0,
    }));
    const belongsToPatch = sanitizedBelongs.filter((belong) => !belong.isNewRecord);
    const belongsToPost = sanitizedBelongs.filter((belong) => belong.isNewRecord);
    setBelongsToDelete(
      belongsToDelete.map((unsantizedBelong) => ({
        company_cd: unsantizedBelong.company_cd,
        dept_cd: unsantizedBelong.dept_cd,
        employee_cd: unsantizedBelong.employee_cd,
        apply_start_ymd: unsantizedBelong.apply_start_ymd,
      }))
    );
    if (belongsToPatch.length) {
      const belongsToPatchResponse = await axiosInstance.patch(
        `employees/${props.match.params.id}/belong/update`,
        belongsToPatch
      );
      if (belongsToPatchResponse.status >= 500) {
        throw response.status;
      }
    }
    if (belongsToPost.length) {
      const belongsToPostResponse = await axiosInstance.post(
        `employees/${props.match.params.id}/belong/create`,
        belongsToPost.map((belong) => ({
          ...belong,
          employee_cd: props.match.params.id,
        }))
      );
      if (belongsToPostResponse.status >= 500) {
        throw response.status;
      }
    }
    if (belongsToDelete.length) {
      const belongsToDeleteResponse = await axiosInstance.patch(
        `employees/${props.match.params.id}/belong/delete`,
        belongsToDelete
      );
      if (belongsToDeleteResponse.status >= 500) {
        throw response.status;
      }
    }
    dispatch(setSuccessToast(true));
    props.history.push(`/employee/${props.match.params.id}`);
  };

  useEffect(() => {
    const id = props.match.params.id || '1093809';
    if (props.location.state) {
      const joinedDate = props.location.state.joined_ymd.split('-') || [];
      const retireDate = props.location.state.retire_ymd?.split('-') || [];
      setEmployeeInfo({
        ...props.location.state,
        joined_ymd_year: parseInt(joinedDate[0], 10) || '',
        joined_ymd_month: parseInt(joinedDate[1], 10) || '',
        joined_ymd_day: parseInt(joinedDate[2], 10) || '',
        retire_ymd_year: parseInt(retireDate[0], 10) || '',
        retire_ymd_month: parseInt(retireDate[1], 10) || '',
        retire_ymd_day: parseInt(retireDate[2], 10) || '',
      });
    } else {
      axiosInstance.get(`/employees/${id}`).then((response) => {
        const joinedDate = response.data?.employee_info.joined_ymd?.split('-') || [];
        const retireDate = response.data?.employee_info.retire_ymd?.split('-') || [];
        setEmployeeInfo({
          ...response.data.employee_info,
          joined_ymd_year: parseInt(joinedDate[0], 10) || '',
          joined_ymd_month: parseInt(joinedDate[1], 10) || '',
          joined_ymd_day: parseInt(joinedDate[2], 10) || '',
          retire_ymd_year: parseInt(retireDate[0], 10) || '',
          retire_ymd_month: parseInt(retireDate[1], 10) || '',
          retire_ymd_day: parseInt(retireDate[2], 10) || '',
        });
      });
    }

    // Maybe this can be put into store for mutiple pages
    axiosInstance.get('/divs/job').then((response) => {
      setJobs(response.data.sort((cur, next) => cur.display_order - next.display_order));
    });
    axiosInstance.get('/divs/enroll_status').then((response) => {
      setEnrollStatuses(response.data.sort((cur, next) => cur.display_order - next.display_order));
    });
    axiosInstance.get(`/divs/${DIV_ID_EMPLOYMENT_TERM}`).then((response) => {
      setTerms(response.data.sort((cur, next) => cur.display_order - next.display_order));
    });
    axiosInstance.get(`/divs/${DIV_ID_EMPLOYMENT_TYPE}`).then((response) => {
      setStatuses(response.data.sort((cur, next) => cur.display_order - next.display_order));
    });
    axiosInstance.get(`/divs/${DIV_ID_POSITION}`).then((response) => {
      setPositions(response.data.sort((cur, next) => cur.display_order - next.display_order));
    });
    axiosInstance.get('/departments').then((response) => {
      setDepartments(response.data);
    });
  }, []);

  useEffect(() => {
    const newErrors = [];
    employeeInfo.employee_belongs?.forEach((belong) => {
      if (!belong.dept_cd) {
        newErrors.push(' ');
      } else {
        newErrors.push(null);
      }
    });
    setDepartmentErrors(newErrors);
  }, [employeeInfo]);
  return (
    <div className="dis-flex flex-center">
      <Formik
        validationSchema={Schema}
        onSubmit={handleSubmitEmployment}
        enableReinitialize
        initialValues={employeeInfo}
      >
        {({ handleSubmit, handleChange, setFieldValue, values, errors, setFieldTouched }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <div className="employment">
              <InputText
                title="社員番号"
                titleSm="4"
                colSm="4"
                fieldKey="employee_no"
                helpText="自動で採番されました"
                handleChange={handleChange}
                values={values}
                errors={errors}
              />
              <Form.Group as={Row} className="mb-6">
                <Form.Label column sm="4" className="pl-10">
                  社用電話番号
                </Form.Label>
                <Col sm="7" as={Row} className="item-center">
                  <Col className="pos-rel">
                    <Form.Control
                      name="biz_tel_areacd"
                      value={values.biz_tel_areacd}
                      required
                      onBlur={(e) => handleBlur(e, setFieldValue, setFieldTouched)}
                      onChange={(e) => handleChangePhone(e, setFieldValue)}
                      isInvalid={errors.biz_tel_areacd}
                    />
                    <Form.Control.Feedback type="invalid" as="small" className="pos-abs">
                      {errors.biz_tel_areacd}
                    </Form.Control.Feedback>
                  </Col>
                  <Col sm="1" className="text-center">
                    -
                  </Col>
                  <Col className="pos-rel">
                    <Form.Control
                      name="biz_tel_localcd"
                      value={values.biz_tel_localcd}
                      required
                      onBlur={(e) => handleBlur(e, setFieldValue, setFieldTouched)}
                      onChange={(e) => handleChangePhone(e, setFieldValue)}
                      isInvalid={errors.biz_tel_localcd}
                    />
                    <Form.Control.Feedback type="invalid" as="small" className="pos-abs">
                      {errors.biz_tel_localcd}
                    </Form.Control.Feedback>
                  </Col>
                  <Col sm="1" className="text-center">
                    -
                  </Col>
                  <Col className="pos-rel">
                    <Form.Control
                      name="biz_tel_number"
                      value={values.biz_tel_number}
                      required
                      onBlur={(e) => handleBlur(e, setFieldValue, setFieldTouched)}
                      onChange={(e) => handleChangePhone(e, setFieldValue)}
                      isInvalid={errors.biz_tel_number}
                    />
                    <Form.Control.Feedback type="invalid" as="small" className="pos-abs">
                      {errors.biz_tel_number}
                    </Form.Control.Feedback>
                  </Col>
                </Col>
              </Form.Group>
              <InputText
                title="社用メールアドレス"
                notRequired
                titleSm="4"
                colSm="4"
                fieldKey="mail_address_sub"
                handleChange={handleChange}
                handleBlur={(e) => handleBlur(e, setFieldValue, setFieldTouched)}
                values={values}
                errors={errors}
              />
              <InputDropdown
                title="雇用形態"
                titleSm="4"
                colSm="4"
                values={values}
                errors={errors}
                fieldKey="employment_status_div"
                handleChange={(e) =>
                  setFieldValue('employment_status_div', parseInt(e.target.value, 10))
                }
                options={statuses}
                optionKey="div_value"
                optionName="div_value_nm"
              />
              <Form.Group as={Row} className="mb-6">
                <Form.Label column sm="4" className="pl-10 required">
                  雇用期間の定め
                </Form.Label>
                <Col sm="4" className="dis-flex space-btwn">
                  {terms.map((term) => (
                    <Form.Check
                      type="radio"
                      inline
                      key={term.div_value}
                      label={term.div_value_nm}
                      value={term.div_value}
                      name="employment_term_div"
                      checked={term.div_value === values.employment_term_div}
                      onChange={(e) =>
                        setFieldValue('employment_term_div', parseInt(e.target.value, 10))
                      }
                      isInvalid={errors.employment_term_div}
                      id={`term-div-${term.div_value}`}
                      className="w-px-80"
                    />
                  ))}
                  <Form.Control.Feedback type="invalid" as="small">
                    {errors.employment_term_div}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <InputDropdown
                title="在籍ステータス区分"
                titleSm="4"
                colSm="4"
                values={values}
                errors={errors}
                fieldKey="enroll_status_div"
                handleChange={(e) =>
                  setFieldValue('enroll_status_div', parseInt(e.target.value, 10))
                }
                options={enrollStatuses}
                optionKey="div_value"
                optionName="div_value_nm"
              />
              <InputDropdown
                title="業務区分"
                titleSm="4"
                colSm="4"
                notRequired
                values={values}
                errors={errors}
                fieldKey="job_div"
                handleChange={(e) => setFieldValue('job_div', parseInt(e.target.value, 10))}
                options={jobs}
                optionKey="div_value"
                optionName="div_value_nm"
              />
              <InputText
                title="業務メモ"
                titleSm="4"
                colSm="4"
                notRequired
                fieldKey="job_content"
                helpText="担当顧客や業務に関する補足を入力"
                handleChange={handleChange}
                values={values}
                errors={errors}
              />
              <Form.Group as={Row} className="mb-6">
                <Form.Label column sm="4" className="pl-10">
                  １週間の所定労働時間
                </Form.Label>
                <Col sm="2">
                  <div className="pos-rel">
                    <Form.Control
                      className="input-time"
                      required
                      name="week_work_time_hour"
                      value={values.week_work_time_hour}
                      onChange={handleChange}
                      isInvalid={errors.week_work_time_hour}
                    />
                    <Form.Control.Feedback type="invalid" as="small">
                      {errors.week_work_time_hour}
                    </Form.Control.Feedback>
                    <div className="time-unit pos-abs">時間</div>
                  </div>
                  {!errors.week_work_time_hour && (
                    <Form.Text className="text-muted">半角数字で入力</Form.Text>
                  )}
                </Col>
                <Form.Label column sm="3" className="pl-10">
                  週労働時間（分）
                </Form.Label>
                <Col sm="2">
                  <div className="pos-rel">
                    <Form.Control
                      className="input-time"
                      required
                      name="week_work_time_minute"
                      value={values.week_work_time_minute}
                      onChange={handleChange}
                      isInvalid={errors.week_work_time_minute}
                    />
                    <Form.Control.Feedback type="invalid" as="small">
                      {errors.week_work_time_minute}
                    </Form.Control.Feedback>
                    <div className="time-unit pos-abs">分</div>
                  </div>
                  {!errors.week_work_time_minute && (
                    <Form.Text className="text-muted">半角数字で入力</Form.Text>
                  )}
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-12">
                <Form.Label column sm="4" className="pl-10">
                  給与支払い形式
                </Form.Label>
                <Col sm="6" className="dis-flex space-btwn">
                  <Form.Check
                    type="radio"
                    label="口座振込"
                    inline
                    name="salarySheetForm"
                    id="banking"
                    onChange={() => setFieldValue('payment_type_div', 1)}
                    checked={values.payment_type_div === 1}
                  />
                  <Form.Check
                    type="radio"
                    label="現金渡し"
                    inline
                    name="salarySheetForm"
                    onChange={() => setFieldValue('payment_type_div', 2)}
                    checked={values.payment_type_div === 2}
                    id="cash"
                  />
                </Col>
              </Form.Group>
              <InputDateForm
                title="入社年月日"
                titleSm="4"
                yearSm="3"
                monthSm="2"
                daySm="2"
                resetStyle={{ top: '13px', right: '55px', cursor: 'pointer' }}
                titleRequired
                handleChange={handleChange}
                valuePrefix="joined_ymd"
                values={values}
                errors={errors}
                setFieldValue={setFieldValue}
              />
              <InputDateForm
                title="退職年月日"
                titleSm="4"
                yearSm="3"
                monthSm="2"
                daySm="2"
                resetStyle={{ top: '13px', right: '55px', cursor: 'pointer' }}
                titleRequired={false}
                handleChange={handleChange}
                valuePrefix="retire_ymd"
                values={values}
                errors={errors}
                setFieldValue={setFieldValue}
              />
              <InputText
                title="退職メモ"
                titleSm="4"
                colSm="7"
                notRequired
                fieldKey="retire_memo"
                handleChange={handleChange}
                values={values}
                errors={errors}
              />
              <FieldArray
                name="employee_belongs"
                render={(arrayHelpers) => {
                  const { employee_belongs } = values;
                  return (
                    <>
                      {employee_belongs &&
                        employee_belongs.length > 0 &&
                        employee_belongs.map((data, index) => (
                          <div className="pos-rel border-slim pa-16 mb-8">
                            <button
                              className="belong-dismiss pos-abs"
                              type="button"
                              onClick={() => {
                                arrayHelpers.remove(index);
                                const newErrors = [...departmentErrors];
                                newErrors.splice(index, 1);
                                setDepartmentErrors(newErrors);
                                if (!data.isNewRecord)
                                  setBelongsToDelete([...belongsToDelete, data]);
                              }}
                            >
                              <img alt="minus" src={iconMinus} />
                            </button>
                            <Form.Group as={Row} className="mb-8 mt-8">
                              <Form.Label column sm="4" className="pl-10 required">
                                所属先
                              </Form.Label>
                              <Col sm="4">
                                <Form.Control
                                  as="select"
                                  name={`employee_belongs[${index}].dept_cd`}
                                  value={values.employee_belongs[index].dept_cd}
                                  onChange={(e) => {
                                    setFieldValue(
                                      `employee_belongs[${index}].dept_cd`,
                                      e.target.value
                                    );
                                    const newErrors = [...departmentErrors];
                                    newErrors[index] =
                                      values.employee_belongs.findIndex(
                                        (belong) => belong.dept_cd === e.target.value
                                      ) === -1
                                        ? null
                                        : '同じ所属は選択できない';
                                    setDepartmentErrors([...newErrors]);
                                  }}
                                  isInvalid={departmentErrors[index]}
                                  defaultValue=""
                                >
                                  <option value="" disabled hidden>
                                    選択してください
                                  </option>
                                  {departments.map((option) => (
                                    <option key={option.dept_cd} value={option.dept_cd}>
                                      {option.dept_nm}
                                    </option>
                                  ))}
                                </Form.Control>
                                <Form.Control.Feedback
                                  type="invalid"
                                  as="small"
                                  className="pos-abs"
                                >
                                  {departmentErrors[index]}
                                </Form.Control.Feedback>
                              </Col>
                            </Form.Group>
                            <InputDropdown
                              title="役職"
                              titleSm="4"
                              colSm="3"
                              values={values}
                              errors={errors}
                              nestedFieldKey={`employee_belongs[${index}].position`}
                              handleChange={handleChange}
                              options={positions}
                              optionKey="div_value"
                              optionName="div_value_nm"
                            >
                              <Form.Label column sm="2" className="pl-10">
                                グループ
                              </Form.Label>
                              <Col sm="2">
                                <Form.Control
                                  name={`employee_belongs[${index}].group_nm`}
                                  value={values.employee_belongs[index].group_nm}
                                  onChange={handleChange}
                                />
                              </Col>
                            </InputDropdown>
                          </div>
                        ))}
                      <div className="dis-flex flex-row-reverse">
                        <ChangeRecordButton
                          onClick={() => {
                            arrayHelpers.push({
                              ...employee_belongs[0],
                              isNewRecord: true,
                              position: '',
                              dept_cd: '',
                              group_nm: '',
                            });
                            setDepartmentErrors([...departmentErrors, ' ']);
                          }}
                          text="所属を追加する"
                        />
                      </div>
                    </>
                  );
                }}
              />
              <Form.Group as={Row} className="mb-6 mt-3">
                <Form.Label column sm="4" className="pl-10">
                  前職会社名
                </Form.Label>
                <Col sm="5">
                  <Form.Control
                    name="prev_company_info"
                    value={values.prev_company_info}
                    onChange={handleChange}
                  />
                </Col>
              </Form.Group>
            </div>
            <div className="dis-flex flex-center submit-wrapper">
              <button className="save-btn" type="submit" disabled={!IsAuthorize(AUTH_CREATEUPDATE)}>
                変更を保存
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
