import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import axiosInstance from '../../../api/base';
import { AUTH_CREATEUPDATE } from '../../../api/constants';
import InputText from '../../../components/common/form/InputText';
import { IsAuthorize } from '../../../redux/slices/auth';
import { setSuccessToast } from '../../../redux/slices/toast';

export default function EmployeeHealthInsuranceCardEdit({ history, match }) {
  const [employeeHealthInsuranceCard, setEmployeeHealthInsuranceCard] = useState({
    ehiNo: '',
    ehiInsuredNo: '',
  });

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    ehiNo: Yup.string()
      .matches(/^[0-9]+$/, '半角数字で入力してください')
      .max(8, '８桁以内で入力してください')
      .required(' ')
      .nullable(),
    ehiInsuredNo: Yup.string()
      .matches(/^[0-9]+$/, '半角数字で入力してください')
      .max(8, '８桁以内で入力してください')
      .required(' ')
      .nullable(),
  });

  const handleSubmitSend = (params) => {
    setIsLoading(true);

    axiosInstance
      .post(`/employees/${match.params.id}/employee_health_insurance_card`, {
        ehi_no: params.ehiNo,
        ehi_insured_no: params.ehiInsuredNo,
      })
      .then(({ status }) => {
        if (status === 200) {
          dispatch(setSuccessToast(true));
          setIsLoading(false);
          history.push(`/employee/${match.params.id}/healthInsuranceCard`);
        }
      });
  };

  useEffect(() => {
    setIsLoading(true);

    axiosInstance
      .get(`/employees/${match.params.id}/employee_health_insurance_card`)
      .then(({ data, status }) => {
        if (status === 200) {
          setEmployeeHealthInsuranceCard({
            ehiNo: data ? data.ehi_no : '',
            ehiInsuredNo: data ? data.ehi_insured_no : '',
          });

          setIsLoading(false);
        }
      });
  }, []);

  return (
    <div className="content w-form">
      <h4 className="bold">帳票情報</h4>
      <Formik
        initialValues={employeeHealthInsuranceCard}
        validationSchema={validationSchema}
        onSubmit={handleSubmitSend}
        enableReinitialize
      >
        {({ handleSubmit, handleChange, values, errors, isValid, touched }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <div className="mt-6 bg-gray2 pa-16 px-10 pos-rel">
              <div className="w-90">
                <InputText
                  title="記号"
                  fieldKey="ehiNo"
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  touched={touched}
                  helpText="半角数字で入力"
                />
                <InputText
                  title="番号"
                  fieldKey="ehiInsuredNo"
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  touched={touched}
                  helpText="半角数字で入力"
                />
              </div>
            </div>
            <div className="dis-flex flex-center submit-wrapper">
              <button
                className="save-btn"
                type="submit"
                disabled={!IsAuthorize(AUTH_CREATEUPDATE) || !isValid || isLoading}
              >
                登録
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
