/* eslint-disable camelcase */
import { useState, useEffect, useRef } from 'react';
import { Form } from 'react-bootstrap';
import * as yup from 'yup';
import { Formik, FieldArray } from 'formik';
import { useDispatch } from 'react-redux';
import InputDateForm from '../../../components/common/form/InputDateForm';
import InputText from '../../../components/common/form/InputText';
import InputDropdown from '../../../components/common/form/InputDropdown';
import ChangeRecordButton from '../../../components/common/button/AddRecordButton';
import axiosInstance from '../../../api/base';
import {
  AUTH_CREATEUPDATE,
  AUTH_DELETE,
  DIV_ID_COMMUTE_WAY,
  DIV_ID_VEHICLE,
  LIST_ID_DROPDOWN_PUBLIC_VEHICLES,
} from '../../../api/constants';
import { generateDateString } from '../../../utils/convert';
import { setSuccessToast } from '../../../redux/slices/toast';
import HeaderForm from '../../../components/HeaderForm';
import { IsAuthorize } from '../../../redux/slices/auth';

const SchemaOfPublicVehicle = yup.object().shape({
  commute_start_ymd_year: yup.number().required(' '),
  commute_start_ymd_month: yup.number().required(' '),
  commute_start_ymd_day: yup.number().required(' '),
  application_reason: yup.string().notRequired(),
  commute_distance: yup.number().typeError('少数を入力してください').nullable(),
  // .test('is-decimal', '少数を入力してください', (value) => `${value}`.match(/^\d*\.?\d*$/)),
  commute_time: yup
    .string()
    .nullable()
    .required(' ')
    .trim()
    .matches(/^[0-9]+$/, '半角数字で入力してください'),
  commute_way_div: yup.number().required(' '),
  oneway_fare: yup
    .string()
    .nullable()
    .trim()
    .matches(/^[0-9]+$/, '半角数字で入力してください'),
  month_pass_price: yup
    .string()
    .nullable()
    .trim()
    .matches(/^[0-9]+$/, '半角数字で入力してください'),
  dtls: yup.array().of(
    yup.object().shape({
      commute_way_div: yup.number().required(' '),
      line_name: yup.string().required(' ').nullable(),
      area_start: yup.string().nullable(),
      area_end: yup.string().required(' ').nullable(),
    })
  ),
});

const SchemaOfNonPublicVehicle = yup.object().shape({
  commute_start_ymd_year: yup.number().required(' '),
  commute_start_ymd_month: yup.number().required(' '),
  commute_start_ymd_day: yup.number().required(' '),
  application_reason: yup.string().notRequired(),
  commute_way_div: yup.number().required(' '),
  vehicle_div: yup.number().required(' '),
  // .test('is-decimal', '少数を入力してください', (value) => `${value}`.match(/^\d*\.?\d*$/)),
  commute_time: yup
    .string()
    .nullable()
    .required(' ')
    .trim()
    .matches(/^[0-9]+$/, '半角数字で入力してください'),
});

export default function CommuteCostApplication(props) {
  const [commuteApplication, setCommuteApplication] = useState({
    month_pass_price: null,
    commute_distance: null,
    oneway_fare: null,
    dtls: [
      {
        commute_way_div: '',
        line_name: '',
        area_start: '',
        area_end: '',
      },
    ],
  });
  const [commuteWays, setCommuteWays] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [initialDtls, setInitialDtls] = useState([]);
  const [deleteShowing, setDeleteShowing] = useState(false);
  const refForm = useRef(null);
  const dispatch = useDispatch();

  // eslint-disable-next-line react/destructuring-assignment
  const { id, commuteId } = props.match.params;
  useEffect(() => {
    axiosInstance.get(`divs/${DIV_ID_COMMUTE_WAY}`).then((response) => {
      setCommuteWays(response.data.sort((cur, next) => cur.display_order - next.display_order));
    });
    axiosInstance.get(`divs/${DIV_ID_VEHICLE}`).then((response) => {
      setVehicles(response.data.sort((cur, next) => cur.display_order - next.display_order));
    });

    axiosInstance.get(`/employees/${id}/employee_commute_ways/${commuteId}`).then((response) => {
      const consultPlanDate = response.data.commute_start_ymd?.split('-') || [];

      axiosInstance
        .get(`/employee_commute_way/${commuteId}/employee_commute_way_dtls/`)
        .then((dtlsResponse) => {
          const dtls = dtlsResponse.data
            ? dtlsResponse.data
            : [
                {
                  commute_way_div: '',
                  line_name: '',
                  area_start: '',
                  area_end: '',
                },
              ];
          setInitialDtls(dtls);
          setCommuteApplication({
            ...response.data,
            commute_start_ymd_year: parseInt(consultPlanDate[0], 10) || '',
            commute_start_ymd_month: parseInt(consultPlanDate[1], 10) || '',
            commute_start_ymd_day: parseInt(consultPlanDate[2], 10) || '',
            dtls,
          });
        });
    });
  }, []);

  const handleSubmitSend = async (params) => {
    const commuteWay = {
      ...params,
      employee_cd: props.match.params.id,
      commute_start_ymd: generateDateString(
        params.commute_start_ymd_year,
        params.commute_start_ymd_month,
        params.commute_start_ymd_day
      ),
    };
    // seq could be zero
    const dtlsToPatch = params.dtls.filter((dtl) => typeof dtl.seq === 'number');
    const dtlsToPost = params.dtls.filter((dtl) => !dtl.seq && typeof dtl.seq !== 'number');
    const listSeqPatch = dtlsToPatch.map((i) => i.seq);
    const dtlsToDelete = initialDtls.filter((initDtl) => !listSeqPatch.includes(initDtl.seq));

    axiosInstance({
      method: commuteId ? 'patch' : 'post',
      url: `/employees/${id}/employee_commute_ways`,
      data: commuteWay,
    }).then(({ data }) => {
      if (!data) return;

      if (dtlsToPatch.length) {
        axiosInstance.patch(
          `/employee_commute_way/${commuteId || data.commute_way_id}/employee_commute_way_dtls/`,
          dtlsToPatch
        );
      }

      if (dtlsToPost.length) {
        axiosInstance.post(
          `/employee_commute_way/${commuteId || data.commute_way_id}/employee_commute_way_dtls/`,
          dtlsToPost
        );
      }

      if (dtlsToDelete.length) {
        axiosInstance.delete(
          `/employee_commute_way/${commuteId || data.commute_way_id}/employee_commute_way_dtls/`,
          { data: dtlsToDelete }
        );
      }
      dispatch(setSuccessToast(true));
      props.history.push(`/employee/${id}/commuteCostApplication`);
    });
  };

  const generateSchema = () => {
    const schema = LIST_ID_DROPDOWN_PUBLIC_VEHICLES.includes(
      Number(refForm.current?.values?.commute_way_div)
    )
      ? SchemaOfPublicVehicle
      : SchemaOfNonPublicVehicle;
    return schema;
  };

  const deleteThisCheckup = () => {
    if (initialDtls.length) {
      axiosInstance
        .delete(
          `/employee_commute_way/${props.match.params.commuteId}/employee_commute_way_dtls/`,
          { data: initialDtls }
        )
        .then(() => {
          axiosInstance
            .delete(
              `/employees/${props.match.params.id}/employee_commute_ways/${props.match.params.commuteId}`
            )
            .then((response) => {
              if (response.data)
                props.history.push(`/employee/${props.match.params.id}/commuteCostApplication`);
            });
        });
    }
  };

  return (
    <div className="content w-form">
      <HeaderForm
        deleteShowing={deleteShowing}
        setDeleteShowing={setDeleteShowing}
        deleteThisCheckup={deleteThisCheckup}
        // eslint-disable-next-line react/destructuring-assignment
        haveBtnDelete={props.match.params.commuteId && IsAuthorize(AUTH_DELETE)}
      />
      <Formik
        innerRef={refForm}
        validationSchema={generateSchema}
        onSubmit={handleSubmitSend}
        enableReinitialize
        initialValues={commuteApplication}
      >
        {({ handleSubmit, handleChange, values, errors, touched, setFieldValue }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <div className="mt-6 bg-gray2 pa-16 pos-rel">
              <div style={{ width: '95%' }}>
                <InputDateForm
                  title="通勤開始日"
                  titleRequired
                  handleChange={handleChange}
                  valuePrefix="commute_start_ymd"
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                />
                <InputText
                  title="申請理由"
                  notRequired
                  fieldKey="application_reason"
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
                <InputDropdown
                  title="主な通勤方法"
                  values={values}
                  errors={errors}
                  touched={touched}
                  fieldKey="commute_way_div"
                  handleChange={handleChange}
                  options={commuteWays}
                  optionKey="div_value"
                  optionName="div_value_nm"
                  helpText="区間ごとの通勤方法は区間ごとに設定できます"
                />
                <InputText
                  title="通勤距離(㎞)"
                  fieldKey="commute_distance"
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  touched={touched}
                  notRequired
                  helpText="半角数字で入力"
                />
                <InputText
                  title="通勤時間(分)"
                  fieldKey="commute_time"
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  touched={touched}
                  helpText="半角数字で入力"
                />
                {values.commute_way_div &&
                  (!LIST_ID_DROPDOWN_PUBLIC_VEHICLES.includes(Number(values.commute_way_div)) ? (
                    <InputDropdown
                      title="車両区分"
                      values={values}
                      errors={errors}
                      touched={touched}
                      fieldKey="vehicle_div"
                      handleChange={handleChange}
                      options={vehicles}
                      optionKey="div_value"
                      optionName="div_value_nm"
                    />
                  ) : (
                    <>
                      <InputText
                        title="片道運賃"
                        fieldKey="oneway_fare"
                        inputStyle="text-right pr-6"
                        suffix="円"
                        handleChange={handleChange}
                        values={values}
                        errors={errors}
                        touched={touched}
                        notRequired
                        helpText="半角数字で入力"
                      />
                      <InputText
                        title="1ヶ月定期金額"
                        fieldKey="month_pass_price"
                        inputStyle="text-right pr-6"
                        suffix="円"
                        handleChange={handleChange}
                        values={values}
                        errors={errors}
                        touched={touched}
                        notRequired
                        helpText="半角数字で入力"
                      />
                      <FieldArray
                        name="dtls"
                        render={(arrayHelpers) => {
                          const { dtls } = values;
                          return (
                            <>
                              {dtls &&
                                dtls.length > 0 &&
                                dtls.map((_, index) => (
                                  <div className="border-slim pa-16 mb-8">
                                    <div className="dis-flex justify-content-between align-items-center mb-2">
                                      <h5>
                                        <b>区間{index + 1}</b>
                                      </h5>
                                      {dtls.length > 1 && (
                                        <div>
                                          <ChangeRecordButton
                                            text="削除する"
                                            minus
                                            onClick={() => arrayHelpers.remove(index)}
                                          />
                                        </div>
                                      )}
                                    </div>
                                    <InputDropdown
                                      title="通勤手段"
                                      values={values}
                                      errors={errors}
                                      nestedFieldKey={`dtls[${index}].commute_way_div`}
                                      handleChange={handleChange}
                                      options={commuteWays}
                                      optionKey="div_value"
                                      optionName="div_value_nm"
                                      touched={touched}
                                    />
                                    <InputText
                                      title="路線名"
                                      nestedFieldKey={`dtls[${index}].line_name`}
                                      handleChange={handleChange}
                                      values={values}
                                      errors={errors}
                                      touched={touched}
                                    />
                                    <InputText
                                      title="乗車地"
                                      nestedFieldKey={`dtls[${index}].area_start`}
                                      handleChange={handleChange}
                                      values={values}
                                      errors={errors}
                                      touched={touched}
                                      notRequired
                                    />
                                    <InputText
                                      title="降車地"
                                      nestedFieldKey={`dtls[${index}].area_end`}
                                      handleChange={handleChange}
                                      values={values}
                                      errors={errors}
                                      touched={touched}
                                    />
                                  </div>
                                ))}
                              <div className="dis-flex flex-row-reverse">
                                <ChangeRecordButton
                                  onClick={() =>
                                    arrayHelpers.push({
                                      commute_way_div: '',
                                      line_name: '',
                                      area_start: '',
                                      area_end: '',
                                    })
                                  }
                                  text="区間を追加する"
                                />
                              </div>
                            </>
                          );
                        }}
                      />
                    </>
                  ))}
              </div>
            </div>
            <div className="dis-flex flex-center submit-wrapper">
              <button className="save-btn" type="submit" disabled={!IsAuthorize(AUTH_CREATEUPDATE)}>
                登録
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
