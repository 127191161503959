/* eslint-disable camelcase */
import { useState, useEffect, useMemo } from 'react';
import { Modal, ListGroup, Button, FormControl } from 'react-bootstrap';
import { Filter, CheckCircle } from 'react-bootstrap-icons';
import Table from '../../components/common/table/Table';
import axiosInstance from '../../api/base';
import noAvatar from '../../assets/images/avatar.png';
import { computeDate } from '../../utils/convert';
import { getTimeOfService } from '../../utils/date';
import { IsAuthorize } from '../../redux/slices/auth';
import { AUTH_CREATEUPDATE } from '../../api/constants';

export default function EmployeeList(props) {
  const [datas, setDatas] = useState([]);
  const [enrollmentFilter, setEnrollmentFilter] = useState([]);
  const [departmentFilter, setDepartmentFilter] = useState([]);
  const [groupFilter, setGroupFilter] = useState([]);

  const [enrollment, setEnrollment] = useState(0);
  const [departments, setDepartments] = useState([0]);
  const [departmentGroup, setDepartmentGroup] = useState(0);
  const [employeeNo, setEmployeeNo] = useState([]);

  const [show, setShow] = useState(false);

  const handleClose = () => {
    const enrollsString =
      enrollment !== 0 ? `status=${enrollmentFilter[enrollment].div_value}` : '';
    const deptsString =
      departments.length === 1 && departments[0] === 0
        ? ''
        : `department=${departments.map((el) => departmentFilter[el]?.dept_cd).toString()}`;
    const departmentGroupString =
      departmentGroup !== 0 ? `departmentGroup=${groupFilter[departmentGroup].group_nm}` : '';
    const empNoString = employeeNo !== '' ? `employee_no=${employeeNo}` : '';
    axiosInstance
      .get(`/employees?${enrollsString}&${deptsString}&${empNoString}&${departmentGroupString}`)
      .then((response) => {
        setDatas(response.data.items);
      });
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const mutipleSelect = (e, func, arr) => {
    if (e === 0) {
      func([0]);
    } else if (!arr.includes(e) && !arr.includes(0) && arr.length) {
      func([...arr, e]);
    } else if (arr.includes(e) && arr.length === 1) {
      func([0]);
    } else if (arr.includes(0)) {
      func([e]);
    } else {
      func(arr.filter((_, i) => i !== arr.indexOf(e)));
    }
  };

  const selectGroup = (index) => {
    setDepartmentGroup(index);
    if (index !== 0) {
      axiosInstance.get(`/departments?group_nm=${groupFilter[index].group_nm}`).then((response) => {
        setDepartmentFilter([{ dept_nm: 'すべて', dept_cd: '' }, ...response.data]);
      });
    } else {
      axiosInstance.get('/departments').then((response) => {
        setDepartmentFilter([{ dept_nm: 'すべて', dept_cd: '' }, ...response.data]);
      });
    }
  };

  useEffect(() => {
    const init = async () => {
      axiosInstance.get('/employees').then((response) => {
        setDatas(response.data.items);
      });
      axiosInstance.get('/departments').then((response) => {
        setDepartmentFilter([{ dept_nm: 'すべて', dept_cd: '' }, ...response.data]);

        const arr1 = response.data.reduce((a, v) => {
          if (!a.some((e) => e.group_nm === v.group_nm)) {
            a.push({ group_nm: v.group_nm, group_cd: v.group_nm });
          }
          return a;
        }, []);
        setGroupFilter([{ group_nm: 'すべて', group_cd: '' }, ...arr1]);
      });

      axiosInstance.get('/divs/enroll_status').then((response) => {
        const orderedData = response.data.sort(
          (cur, next) => cur.display_order - next.display_order
        );
        setEnrollmentFilter([{ div_value_nm: 'すべて', div_value: '' }, ...orderedData]);
      });
    };
    init();
  }, []);

  const columns = useMemo(() => [
    {
      id: 'name',
      accessor: ({ employee_info, avatar }) => {
        const { employee_last_name, employee_first_name } = employee_info;
        return [employee_last_name, employee_first_name, avatar?.url];
      },
      Header: '氏名',
      width: 200,
      // sticky: 'left',
      Cell: ({ value }) => (
        <div className="dis-flex item-center item-center">
          <img alt="" className="list-avatar mr-r-md" src={value[2] || noAvatar} />
          {value[0]} {value[1]}
        </div>
      ),
    },
    {
      id: 'employee_no',
      accessor: ({ employee_info }) => employee_info.employee_no,
      Header: '社員番号',
    },
    {
      id: 'employee_info.group_nm',
      accessor: ({ employee_info }) => employee_info.employee_belongs[0]?.department?.group_nm,
      Header: '会社名',
      width: 250,
    },
    {
      id: 'dept_maincategory_nm',
      accessor: ({ employee_info }) =>
        employee_info.employee_belongs[0]?.department?.dept_maincategory_nm,
      Header: () => <span className="cell-pd">所属先</span>,
      Cell: ({ value }) => <span className="cell-pd">{value}</span>,
      width: 180,
    },
    {
      id: 'employee_info.joined_ymd',
      accessor: ({ employee_info }) => computeDate(employee_info.joined_ymd),
      Header: '入社年月日',
    },
    {
      id: 'time_of_service',
      accessor: ({ employee_info }) => getTimeOfService(employee_info.joined_ymd),
      Header: '勤続年数',
    },
    {
      id: 'employee_info.related_docs.length',
      accessor: ({ employee_info }) =>
        employee_info.related_docs.length >= 1 ? '登録済み' : '未登録',
      Header: '書類登録状況',
    },
    {
      id: 'employee_info.employment_end_ymd',
      accessor: ({ employee_info }) => computeDate(employee_info.employment_end_ymd),
      Header: '契約終了日',
    },
    {
      id: 'employee_info.driver_licenses[0].expiration_date',
      accessor: ({ employee_info }) =>
        computeDate(employee_info.driver_licenses[0]?.expiration_date),
      Header: '自動車運転免許証有効期限',
      width: 200,
    },
    {
      id: 'employee_info.health_checkups[0].consult_plans_ymd',
      accessor: ({ employee_info }) =>
        computeDate(employee_info.health_checkups[0]?.consult_plans_ymd),
      Header: '健康診断最終受診日',
    },
    {
      id: 'employee_info.aptitude_tests[0].checkup_ymd',
      accessor: ({ employee_info }) => computeDate(employee_info.aptitude_tests[0]?.checkup_ymd),
      Header: '適性診断最終受講日',
    },
    {
      id: 'employee_info.commute_vehicles[0].inspection_expiration_date',
      accessor: ({ employee_info }) =>
        computeDate(employee_info.commute_vehicles[0]?.inspection_expiration_date),
      Header: '車検証有効期限',
    },
    {
      id: 'liability_date',
      accessor: ({ employee_info }) =>
        employee_info.vehicle_insurances[0]?.insurance_div === 1
          ? computeDate(employee_info.vehicle_insurances[0].expiration_date)
          : '-',
      Header: '自賠責保険証有効期限',
    },
    {
      id: 'voluntary_date',
      accessor: ({ employee_info }) =>
        employee_info.vehicle_insurances[0]?.insurance_div === 2
          ? computeDate(employee_info.vehicle_insurances[0].expiration_date)
          : '-',
      Header: '任意保険有効期限',
    },
    {
      id: 'bicycle_date',
      accessor: ({ employee_info }) =>
        employee_info.vehicle_insurances[0]?.insurance_div === 3
          ? computeDate(employee_info.vehicle_insurances[0].expiration_date)
          : '-',
      Header: '自転車保険有効期限',
    },
    {
      id: 'employee_info.residence_cards[0].expiration_date',
      accessor: ({ employee_info }) =>
        computeDate(employee_info.residence_cards[0]?.expiration_date),
      Header: '在留カード有効期限',
    },
    {
      id: 'employee_info.skills[0].expiration_date',
      accessor: ({ employee_info }) => computeDate(employee_info.skills[0]?.expiration_date),
      Header: '資格者証有効期限',
    },
    {
      id: 'employee_info.accident_histories[0].occur_ymd',
      accessor: ({ employee_info }) => computeDate(employee_info.accident_histories[0]?.occur_ymd),
      width: 200,
      Header: '事故履歴最新事故発生日',
    },
    {
      id: 'employee_info.repayment_plans[0].repayment_end_ym',
      accessor: ({ employee_info }) =>
        computeDate(employee_info.repayment_plans[0]?.repayment_end_ym),
      Header: '返済計画終了月',
    },
    {
      id: 'id',
      accessor: 'employee_info.id',
      width: 0,
      Cell: () => null,
      disableSortBy: true,
    },
  ]);

  return (
    <div className="content employee-list mr-r-bg mr-l-bg">
      <div className="dis-flex flex-end pb-7 pos-rel">
        <h1 className="pos-abs abs-left bold">従業員</h1>
        <div className="dis-flex height-40">
          {IsAuthorize(AUTH_CREATEUPDATE) && (
            <Button
              className="new-emp-btn mr-l-bg"
              size="sm"
              variant="light"
              onClick={() => props.history.push(`/employee/create`)}
            >
              新規作成
            </Button>
          )}
        </div>
      </div>
      <div className="filter-btn-group">
        <button className="filter-btn" onClick={handleShow} type="button">
          <Filter size={24} className="mr-b-sm" />
          絞り込み
        </button>
        <div className="info-choicechip">
          在籍ステータス：
          <span>{enrollmentFilter[enrollment]?.div_value_nm}</span>
        </div>
        {departments.length > 0 && (
          <div className="info-choicechip">
            所属先：
            {departments.map((e, i) => (
              <span key={i}>{departmentFilter[e]?.dept_nm}</span>
            ))}
          </div>
        )}
      </div>
      <Table
        data={datas}
        columns={columns}
        pageSize={50}
        sticky
        otherSearchBar
        onClickRow={(row) => props.history.push(`/employee/${row.id}`)}
      >
        <Modal show={show} onHide={handleClose} centered className="select-filter-modal">
          <Modal.Header>
            <Modal.Title>従業員の絞り込み条件</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-lght">社員番号</div>
            <FormControl
              placeholder=""
              className="input-keyword"
              size="sm"
              onChange={(e) => setEmployeeNo(e.target.value)}
              value={employeeNo}
            />
            <div className="text-lght">在籍ステータス</div>
            <ListGroup>
              {enrollmentFilter.map((el, index) => (
                <ListGroup.Item
                  action
                  key={index}
                  onClick={() => setEnrollment(index)}
                  className={`item-filter ${enrollment === index ? 'active' : ''}`}
                >
                  {el.div_value_nm}
                  {enrollment === index && <CheckCircle size={20} />}
                </ListGroup.Item>
              ))}
            </ListGroup>
            <div className="text-lght">会社名</div>
            <ListGroup>
              {groupFilter.map((el, index) => (
                <ListGroup.Item
                  action
                  key={index}
                  onClick={() => selectGroup(index)}
                  className={`item-filter ${departmentGroup === index ? 'active' : ''}`}
                >
                  {el.group_nm}
                  {departmentGroup === index && <CheckCircle size={20} />}
                </ListGroup.Item>
              ))}
            </ListGroup>
            <div className="text-lght">所属先</div>
            <ListGroup>
              {departmentFilter.map((el, index) => (
                <ListGroup.Item
                  action
                  key={index}
                  onClick={() => mutipleSelect(index, setDepartments, departments)}
                  className={`item-filter ${departments.indexOf(index) !== -1 ? 'active' : ''}`}
                >
                  {el.dept_nm}
                  {departments.indexOf(index) !== -1 && <CheckCircle size={20} />}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Modal.Body>
        </Modal>
      </Table>
    </div>
  );
}
