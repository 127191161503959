import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentUser, getUser } from '../../redux/slices/user';
import axiosInstance from '../../api/base';

export default function BreadcrumbDynamicEmployee({ match }) {
  const { currentUser } = useSelector(getUser);
  const [employee, setEmployee] = useState(currentUser);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUser?.id !== match.params.id) {
      axiosInstance.get(`/employees/${match.params.id}`).then((response) => {
        dispatch(setCurrentUser(response.data?.employee_info));
        setEmployee(response.data?.employee_info);
      });
    }
  }, []);

  return (
    <span>{employee ? `${employee.employee_last_name} ${employee.employee_first_name}` : ''}</span>
  );
}
