import iconPlus from '../../../assets/images/icon-plus.svg';
import iconMinus from '../../../assets/images/icon-minus.svg';

export default function AddRecordButton(props) {
  const { onClick, onKeyPress, imageRecord, text, minus } = props;
  return (
    <div
      onClick={onClick}
      onKeyPress={onKeyPress}
      role="button"
      tabIndex="0"
      className="add-record-button"
    >
      <img alt="plus" src={minus ? iconMinus : iconPlus} className="mr-2 w-20-px" />
      {!text && (
        <span className="button-text text-prim font-weight-bold">
          {imageRecord ? '新しい帳票を登録' : '新しい情報を登録'}
        </span>
      )}
      <span className="text-prim">{text}</span>
    </div>
  );
}
