import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

import * as api from '../../api/authen';
import cache from '../cache';
import avatar1 from '../../assets/images/avatar-1.png';
import avatar2 from '../../assets/images/avatar-2.png';
import avatar3 from '../../assets/images/avatar-3.png';
import avatar4 from '../../assets/images/avatar-4.png';

const getRandomAvatar = () => {
  const rd = Math.random();
  if (rd < 0.25) {
    return avatar1;
  }
  if (rd < 0.5) {
    return avatar2;
  }
  if (rd < 0.75) {
    return avatar3;
  }
  return avatar4;
};

export const slice = createSlice({
  name: 'counter',
  initialState: {
    authUser: null,
    token: cache.get('token'),
    loading: true,
    errorMessage: null,
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    authSuccess: (state, action) => {
      state.authUser = { ...action.payload, avatar: getRandomAvatar() };
      state.loading = false;
      state.error = null;
    },
    loginSuccess: (state, action) => {
      state.token = action.payload && action.payload.access_token;
      // state.loading = false;
      state.error = null;
      cache.set('token', state.token);
    },
    logoutSuccess: (state) => {
      state.authUser = null;
      state.token = null;
      state.loading = false;
      state.error = null;
      cache.remove('token');
    },
    setFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoading, authSuccess, loginSuccess, logoutSuccess, setFail } = slice.actions;

// Async action
export const authAction = () => async (dispatch) => {
  if (cache.get('token')) {
    try {
      dispatch(setLoading(true));
      const response = await api.auth();
      dispatch(authSuccess(response.data));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(setFail('セッションの有効期限が切れました。'));
      }
    }
  } else {
    dispatch(setLoading(false));
  }
};

export const loginAction = (params) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await api.login(params);
    dispatch(loginSuccess(response.data));
    dispatch(authAction());
  } catch (error) {
    if (error.response?.status === 401) {
      dispatch(setFail('IDまたはパスワードが正しくありません。'));
    }
  }
};

export const logoutAction = () => async (dispatch) => {
  dispatch(logoutSuccess());
};

// Get value from state
export const selectAuth = (state) => state.auth;

export default slice.reducer;

export const IsAuthorize = (targetLevel) => {
  const { authUser } = useSelector(selectAuth);
  const res = authUser?.permission_div >= targetLevel;
  return res || true;
};
