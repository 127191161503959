import { XLg, InfoCircleFill } from 'react-bootstrap-icons';
import { Toast } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { setSuccessToast, getSuccess } from '../../../redux/slices/toast';
import './style.scss';

export default function SuccessToast() {
  const { isShowSuccess } = useSelector(getSuccess);
  const dispatch = useDispatch();
  return (
    <Toast
      className="success-toast"
      show={isShowSuccess}
      onClose={() => dispatch(setSuccessToast(false))}
      bg="primary"
      delay={2000}
      autohide
    >
      <Toast.Body className="dis-flex item-center space-btwn">
        <div>
          <InfoCircleFill width={15} height={15} className="ml-4" color="white" />
          <span className="ml-3">保存に成功しました。</span>
        </div>
        <button type="button" onClick={() => dispatch(setSuccessToast(false))}>
          <XLg width={15} height={15} className="ml-4" color="white" />
        </button>
      </Toast.Body>
    </Toast>
  );
}
